import axios from 'axios';
import { mapState, mapMutations } from 'vuex';
import InformationModal from '@/components/modals/InformationModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import ReasonModal from '@/components/modals/ReasonModal.vue';
import UserModal from '@/components/modals/UserModal.vue';
import mixin from '@/components/mixins/mixin';

export default {
  name: 'navbar',
  data() {
    return {
      svpConnection: false,
      links: [],
      timer1: null,
      timer2: null,
    };
  },
  components: {
    InformationModal,
    ConfirmModal,
    ReasonModal,
    UserModal,
  },
  mixins: [mixin],
  computed: {
    ...mapState([
      'settings',
      'currentState',
      'isLoginned',
    ]),
    userName() {
      if (localStorage.token) {
        return this.parseJwt(localStorage.token).user.name;
      }
      return '';
    },
  },
  watch: {
    isLoginned(val) {
      if (val) {
        this.loadData();
      } else {
        if (this.timer1) {
          clearInterval(this.timer1);
          this.timer1 = null;
        }
        if (this.timer2) {
          clearInterval(this.timer2);
          this.timer2 = null;
        }
      }
    },
  },
  created() {
    if (this.isLoginned) {
      this.loadData();
    }
  },
  methods: {
    ...mapMutations([
      'setSettings',
      'setCurrentState',
      'setAlertNames',
      'openModal',
      'setLicense',
    ]),
    getSettings() {
      axios
        .get(this.getApiUrl('settings'))
        .then((response) => {
          this.setSettings(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getLicense() {
      axios
        .get(this.getLicenseUrl())
        .then((response) => {
          this.setLicense(response.data);
        })
        .catch((error) => {
          this.setLicense({
            type: 'Лицензия не действительна',
            organization: '',
            issued_date: '',
          });
          console.error(error);
        });
    },
    checkSVP() {
      const request = {
        method: 'state_journal.get_svp_connection',
        token: localStorage.token,
        data: {},
      };
      this.sendRequest(request).then((data) => {
        this.svpConnection = data.data;
      });
    },
    getCurrentState() {
      const request = {
        method: 'state_journal.current_state',
        token: localStorage.token,
        data: {},
      };
      this.sendRequest(request).then((data) => {
        this.setCurrentState(data.data.state);
      });
    },
    getAlertNames() {
      const request = {
        method: 'fault_log.get_alert_names',
        token: localStorage.token,
        data: {
          lang: this.$i18n.locale,
        },
      };
      this.sendRequest(request).then((data) => {
        this.setAlertNames(Object.keys(data.data).map((key) => {
          const value = {
            value: key,
            name: data.data[key],
          };
          return value;
        }));
      });
    },
    loadData() {
      this.getSettings();
      this.getLicense();
      this.checkSVP();
      this.getCurrentState();
      this.getAlertNames();
      if (!this.timer1) {
        this.timer1 = setInterval(this.checkSVP, 15000);
      }
      if (!this.timer2) {
        this.timer2 = setInterval(this.getCurrentState, 15000);
      }
    },
  },
};
