/* eslint-disable import/no-unresolved */
import EventBus from '@/eventBus.js';

import constructor from '@/components/mixins/constructor';
import mixin from '@/components/mixins/mixin';
import VJsoneditor from 'v-jsoneditor';
// import 'brace/theme/tomorrow_night_eighties';
// import 'brace/theme/chaos';
// import 'brace/theme/clouds_midnight';
// eslint-disable-next-line import/extensions
import 'brace/theme/dracula';
// import 'brace/theme/gruvbox';
// import 'brace/theme/idle_fingers';
// import 'brace/theme/tomorrow_night';
// import 'brace/theme/twilight';
// import 'brace/theme/xcode';

export default {
  name: 'Config',
  mixins: [mixin, constructor],
  components: {
    VJsoneditor,
  },
  data() {
    return {
      options: {
        mode: 'code',
        mainMenuBar: false,
        theme: 'ace/theme/dracula',
        onValidate: this.onValidate,
        onValidationError: this.onValidationError,
      },
      configErrors: false,
      isConfigLocked: false,
      isDrag: false,
      isSuccess: false,
      isError: false,
      errorText: '',
      showModalError: false,
    };
  },
  mounted() {
    const { configFile } = this.$store.state;
    this.json = configFile && Object.keys(configFile).length ? configFile : JSON.parse(this.baseJson);
    EventBus.$on('update-config-language', this.updateConfigLanguage);
  },
  beforeDestroy() {
    EventBus.$off('update-config-language', this.updateConfigLanguage);
  },
  watch: {
    isSuccess(val) {
      if (val) {
        setTimeout(() => {
          this.isSuccess = false;
        }, 400);
      }
    },
    isError(val) {
      if (val) {
        this.showModalError = true;
        setTimeout(() => {
          this.isError = false;
        }, 400);
      }
    },
  },
  methods: {
    onValidate(json) {
      if (!json) return [];

      const errors = {
        // languages: false,
      };

      const result = [];

      if (Object.values(errors).indexOf(true) + 1) return result;

      try {
        this.jsonParse(json);
      } catch (e) {
        result.push({ path: [], message: this.$t('config.error') });
      }
      return result;
    },
    onDownload() {
      const a = document.createElement('a');
      const file = new Blob([JSON.stringify(this.json, null, ' ')], { type: 'application/json' });
      a.href = URL.createObjectURL(file);
      a.download = 'config.json';
      a.click();
    },
    handlerDrag(e) {
      e.stopPropagation();
      e.preventDefault();
      switch (e.type.toLowerCase()) {
        case 'dragenter':
          this.isDrag = true;
          break;
        case 'dragover':
          break;
        case 'dragleave':
          this.isDrag = false;
          break;
        case 'drop':
          this.importFiles(e.dataTransfer.files);
          this.isDrag = false;
          break;
        default:
          break;
      }
    },
    onImport(input) {
      this.importFiles(input.srcElement.files, input.srcElement);
    },
    importFiles(files) {
      const filesCurrent = Array.from(files || []).filter(({ type }) => type === 'application/json');
      this.errorText = this.$t('config.error');
      if (!filesCurrent.length) {
        this.$refs.fileInput.value = '';
        this.isError = true;
        return;
      }
      const reader = new FileReader();
      reader.readAsText(filesCurrent[0]);
      reader.onload = () => {
        try {
          const newJSON = JSON.parse(reader.result);
          if (typeof newJSON === 'object' && (newJSON.languages || newJSON.web_groups || newJSON.triggers || newJSON.metrics || newJSON.sources)) {
            this.json = newJSON;
            this.isSuccess = true;
          } else {
            this.isError = true;
          }
        } catch (e) {
          console.error('error import: ', e);
          this.isError = true;
        } finally {
          this.$refs.fileInput.value = '';
        }
      };
    },
    getMonitoringConfig() {
      this.lockMonitoringConfig().then(() => {
        this.isConfigLocked = false;
        this.lockConfig();
        const request = {
          method: 'configs.get_monitoring_config',
          token: localStorage.token,
          data: {},
        };
        this.errorText = this.$t('error_modal.update');
        this.sendRequest(request).then((data) => {
          try {
            this.json = data.data;
            this.isSuccess = true;
          } catch (e) {
            console.error(e);
            this.isError = true;
          }
        }).catch((e) => {
          console.error(e);
          this.isError = true;
        });
      }).catch((lockData) => {
        this.isConfigLocked = lockData;
        this.errorText = `${this.$t('config.locked')} ${lockData.data}`;
        this.showModalError = true;
      });
    },
    setMonitoringConfig() {
      if (this.configErrors) return;
      if (this.isConfigLocked) return;
      this.lockMonitoringConfig().then(() => {
        this.isConfigLocked = false;
        const request = {
          method: 'configs.set_monitoring_config',
          token: localStorage.token,
          data: this.jsonNormalize(this.json),
        };
        this.errorText = this.$t('error_modal.save');
        this.sendRequest(request).then(() => {
          this.isSuccess = true;
        }).catch((e) => {
          console.error(e);
          this.isError = true;
        });
      }).catch((lockData) => {
        this.isConfigLocked = lockData;
        this.errorText = `${this.$t('config.locked')} ${lockData.data}`;
        this.showModalError = true;
      });
    },
    async lockMonitoringConfig() {
      const request = {
        method: 'configs.lock_monitoring_config',
        token: localStorage.token,
        data: {},
      };
      return this.sendRequest(request);
    },
    onValidationError(errors) {
      this.configErrors = !!errors.length;
      EventBus.$emit('config-errors', !!errors.length);
    },
    updateConfigLanguage(languages) {
      this.$set(this.json, 'languages', languages);
    },
    lockConfig() {
      if (!this.$router.history.current.fullPath.startsWith('/settings/constructor')) return;
      this.lockMonitoringConfig().then(() => {
        this.isConfigLocked = false;
        setTimeout(this.lockConfig, 10000);
      }).catch((lockData) => {
        this.isConfigLocked = lockData;
      });
    },
  },
};
