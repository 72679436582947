<template>
  <div class="well">
    <form @submit.stop.prevent="setSettings" class="form" name="acForm">
      <h2>{{ $t('ac.conditioner_settings') }}</h2>
      <br />
      <div class="form-group">
        <div class="checkbox">
          <label class="color-black">
            <span class="checkbox-material"
              ><span
                class="check black"
                :style="{
                  background: turnOn
                    ? 'url(' + require('../../assets/img/icons/check-blue.svg') + ')  center '
                    : ''
                }"
              >
              </span
            ></span>
            <input v-model="turnOn" type="checkbox" />
            {{ $t('ac.On_Off') }}
          </label>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="color-black">{{ $t('ac.highest_border') }}</label>
        <input
          type="text"
          required
          v-model="params.UpperBound.value"
          class="form-control"
          id=""
        />
      </div>
      <div class="form-group">
        <label for="" class="color-black">{{ $t('ac.lower_border') }}</label>
        <input
          type="text"
          name="LowerBound"
          required
          v-model="params.LowerBound.value"
          class="form-control"
          id=""
        />
      </div>
      <div class="form-group">
        <label for="" class="color-black">{{ $t('ac.Hysteresis') }}</label>
        <input
          type="text"
          required
          v-model="params.Hysteresis.value"
          class="form-control"
          id=""
        />
      </div>
      <div class="form-group">
        <label for="" class="color-black">{{ $t('ac.compressor_start_temperature') }}</label>
        <input
          type="text"
          v-model="params.CompressorTurnOnTemp.value"
          class="form-control"
          id=""
        />
      </div>
      <button type="submit" class="btn btn-primary" ng-disabled="!isChanged || isFething">
        {{ $t('action.save') }}
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'AC',
  data() {
    return {
      turnOn: false,
      params: {
        UpperBound: {
          value: 0,
          put: '/sensor/DKCUpperBound/value',
          get: '/sensor/DKCUpperBound/measurement',
        },
        LowerBound: {
          value: 0,
          put: '/sensor/DKCLowerBound/value',
          get: '/sensor/DKCLowerBound/measurement',
        },
        Hysteresis: {
          value: 0,
          put: '/sensor/DKC_w/value',
          get: '/sensor/DKCHysteresis/measurement',
        },
        CompressorTurnOnTemp: {
          value: 0,
          put: '/sensor/DKC_w/value',
          get: '/sensor/DKCTemp/measurement',
        },
      },
    };
  },
  computed: {
    ...mapState([
      'settings',
    ]),
  },
  methods: {
    setSettings() {
      axios
        .put(`${this.settings.simona_url}/sensor/DKC_w/value`, {
          da: 1,
          ra: 770,
          rt: 2,
          bt: 0,
          fn: 6,
          vl: 34,
          vt: 0,
        })
        .then((res) => console.log(res))
        .catch((err) => console.error(err));
    },
  },
};
</script>
