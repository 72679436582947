<template>
  <BodyVehicle class="trailer">
    <div class="bind-line" />
    <Axle
      v-for="(item, key) in axles"
      :key="key"
      :weight="item.axle_weight"
      :isShowLine="key === 0 && axles.length > 1"
      :type-cardan="key === 0 && axles.length > 1 ? 'rectangle' : null"
      :type-wheels="item.skatnost"
      :wheels="item.wheels"
      type-line="middle"
      :isTextOnLine="true"
      :distance="item.axle_base"
      :isShowDistance="key !== axles.length - 1"
      :class="{ 'special-margin': key > 0 }"
    />
  </BodyVehicle>
</template>

<script>
import BodyVehicle from '@/components/modals/measurement/constructor-axles/common/BodyVehicle.vue';
import Axle from '@/components/modals/measurement/constructor-axles/common/Axle.vue';

export default {
  name: 'Trailer',
  components: { BodyVehicle, Axle },
  props: {
    axles: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.trailer {
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: 40px;
}
.bind-line {
  width: 90px;
  background: #C8C8C8;
  position: absolute;
  top: 50%;
  left: 25px;
  height: 4px;
  transform: translate(-100%, -50%);
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #C8C8C8;
  }
  &:after {
    left: 0;
  }
  &:before {
    right: 0;
  }
}
</style>
