<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark monitoringFilter"
    size="xxl"
  >
    <div slot="title">{{ $t('interface.filter') }}</div>
    <div class="filters">
      <div class="form-group">
        <label>{{ $t('monitoring.groups') }}</label>
        <br>
        <div class="input-group flex-wrap">
          <div v-for="(group, index) in monitoringGroups" :key="index" class="checkbox checkbox-item">
            <input
              name="filters_group"
              v-model="filters.group"
              :value="group"
              :id="'ch-'+group"
              type="radio"/>
            <label :for="'ch-'+group">
              {{ $t(`monitoring.${group}`) }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="d-flex justify-content-between">
      <div></div>
      <div class="d-flex">
        <div class="button" @click="applyFilter">
          <span class="button-image"><img src="../../assets/img/icons/search.svg"/></span>
          <span>{{ $t('action.apply') }}</span>
        </div>
        <div class="button" @click="close">
          <span class="button-image"><img src="../../assets/img/icons/close.svg"/></span>
          <span>{{ $t('action.cancel') }}</span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import mixin from '@/components/mixins/mixin';
import modal from '@/components/mixins/modal';

export default {
  name: 'MonitoringFilter',
  mixins: [mixin, modal],
  props: ['filtersParent'],
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    monitoringGroups() {
      if (this.settings.monitoring_groups.length) {
        return JSON.parse(this.settings.monitoring_groups);
      }
      if (this.appCode === 'vgk') {
        return [
          'conditioner',
          'scanner',
          'state',
          'security',
          'mercury',
          'camera',
          'ups',
          'business',
          'switch',
          'controller',
          'weighter',
        ];
      }
      if (this.appCode === 'ind') {
        return [
          'conditioner',
          'camera',
          'ups',
          'scanner',
          'state',
          'security',
          'business',
          'switch',
          'controller',
          'weighter',
        ];
      }
      return [
        'conditioner',
        'scanner',
        'state',
        'security',
        'mercury',
        'camera',
        'ups',
        'business',
        'switch',
        'controller',
        'dsrc',
        'programm_modules',
        'weighter',
      ];
    },
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.monitoringFilter,
    }),
  },
  methods: {
    close() {
      this.closeModal('monitoringFilter');
    },
  },
};
</script>
