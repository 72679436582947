<template>
  <div class="constructor-axles">
    <LightVehicle
      v-if="getCurrentType === 'light'"
      :axles="prepareDataAxles.length > 2 ? prepareDataAxles.slice(0, 2) : prepareDataAxles"
    />
    <LightVehicleWithTrailer
      v-if="getCurrentType === 'light-trailer'"
      :axles-vehicle="prepareDataAxles.length > 2 ? prepareDataAxles.slice(0, 2) : prepareDataAxles"
      :axles-trailer="prepareDataAxlesTrailer"
    />
    <Bus v-if="getCurrentType === 'bus'" :axles="prepareDataAxles" />
    <BusWidthTrailer
      v-if="getCurrentType === 'bus-trailer'"
      :axles-vehicle="axles"
      :axles-trailer="prepareDataAxlesTrailer"
    />
    <Truck v-if="getCurrentType === 'truck'" :axles="prepareDataAxles" />
    <TruckWidthTrailer
      v-if="getCurrentType === 'truck-trailer'"
      :axles-vehicle="prepareDataAxles"
      :axles-trailer="prepareDataAxlesTrailer"
    />
  </div>
</template>

<script>
import LightVehicle from '@/components/modals/measurement/constructor-axles/types/LightVehicle.vue';
import LightVehicleWithTrailer from '@/components/modals/measurement/constructor-axles/types/LightVehicleWithTrailer.vue';
import Bus from '@/components/modals/measurement/constructor-axles/types/Bus.vue';
import BusWidthTrailer from '@/components/modals/measurement/constructor-axles/types/BusWidthTrailer.vue';
import Truck from '@/components/modals/measurement/constructor-axles/types/Truck.vue';
import TruckWidthTrailer from '@/components/modals/measurement/constructor-axles/types/TruckWidthTrailer.vue';

export default {
  name: 'ConstructorAxles',
  components: {
    LightVehicle,
    LightVehicleWithTrailer,
    Bus,
    BusWidthTrailer,
    Truck,
    TruckWidthTrailer,
  },
  props: {
    axles: {
      type: Array,
      default: () => [],
    },
    category: {
      type: Number,
      required: false,
    },
  },
  computed: {
    getCurrentType() {
      if (this.category !== undefined) {
        if ([211, 310].includes(this.category)) return 'light-trailer';
        if ([610, 330].includes(this.category)) return 'bus';
        if ([611].includes(this.category)) return 'bus-trailer';
        if ([312, 410, 420, 430, 440, 450, 451, 510, 520, 530, 540, 550, 551, 590].includes(this.category)) return 'truck';
        if ([311, 321, 331, 340, 411, 421, 431, 441, 611].includes(this.category)) return 'truck-trailer';
      } if (this.axles.length > 4) {
        return 'truck-trailer';
      } if (this.axles.length > 2) {
        return 'truck';
      }
      return 'light';
    },
    // готовим инфу по осям
    prepareDataAllAxles() {
      return this.axles.map((item, index) => ({ ...item, axle_base: this.axles[index + 1] ? this.axles[index + 1].axle_base : '' }));
    },
    // оси для основного тс
    prepareDataAxles() {
      if (!['light-trailer', 'bus-trailer', 'truck-trailer'].includes(this.getCurrentType)) return this.prepareDataAllAxles;
      if ([211, 611].includes(this.category)) {
        return this.prepareDataAllAxles.length > 2 ? this.prepareDataAllAxles.slice(0, -1) : this.prepareDataAllAxles;
      }
      return this.prepareDataAllAxles.length > 3 ? this.prepareDataAllAxles.slice(0, -2) : this.prepareDataAllAxles;
    },
    // оси для прицепа
    prepareDataAxlesTrailer() {
      if ([211, 611].includes(this.category)) {
        return this.prepareDataAllAxles.length > 2 ? this.prepareDataAllAxles.slice(-1) : [];
      }
      return this.prepareDataAllAxles.length > 3 ? this.prepareDataAllAxles.slice(-2) : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.constructor-axles {
  display: flex;
  padding: 50px 0 40px;
}
</style>
