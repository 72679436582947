export default {
  name: 'pagination',
  props: [
    'results',
    'resultsLength',
    'resultsOnPage',
    'pageClickCallback',
  ],
  data() {
    return {
      pages: [],
      currentPage: 1,
      startPage: 1,
      lastPage: null,
      inputPageNumber: null,
    };
  },
  computed: {
    showStartPage() {
      return this.pages.length && this.pages[0].num !== this.startPage;
    },
    showLastPage() {
      return this.pages.length && this.pages[this.pages.length - 1].num !== this.lastPage;
    },
  },
  created() {
    this.paginationReinit();
  },
  watch: {
    results() {
      this.paginationReinit();
    },
    resultsLength() {
      this.paginationReinit();
    },
    currentPage() {
      this.paginationReinit();
    },
  },
  methods: {
    paginationReinit() {
      this.pages = [];
      this.lastPage = Math.ceil(this.resultsLength / this.resultsOnPage);
      let startPage = this.currentPage - 2;
      let lastPage = this.currentPage + 2;
      if (startPage < this.startPage) {
        lastPage = Math.min(lastPage + this.startPage - startPage, this.lastPage);
        startPage = this.startPage;
      }
      if (lastPage > this.lastPage) {
        startPage = Math.max(startPage + this.lastPage - lastPage, this.startPage);
        lastPage = this.lastPage;
      }
      for (let i = startPage; i <= lastPage; i += 1) {
        const page = {
          num: i,
        };
        this.pages.push(page);
      }
      if (this.pages.length === 1) {
        this.pages = [];
      }
    },
    pageClass(page) {
      return this.currentPage === page.num ? 'active' : '';
    },
    pageClick(num, forced) {
      if (this.currentPage !== num || forced) {
        this.currentPage = num;
        this.pageClickCallback((this.currentPage - 1) * this.resultsOnPage);
      }
    },
    setPageNumber() {
      let value = Number.parseInt(this.inputPageNumber, 10);
      if (!Number.isNaN(value)) {
        value = Math.min(this.lastPage, Math.max(this.startPage, value));
        this.inputPageNumber = value;
        this.pageClick(value);
      }
    },
  },
};
