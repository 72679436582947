<template>
  <BodyVehicle class="wrapper-truck">
    <div class="wrapper-axles">
      <Axle
        v-for="(item, key) in axles"
        :key="key"
        :weight="item.axle_weight"
        :isShowLine="key !== axles.length - 1 && axles.length > 1"
        :type-cardan="key < 2 ? getCurrentTypeCardan(key) : null"
        :type-wheels="item.skatnost"
        :wheels="item.wheels"
        :type-line="key < 2 ? 'middle': 'small'"
        :isTextOnLine="true"
        :distance="item.axle_base"
        :isShowDistance="key !== axles.length - 1"
        :class="{ 'special-margin': key > 0 }"
      />
      <!-- :isTextOnLine="key < 2 ? true : key % 2" -->
      <div v-if="axles.length > 2" class="truck-rectangle" />
    </div>
    <img src="@/assets/img/axles/types-axles/front-rectangle-bus.svg" class="front-rectangle-truck" />
    <div v-if="axles.length > 2" class="rectangle" />
  </BodyVehicle>
</template>

<script>
import Axle from '@/components/modals/measurement/constructor-axles/common/Axle.vue';
import BodyVehicle from '@/components/modals/measurement/constructor-axles/common/BodyVehicle.vue';

export default {
  name: 'Truck',
  components: { BodyVehicle, Axle },
  props: {
    axles: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getCurrentTypeCardan(index) {
      return index ? 'circle' : 'rectangle';
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-truck {
  padding-left: 35px;
  padding-right: 45px;
}
.wrapper-axles {
  display: flex;
  position: relative;
  .truck-rectangle {
    content: '';
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    height: calc(100% + 5px);
    width: calc(100% - 190px);
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
}
.front-rectangle-truck {
  position: absolute;
  left: 20px;
  top: 50%;
  height: 100px;
  transform: translateY(-50%);
}
.rectangle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 85px;
  width: 60px;
  height: 80px;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
</style>
