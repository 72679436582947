<template>
  <BodyVehicle class="wrapper-bus">
    <div
      v-for="(item, key) in axles"
      :key="key"
      class="bus-axle"
    >
      <Axle
        :weight="item.axle_weight"
        :isShowLine="key !== axles.length - 1 && axles.length > 1"
        :type-cardan="key === 1 && axles.length > 2 ? 'rectangle' : 'rectangle-small'"
        :type-wheels="item.skatnost"
        :wheels="item.wheels"
        :type-line="key === 1 ? 'big': 'special'"
        :isTextOnLine="true"
        :distance="item.axle_base"
        :isShowDistance="key !== axles.length - 1"
        :class="{ 'special-margin': key > 0 }"
      />
      <!-- <img
        v-if="key !== axles.length - 1"
        src="@/assets/img/axles/types-axles/top-window.svg"
        class="window top-window"
        :class="{ 'special-position': key > 0 }"
      />
      <img
        v-if="key !== axles.length - 1"
        src="@/assets/img/axles/types-axles/bottom-window.svg"
        class="window bottom-window"
        :class="{ 'special-position': key > 0 }"
      /> -->
    </div>

    <!-- <img src="@/assets/img/axles/types-axles/bus.svg" class="vehicle-img" /> -->
    <!-- <img src="@/assets/img/axles/types-axles/front-rectangle-bus.svg" class="front-rectangle-bus" />
    <img src="@/assets/img/axles/types-axles/back-rectangle-bus.svg" class="back-rectangle-bus" /> -->
    <!-- <div v-if="axles.length > 2" class="rectangle" /> -->
  </BodyVehicle>
</template>

<script>
import Axle from '@/components/modals/measurement/constructor-axles/common/Axle.vue';
import BodyVehicle from '@/components/modals/measurement/constructor-axles/common/BodyVehicle.vue';

export default {
  name: 'Bus',
  components: { BodyVehicle, Axle },
  props: {
    axles: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-bus {
  padding-left: 35px;
  padding-right: 45px;
  background: url('~@/assets/img/axles/types-axles/bus.svg') 0/100% 100%;
  &:before, &:after {
    display: none;
  }
}
.back-rectangle-bus,
.front-rectangle-bus {
  position: absolute;
  top: 50%;
  height: 100px;
  transform: translateY(-50%);
}
.back-rectangle-bus {
  right: 15px;
}
.front-rectangle-bus {
  left: 20px;
}
.bus-axle {
  position: relative;
}
.window {
  position: absolute;
  left: 30px;
  width: 60px;
}
.top-window {
  top: -5px;
}
.bottom-window {
  bottom: -5px;
}
.special-position {
  left: 10px;
}
.rectangle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 150px;
  width: 100px;
  height: 60px;
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
</style>
