import axios from 'axios';
import Grid from '@/components/Grid/Grid.vue';
import moment from 'moment';

export default {
  name: 'Services',
  components: {
    Grid,
  },
  data() {
    return {
      monitoring: {
        records: [],
        loading: true,
      },
      services: {
        records: [],
        loading: true,
      },
    };
  },
  created() {
    this.getMonitoring();
    this.getServices();
  },
  computed: {
  },
  methods: {
    getApiUrl(url, addToken = false) {
      const base = process.env.NODE_ENV === 'development' ? '/services_url' : `http://${window.location.hostname}:8902`;
      if (addToken) return `${base}${url}?token=${localStorage.token}`;
      return `${base}${url}`;
    },
    async getMonitoring() {
      try {
        const { data } = await axios.get(this.getApiUrl('/containers', true));
        this.monitoring.records = data.map((item) => ({ ...item, created: moment.unix(item.created).format('DD.MM.YYYY hh:mm:ss') }));
      } catch (e) {
        console.error('error getMonitoring: ', e);
      } finally {
        this.monitoring.loading = false;
      }
    },
    async getServices() {
      try {
        const { data } = await axios.get(this.getApiUrl('/services', true));
        this.services.records = data;
      } catch (e) {
        console.error('error getServices: ', e);
      } finally {
        this.services.loading = false;
      }
    },
    async pushCommand(command, name) {
      try {
        this.services.loading = true;
        const { data } = await axios.post(this.getApiUrl(`/services/${name}/${command}`), { token: localStorage.token });
        console.log(data);
      } catch (e) {
        console.error('error pushCommand: ', e);
      } finally {
        this.services.loading = false;
      }
    },
  },
};
