import { mapState, mapGetters, mapMutations } from 'vuex';
import mixin from '@/components/mixins/mixin';
import dataLoadInterval from '@/components/mixins/dataLoadInterval';
import FunctioningFilter from '@/components/modals/FunctioningFilter.vue';
import ResetFilter from '@/components/modals/ResetFilter.vue';
import reportsControl from '@/components/reportsControl/reportsControl.vue';
import pagination from '@/components/pagination/pagination.vue';

export default {
  name: 'Functioning',
  components: {
    FunctioningFilter,
    ResetFilter,
    reportsControl,
    pagination,
  },
  mixins: [
    mixin,
    dataLoadInterval,
  ],
  data() {
    return {
      resultsOnPage: 10,
      journalNotes: [],
      journalSize: 0,
      offsetCount: 0,
      filters: {
        state: [],
        reason: [],
        dateFrom: null,
        dateTo: null,
      },
      filtersForRequest: {},
    };
  },
  computed: {
    ...mapState([
      'timezone',
    ]),
    ...mapGetters([
      'timezoneTitle',
    ]),
    activeTime() {
      const activeTime = {};
      if (this.filters.dateFrom) {
        activeTime.dateTime = `${this.$t('interface.from')} ${this.formatTime(this.filters.dateFrom)}`;
      }
      if (this.filters.dateTo) {
        activeTime.dateTime = `${activeTime.dateTime !== undefined ? `${activeTime.dateTime} ` : ''}${this.$t('interface.to').toLowerCase()} ${this.formatTime(this.filters.dateTo)}`;
      }
      return activeTime;
    },
  },
  watch: {
    filters: {
      handler(val) {
        this.filtersForRequest = { ...val };
        this.filtersForRequest.reason = val.reason.map((item) => item.name);
        this.filtersForRequest.dateFrom = this.getOffsetTime(this.filtersForRequest.dateFrom);
        this.filtersForRequest.dateTo = this.getOffsetTime(this.filtersForRequest.dateTo);
        this.$refs.pagination.pageClick(1, true);
      },
      deep: true,
    },
  },
  created() {
    this.filtersForRequest = { ...this.filters };
    this.checkLogin();
    this.startLoadData(30000, () => {
      this.getJournalNotes();
    });
  },
  beforeDestroy() {
    this.stopLoadData();
  },
  methods: {
    ...mapMutations([
      'openModal',
    ]),
    downloadReport() {
      const data = {
        state: this.filtersForRequest.state,
        reason: this.filtersForRequest.reason,
        start_at: this.filtersForRequest.dateFrom,
        end_at: this.filtersForRequest.dateTo,
        limit: this.journalSize,
        offset: 0,
        timezone: this.timezoneTitle,
      };
      this.downloadFile(this.getApiUrl('reports_state_journal'), data);
    },
    getJournalNotes(offsetCount) {
      if (offsetCount !== undefined) {
        this.offsetCount = offsetCount;
      }
      const request = {
        method: 'state_journal.get_journal',
        token: localStorage.token,
        data: {
          state: this.filtersForRequest.state,
          reason: this.filtersForRequest.reason,
          start_at: this.filtersForRequest.dateFrom,
          end_at: this.filtersForRequest.dateTo,
          limit: this.resultsOnPage,
          offset: offsetCount,
          lang: this.$i18n.locale,
        },
      };
      this.sendRequest(request, true).then((data) => {
        this.journalNotes = data.data.map((item, index) => {
          const value = { ...item };
          value.table_id = this.offsetCount + index + 1;
          return value;
        });
        this.journalSize = data.count;
      });
    },
    removeTag(type, key) {
      if (type === 'time') {
        this.filters.dateFrom = null;
        this.filters.dateTo = null;
      } else
      if (Array.isArray(this.filters[type])) {
        this.filters[type].splice(key, 1);
      }
    },
    getColor(state) {
      let color = '';
      if (state === 'NORMAL') {
        color = 'green';
      } else
      if (state === 'SERVICE') {
        color = 'yellow';
      } else
      if (state === 'ALARM') {
        color = 'red';
      }
      return color;
    },
  },
};
