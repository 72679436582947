<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark userModal"
    size="lg"
    :footer="false"
  >
    <div slot="title">{{ $t('user_modal.title') }}</div>
    <div>
      <table class="measurements__table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t('user_modal.name') }}</th>
            <th>{{ $t('user_modal.login') }}</th>
            <th>{{ $t('user_modal.role') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in userList" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.login }}</td>
            <td>{{ $t(`user_modal.${user.role}`) }}</td>
            <td>
              <span v-if="user.role == 'user'">
                <button class="btn btn-primary" type="button" @click="deleteUser(user.id)">{{ $t('user_modal.delete') }}</button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h4>{{ $t('user_modal.new_user') }}</h4>
      <form @submit.stop.prevent="createUser()" class="form">
        <div class="form-group font-weight-bold">
          <label for="">{{ $t('user_modal.login') }}</label>
          <input
            required
            type="text"
            v-model="newUser.login"
            class="form-control font-weight-bolder"
          />
        </div>
        <div class="form-group">
          <label for="">{{ $t('user_modal.password') }}</label>
          <input
            required
            type="text"
            v-model="newUser.password"
            class="form-control font-weight-bold"
          />
        </div>
        <div class="form-group">
          <label for="">{{ $t('user_modal.name') }}</label>
          <input
            required
            type="text"
            v-model="newUser.name"
            class="form-control font-weight-bold"
          />
        </div>
        <div class="form-group">
          <label for="">{{ $t('user_modal.role') }}</label>
          <select required class="form-control font-weight-bold" v-model="newUser.role">
            <option value="admin">{{ $t('user_modal.admin') }}</option>
            <option value="user">{{ $t('user_modal.user') }}</option>
            <option value="observer">{{ $t('user_modal.observer') }}</option>
          </select>
        </div>
        <button class="btn btn-primary">{{ $t('user_modal.create_user') }}</button>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import mixin from '@/components/mixins/mixin';
import modal from '@/components/mixins/modal';

export default {
  name: 'UserModal',
  mixins: [mixin, modal],
  data() {
    return {
      userList: [],
      newUser: {
        login: '',
        password: '',
        name: '',
        role: '',
      },
    };
  },
  computed: {
    ...mapState(['isLoginned']),
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.user,
      isLoginned: 'isLoginned',
    }),
  },
  watch: {
    isLoginned(val) {
      if (val) {
        this.getUserList();
      }
    },
  },
  beforeMount() {
    if (localStorage.token) {
      this.getUserList();
    }
  },
  methods: {
    createUser() {
      const request = {
        method: 'users.create',
        token: localStorage.token,
        data: this.newUser,
      };
      this.sendRequest(request).then(() => {
        this.newUser.login = '';
        this.newUser.name = '';
        this.newUser.password = '';
        this.newUser.role = '';
        this.getUserList();
      });
    },
    deleteUser(userId) {
      const request = {
        method: 'users.delete',
        token: localStorage.token,
        data: {
          id: userId,
        },
      };
      this.sendRequest(request).then(() => {
        this.getUserList();
      });
    },
    getUserList() {
      const request = {
        method: 'users.get_list',
        token: localStorage.token,
        data: {},
      };
      this.sendRequest(request).then((data) => {
        this.userList = data.data;
      });
    },
    close() {
      this.closeModal('user');
    },
  },
};
</script>
