export default {
  data() {
    return {
      dataLoadInterval: null,
    };
  },
  methods: {
    startLoadData(int, callback) {
      callback();
      this.dataLoadInterval = setInterval(() => {
        callback();
      }, int);
    },
    stopLoadData() {
      clearInterval(this.dataLoadInterval);
    },
  },
};
