import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SettingsMain',
  data() {
    return {
      langs: [
        'ru',
        'en',
        'tr',
        'id',
      ],
    };
  },
  computed: {
    ...mapState([
      'timezone',
      'timezones',
    ]),
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.preferences,
    }),
  },
  methods: {
    ...mapMutations([
      'setTimezone',
    ]),
    setLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      window.location.reload();
    },
    close() {
      this.closeModal('preferences');
    },
  },
};
