<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark"
    size="lg"
    :footer="false"
  >
    <div slot="title">{{ $t('interface.reports') }}</div>
    <div class="filters">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <label>{{ $t('interface.from') }}:</label>
                <div class="input-group">
                  <datetimepicker opt="dateFrom" @change="updateDate" />
                </div>
              </div>
              <div class="col-6">
                <label>{{ $t('interface.to') }}:</label>
                <div class="input-group">
                  <datetimepicker opt="dateTo" @change="updateDate" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 white">
          <b>{{ $t('monitoring.data') }}:</b> {{sensor.description}}
        </div>
      </div>
      <br>
      <div class="d-flex justify-content-between">
        <div></div>
        <div class="d-flex">
          <div class="button" @click="applyFilter">
            <span class="button-image"><img src="../../assets/img/icons/search.svg"/></span>
            <span>{{ $t('interface.download_report_short') }}</span>
          </div>
          <a class="button" @click="close">
            <span class="button-image"><img src="../../assets/img/icons/close.svg"/></span>
            <span>{{ $t('action.cancel') }}</span>
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import mixin from '@/components/mixins/mixin';
import modal from '@/components/mixins/modal';
import datetimepicker from '@/components/vue-datetimepicker/vue-datetimepicker.vue';

export default {
  name: 'MonitoringReports',
  components: {
    datetimepicker,
  },
  mixins: [mixin, modal],
  props: ['sensor'],
  data() {
    return {
      filters: {
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  computed: {
    ...mapState([
      'timezone',
    ]),
    ...mapGetters([
      'timezoneTitle',
    ]),
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.monitoringReports,
    }),
  },
  methods: {
    applyFilter() {
      const data = {
        sensor: this.sensor.type,
        start_at: this.getOffsetTime(this.filters.dateFrom),
        end_at: this.getOffsetTime(this.filters.dateTo),
        limit: 1000000000,
        offset: 0,
        timezone: this.timezoneTitle,
      };
      this.downloadFile(this.getApiUrl('reports_sensors'), data);
      this.close();
    },
    close() {
      this.closeModal('monitoringReports');
    },
  },
};
</script>
