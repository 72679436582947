import mixin from '@/components/mixins/mixin';

export default {
  name: 'reportsControl',
  props: ['links'],
  mixins: [mixin],
  data() {
    return {
      reportsAreVisible: false,
      reports: [],
    };
  },
  methods: {
    changeVisibleReports() {
      this.reportsAreVisible = !this.reportsAreVisible;
    },
    clickEvent(link) {
      this.reportsAreVisible = false;
      link[1]();
    },
    clickReport(name) {
      console.log('report');
      console.log(name);

      const data = {
        filename: name,
      };
      this.downloadFile(this.getApiUrl('reports_download'), data);
    },
    loadReportsList() {
      const request = {
        method: 'reports.get_reports_list',
        token: localStorage.token,
        data: {
        },
      };
      this.sendRequest(request, true).then((data) => {
        this.reports = data.data;
        console.log('loadReportsList');
        console.log(this.reports);
      });
      console.log('loadReportsList');
      console.log(this.reports);
    },
  },
  mounted() {
    this.loadReportsList();
  },
};
