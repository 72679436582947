import { createTextMaskInputElement } from 'text-mask-core';
// import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import presets from './mask';

function inputHandler(outEl, rule) {
  const el = outEl;

  if (!el || el.value === '') return;
  let val = el.value;
  const clearVal = val.replace('+7', '').replace(/\D+/g, '');

  switch (rule.arg) {
    case 'numberDot':
      val = val.replace(/,/g, '.');
      if (val[val.length - 1] === '.') val += '00';
      break;
    case 'lat':
      if (val.length > 3 && val[val.length - 1] === '.') {
        val = val.replace(/(-?\d\d)(\d)./g, '$1.$2');
        el.value = val.replace(/(-?\d\d)(\d)./g, '$1.$2');
      }
      break;
    case 'long':
      if (val.length > 4 && val[val.length - 1] === '.') {
        val = val.replace(/(-?\d\d\d)(\d)./g, '$1.$2');
        el.value = val.replace(/(-?\d\d\d)(\d)./g, '$1.$2');
      }
      break;
    case 'phoneDynamic':
      if (clearVal.length < 4) {
        val = clearVal;
        el.value = clearVal;
        el.textMaskInputElement.state.previousConformedValue = val;
      }
      break;
    default: break;
  }
  el.prevVal = val;
  el.textMaskInputElement.update(val);

  const event = new Event('input');
  el.dispatchEvent(event);
}

function setupTextMask(outEl, rule) {
  const { mask, guide } = rule;
  const el = outEl;

  el.textMaskInputElement = createTextMaskInputElement(
    {
      ...rule,
      ...{
        inputElement: el,
        guide,
        mask,
      },
    },
  );
  inputHandler(el, rule);
}

function getInput(el) {
  return el.querySelector('.el-input__inner') || el;
}

export default {
  inserted(el, binding) {
    const rule = presets[binding.arg];
    if (!rule) {
      console.warn(`no preset ${binding.arg} for mask`); // eslint-disable-line
      return;
    }
    setupTextMask(getInput(el), { ...rule, ...binding });
  },
  componentUpdated(el, binding) {
    const input = getInput(el);
    // setTimeout(() => {
    inputHandler(input, binding);
    // });
  },
};
