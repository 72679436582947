import pagination from '@/components/pagination/pagination.vue';

export default {
  name: 'Grid',
  components: {
    pagination,
  },
  props: {
    records: Array,
    newButtonName: String,
    showSearch: {
      type: Boolean,
      default: true,
    },
    onPage: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      offset: 0,
      deleteRowIndex: -1,
      deleteModalShow: false,
      search: '',
    };
  },
  computed: {
    recordsSearch() {
      let { records } = this;
      if (!this.search) return records;
      const search = this.search.toUpperCase().trim();
      records = records.filter((row) => row.searchData && row.searchData.indexOf(search) + 1);
      return records;
    },
    recordsShow() {
      const result = [...this.recordsSearch].slice(this.offset, this.offset + this.onPage);
      if (!result.length && this.records.length && this.offset) this.$nextTick(() => { this.$refs.pagination.pageClick(1, true); });
      return result;
    },
  },
  methods: {
    pageClick(offset) {
      this.offset = offset || 0;
    },
    deleteRow(index) {
      this.deleteRowIndex = index;
      this.deleteModalShow = true;
    },
    onDelete() {
      if (this.deleteRowIndex + 1) this.$emit('delete', this.deleteRowIndex);
      this.deleteModalShow = false;
    },
  },
};
