/* eslint-disable import/no-unresolved */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login/Login.vue';
import Panel from '@/views/Panel/Panel.vue';
import Functioning from '@/views/Functioning/Functioning.vue';
import Failure from '@/views/Failure/Failure.vue';
import Monitoring from '@/views/Monitoring/Monitoring.vue';
import Hardware from '@/views/Hardware/Hardware.vue';
import Iframe from '@/components/hardware/Iframe.vue';
import AC from '@/components/hardware/AC.vue';

import Settings from '@/views/Settings/Settings.vue';
import SettingsMain from '@/views/Settings/Main/SettingsMain.vue';
import Services from '@/views/Settings/Services/Services.vue';

import Constructor from '@/views/Constructor/Constructor.vue';
import Config from '@/views/Constructor/Config/Config.vue';
import Source from '@/views/Constructor/Source/Source.vue';
import Metrics from '@/views/Constructor/Metrics/Metrics.vue';
import Groups from '@/views/Constructor/Groups/Groups.vue';
import Trigger from '@/views/Constructor/Trigger/Trigger.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: () => {
      if (!localStorage.token) {
        return '/login';
      }
      return '/panel';
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/panel',
    name: 'panel',
    component: Panel,
    children: [
      {
        path: '/panel/grid',
        name: 'panelGrid',
        component: Panel,
      },
    ],
  },
  {
    path: '/functioning',
    name: 'functioning',
    component: Functioning,
  },
  {
    path: '/failure',
    name: 'failure',
    component: Failure,
  },
  {
    path: '/monitoring',
    name: 'monitoring',
    component: Monitoring,
  },
  {
    path: '/hardwareSetup',
    name: 'hardwareSetup',
    component: Hardware,
    children: [
      {
        path: '/hardwareSetup/configure',
        name: 'configure',
        component: Iframe,
        props: {
          url_id: 'consul',
        },
      },
      {
        path: '/hardwareSetup/cameraRecognition',
        name: 'cameraRecognition',
        component: Iframe,
        props: {
          url_id: 'guacamole_url',
        },
      },
      {
        path: '/hardwareSetup/sensorClassification',
        name: 'sensorClassification',
        component: Iframe,
        props: {
          url_id: 'scaner_host',
        },
      },
      {
        path: '/hardwareSetup/inode',
        name: 'inode',
        component: Iframe,
        props: {
          url_id: 'inode_url',
        },
      },
      {
        path: '/hardwareSetup/ups',
        name: 'ups',
        component: Iframe,
        props: {
          url_id: 'ups_url',
        },
      },
      {
        path: '/hardwareSetup/ac',
        name: 'ac',
        component: AC,
      },
    ],
  },
];

const appCode = process.env.VUE_APP_CODE;

if (appCode === 'vgk') {
  routes.push({
    path: '/settings',
    name: 'settings',
    redirect: 'settings/main',
    component: Settings,
    children: [
      {
        path: '/settings/main',
        name: 'settingsMain',
        component: SettingsMain,
      },
      {
        path: '/settings/constructor',
        name: 'constructor',
        redirect: '/settings/constructor/config',
        component: Constructor,
        children: [
          {
            path: '/settings/constructor/config',
            name: 'config',
            component: Config,
          },
          {
            path: '/settings/constructor/source',
            name: 'source',
            component: Source,
          },
          {
            path: '/settings/constructor/metrics',
            name: 'metrics',
            component: Metrics,
          },
          {
            path: '/settings/constructor/groups',
            name: 'groups',
            component: Groups,
          },
          {
            path: '/settings/constructor/trigger',
            name: 'trigger',
            component: Trigger,
          },
        ],
      },
      {
        path: '/settings/services',
        name: 'services',
        component: Services,
      },
    ],
  });
} else if (appCode === 'ind' || appCode === 'ckad') {
  routes.push({
    path: '/settings',
    name: 'settings',
    redirect: 'settings/main',
    component: Settings,
    children: [
      {
        path: '/settings/main',
        name: 'settingsMain',
        component: SettingsMain,
      },
      {
        path: '/settings/constructor',
        name: 'constructor',
        redirect: '/settings/constructor/config',
        component: Constructor,
        children: [
          {
            path: '/settings/constructor/config',
            name: 'config',
            component: Config,
          },
          {
            path: '/settings/constructor/source',
            name: 'source',
            component: Source,
          },
          {
            path: '/settings/constructor/metrics',
            name: 'metrics',
            component: Metrics,
          },
          {
            path: '/settings/constructor/groups',
            name: 'groups',
            component: Groups,
          },
          {
            path: '/settings/constructor/trigger',
            name: 'trigger',
            component: Trigger,
          },
        ],
      },
      {
        path: '/settings/services',
        name: 'services',
        component: Services,
      },
    ],
  });
} else {
  routes.push({
    path: '/settings',
    name: 'settings',
    component: SettingsMain,
  });
}

const router = new VueRouter({
  routes,
});

export default router;
