import constructorEdit from '@/components/mixins/constructorEdit';
import Grid from '@/components/Grid/Grid.vue';

export default {
  name: 'Trigger',
  components: {
    Grid,
  },
  mixins: [
    constructorEdit,
  ],
  data() {
    return {
      field: 'triggers',
      ruleForm: {
        name: '',
        display_name: 'lang',
      },
      emptyRuleForm: {
        name: '',
        display_name: 'lang',
      },
    };
  },
  computed: {
    isValid() {
      return this.ruleForm.name && Object.values(this.ruleForm.display_name).some((val) => val) && !this.records.find((item, i) => item.name === this.ruleForm.name && i !== this.ruleFormRowIndex);
    },
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.ruleForm = this.emptyRuleForm;
      }
    },
  },
  methods: {
    onSubmitForm(ruleForm) {
      if (this.ruleFormRowIndex < 0) return ruleForm;
      const { name } = this.json[this.field][this.ruleFormRowIndex];
      if (name === ruleForm.name) return ruleForm;
      const metrics = this.json.metrics.map((item) => {
        if (!item.trigger || item.trigger !== name) return item;
        return {
          ...item,
          trigger: ruleForm.name,
        };
      });

      this.json.metrics = metrics.map((item) => {
        if (!item.deny) return item;
        const text = JSON.stringify(item.deny);
        if (text.indexOf(`"!${name}!`) < 0) return item;
        return {
          ...item,
          deny: JSON.parse(text.replace(`"!${name}!`, `"!${ruleForm.name}!`)),
        };
      });

      return ruleForm;
    },
  },
};
