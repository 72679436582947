import Navbar from '@/components/Navbar/Navbar.vue';
import EventBus from '@/eventBus.js';

export default {
  name: 'ROOT',
  data() {
    return {
      loading: false,
    };
  },
  components: {
    Navbar,
  },
  watch: {
    '$route.path': function routePath() {
      this.loading = false;
    },
  },
  created() {
    EventBus.$on('set-loading', (payload) => {
      this.loading = payload;
    });
  },
  mounted() {
    this.$options.sockets.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);
        Object.keys(this.$store.state.wsList).forEach((key) => {
          if (Number(key) === Number(data.index)) {
            if (data.status === 'ok') {
              this.$store.state.wsList[key].resolve(data);
            } else
            if (data.status === 'error') {
              this.$store.state.wsList[key].reject(data);
            }
            if (this.$store.state.wsList[key].wait) {
              EventBus.$emit('set-loading', false);
            }
            delete this.$store.state.wsList[key];
          }
        });
        this.$store.commit('wsGetMessage', data);
      } catch (err) {
        console.error(err);
      }
    };
  },
};
