<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark meaurementModal"
    size="lg"
  >
    <div slot="title">
      <div class="modal-tabs">
        <div
          v-for="tab in tabs"
          :key="tab"
          class="modal-tabs-item"
          :class="{ 'active': currentTab === tab }"
          @click="currentTab = tab"
        >
          {{ $t(`measurement_modal.${tab}`) }}
        </div>
      </div>
    </div>
    <CommonInfoTab v-if="currentTab === 'info'" :measurement="measurement" />
    <VehicleTab v-if="currentTab === 'info-ts'" :measurement="measurement" />
    <TechnicalDataTab v-if="currentTab === 'info-tech'" :measurement="measurement" />
    <div slot="footer">
      <div class="center-block d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-danger btn-raised"
          @click="close"
        >
          {{ $t('action.close') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import modal from '@/components/mixins/modal';
import CommonInfoTab from '@/components/modals/measurement/CommonInfoTab.vue';
import VehicleTab from '@/components/modals/measurement/VehicleTab.vue';
import TechnicalDataTab from '@/components/modals/measurement/TechnicalDataTab.vue';

export default {
  name: 'MeasurementModal',
  props: ['measurement'],
  components: {
    CommonInfoTab,
    VehicleTab,
    TechnicalDataTab,
  },
  data() {
    return {
      currentTab: 'info',
      tabs: [
        'info',
        'info-ts',
        'info-tech',
      ],
    };
  },
  mixins: [modal],
  computed: {
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.measurement,
    }),
  },
  methods: {
    close() {
      this.closeModal('measurement');
    },
  },
};
</script>
