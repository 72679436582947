export default {
  name: 'GrnzPlate',
  props: [
    'gnz',
    'country',
    'flag',
    'number_type',
  ],
  methods: {
    parseGrnzNumber(number, numberType, part) {
      let numType = numberType;
      if (!numberType) {
        numType = '1';
      }
      if (!number || !numType) return number;
      const types = {
        1: 6,
        '1a': 6,
        '1b': 5,
        2: 6,
        5: 6,
        9: 6,
        10: 7,
        19: 6,
        20: 5,
        21: 4,
        23: 7,
      };
      const numArray = [number.slice(0, types[numType]), number.slice(types[numType])];
      return numArray[part];
    },
    rus_to_latin(str) {
      if (!str) return str;
      const ru = {
        а: 'a',
        в: 'b',
        е: 'e',
        ё: 'e',
        к: 'k',
        м: 'm',
        н: 'h',
        о: 'o',
        р: 'p',
        с: 'c',
        т: 't',
        у: 'y',
        х: 'x',
      };
      let nStr = null;
      nStr = [];
      const strAfter = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');
      for (let i = 0; i < strAfter.length; i += 1) {
        nStr.push(
          ru[strAfter[i]]
            || (ru[strAfter[i].toLowerCase()] === undefined && strAfter[i])
            || ru[strAfter[i].toLowerCase()].replace(/^(.)/, (match) => match.toUpperCase()),
        );
      }
      return nStr.join('');
    },
  },
};
