import EventBus from '@/eventBus.js';
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      json: {},
      baseJson: '{ "languages": ["RU","EN"], "web_groups": [], "triggers": [], "metrics": [], "sources": [] }',

      lang: ['ru', 'en', 'tr', 'id'],
      protocolList: ['snmp', 'NTP', 'SystemState', 'Zabbix', 'UraganSSHCommand', 'Advantech', 'REST', 'FrameVersion', 'FrameBoot', 'Shtil', 'Calculation', 'Local', 'mercury236', 'mercury236log', 'Text', 'Db'],
      typeList: ['int', 'float', 'string', 'bool'],
    };
  },
  created() {
    if (!this.$store.state.configFile && sessionStorage.getItem('configFile')) {
      const json = JSON.parse(sessionStorage.getItem('configFile'));
      this.setConfigFile(json);
    }
    window.addEventListener('beforeunload', this.setConfigFileStore);
  },
  mounted() {
    if (this.dontSave) return;
    EventBus.$on('actual-config-file-store', this.setConfigFileStore);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.setConfigFileStore);
    if (this.dontSave) return;
    this.setConfigFileStore();
    EventBus.$off('actual-config-file-store', this.setConfigFileStore);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.dontSave) this.setConfigFileStore();
    next();
  },
  methods: {
    ...mapMutations([
      'setConfigFile',
    ]),
    setConfigFileStore() {
      this.setConfigFile(this.jsonNormalize(this.json));
    },
    jsonNormalize(obj) {
      try {
        const json = this.jsonParse(obj);
        return json;
      } catch (e) {
        console.error('error jsonNormalize:', e);
        return obj;
      }
    },
    jsonParse(obj) {
      let json = JSON.parse(this.baseJson);
      Object.keys(obj).forEach((key) => {
        json[key.toLowerCase()] = obj[key];
      });
      json = this.normalizeLanguages(json);

      json.web_groups = this.normalizeFields(json.web_groups);
      json.triggers = this.normalizeFields(json.triggers);
      json.metrics = this.normalizeFields(json.metrics);
      json.sources = this.normalizeFields(json.sources, true);

      json = this.normalizeSources(json);
      json = this.normalizeMetrics(json);
      return json;
    },
    normalizeFields(arr, isSource) {
      const namesRepeat = [];
      const name = isSource ? 'Name' : 'name';
      return arr
        .filter((obj) => typeof obj === 'object')
        .map((obj) => {
          const result = {};
          Object.keys(obj).forEach((key) => {
            let newKey = key.toLowerCase();
            if (isSource) newKey = newKey[0].toUpperCase() + newKey.slice(1);
            result[newKey] = obj[key];
          });
          return result;
        }).filter((obj) => {
          if (namesRepeat.indexOf(obj[name]) + 1) return false;
          namesRepeat.push(obj[name]);
          return true;
        });
    },
    normalizeLanguages(obj) {
      const lang = obj.languages.map((key) => `"${key}"`).join('|');
      const reg = new RegExp(`(${lang})`, 'gi');
      const json = JSON.parse(JSON.stringify(obj).replace(reg, (c) => c.toLowerCase()));
      json.languages = json.languages.map((item) => item.toUpperCase());
      return json;
    },
    normalizeSources(obj) {
      const json = { ...obj };
      const protocolList = this.protocolList.map((item) => item.toLowerCase());
      json.sources = json.sources.map((item) => {
        const index = protocolList.indexOf(item.Protocol.toLowerCase());
        return {
          ...item,
          Protocol: index + 1 ? this.protocolList[index] : item.Protocol,
        };
      });
      return json;
    },
    normalizeMetrics(obj) {
      const json = { ...obj };
      const typeList = this.typeList.map((item) => item.toLowerCase());
      json.metrics = json.metrics.map((item) => {
        const index = item.type ? typeList.indexOf(item.type.toLowerCase()) : -1;
        return {
          ...item,
          type: index + 1 ? this.typeList[index] : item.type,
        };
      });
      return json;
    },
  },
};
