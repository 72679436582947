import emailMask from 'text-mask-addons/dist/emailMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  email: {
    mask: emailMask,
    guide: true,
  },
  phone: {
    mask: createNumberMask({
      prefix: '+',
      includeThousandsSeparator: false,
      allowDecimal: false,
      integerLimit: 15,
      allowLeadingZeroes: true,
    }),
    guide: false,
  },
  innerPhone: {
    mask: [
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: true,
    showMask: true,
  },
  phoneRF: {
    mask: [
      '+',
      '7',
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  phoneDynamic: {
    mask: [
      '+',
      '7',
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  ip: {
    mask: [
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: true,
  },
  host: {
    mask: (value) => {
      const mask = Array(value.length).fill(/[\d.]/);
      const chunks = value.split('.');

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 4; ++i) {
        const chunk = (chunks[i] || '');

        if (255 % +chunk === 255) {
          mask[value.length - 1] = '.';
          mask[value.length] = /[\d.]/;
        }
      }

      return mask;
    },
    pipe: (value) => {
      if (value === '.' || value.endsWith('..')) return false;
      const parts = value.split('.');
      if (parts.length > 4 || parts.some((part) => part === '00' || part < 0 || part > 255)) return false;
      return value;
    },
    guide: false,
  },
  lat: {
    mask: createNumberMask({
      prefix: false,
      decimalLimit: 6,
      integerLimit: 2,
      requireDecimal: true,
      allowNegative: true,
      allowLeadingZeroes: true,
    }),
    guide: false,
  },
  long: {
    mask: createNumberMask({
      prefix: false,
      decimalLimit: 6,
      integerLimit: 3,
      requireDecimal: true,
      allowNegative: true,
      allowLeadingZeroes: true,
    }),
    guide: false,
  },
  documentNumberRF: {
    mask: [
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: false,
  },
  documentIssuerCodeRF: {
    mask: [
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
    ],
    guide: false,
  },
  number: {
    number: true,
    mask: [...new Array(100)].map(() => /\d/),
    guide: false,
  },
  noZeroNumber: {
    mask: createNumberMask({
      prefix: false,
      includeThousandsSeparator: false,
      allowDecimal: true,
    }),
    guide: false,
  },
  numberPos: {
    number: true,
    mask: [/[1-9]/, ...[...new Array(100)].map(() => /\d/)],
    guide: false,
  },
  numberDot: {
    mask: createNumberMask({
      prefix: false,
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ' ',
      requireDecimal: true,
      allowDecimal: true,
      decimalLimit: 6,
    }),
    // placeholderChar: '0',
    // guide: true,
    guide: false,
  },
  numberDotMobile: {
    mask: createNumberMask({
      prefix: false,
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ' ',
      requireDecimal: true,
      allowDecimal: true,
      decimalLimit: 6,
    }),
    // placeholderChar: '0',
    // guide: true,
    guide: false,
  },
  float: {
    mask: createNumberMask({
      prefix: false,
      includeThousandsSeparator: false,
      allowDecimal: true,
      decimalLimit: 20,
      allowNegative: true,
    }),
    guide: false,
  },
  floatPlus: {
    mask: createNumberMask({
      prefix: false,
      includeThousandsSeparator: false,
      allowDecimal: true,
      decimalLimit: 20,
    }),
    guide: false,
  },
  name: {
    mask: [...new Array(100)].map(
      () => /([A-я]|[A-z]|[0-9]|-|№|Ё|ё| )/,
    ),
    guide: false,
  },
  code: {
    mask: [...new Array(100)].map(
      () => /([A-Z]|[a-z]|[0-9]|-|_|\.)/,
    ),
    guide: false,
  },
  latintext: {
    mask: [...new Array(100)].map(
      () => /([A-Z]|[a-z])|\d/,
    ),
    guide: false,
  },
  latinNumber: {
    mask: [...new Array(100)].map(
      () => /([A-Z]|[a-z]|[0-9])|\d/,
    ),
    guide: false,
  },
  ru: {
    mask: [...new Array(100)].map(
      () => /([А-Я]|[а-я]|Ё|ё| )/,
    ),
    guide: false,
  },
  ruNumber: {
    mask: [...new Array(100)].map(
      () => /([А-Я]|[а-я]|Ё|ё|[0-9]|\.|-| )|\d/,
    ),
    guide: false,
  },
  priority: {
    number: true,
    mask: [...new Array(3)].map(() => /\d/),
    guide: false,
  },
  inputtag: {
    mask: [...new Array(100)].map(
      () => /[^{}]/,
    ),
    guide: false,
  },
};
