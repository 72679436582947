import mixin from '@/components/mixins/mixin';
import EventBus from '@/eventBus.js';

import Settings from '@/views/Constructor/Settings/Settings.vue';

export default {
  name: 'Constructor',
  mixins: [mixin],
  components: {
    Settings,
  },
  data() {
    return {
      configErrors: false,
      showSettings: false,
    };
  },
  mounted() {
    this.checkLogin();
    EventBus.$on('config-errors', (payload) => {
      this.configErrors = payload;
    });
  },
  beforeDestroy() {
    EventBus.$off('config-errors');
  },
  methods: {
    showSettingsHandler() {
      EventBus.$emit('actual-config-file-store');
      setTimeout(() => {
        this.showSettings = true;
      });
    },
  },
};
