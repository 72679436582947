<template>
  <div class="axles_constructor">
    <div v-for="(item, key) in prepareDataAllAxles" :key="key" class="axle">
      <div :class="{ 'head': true, 'is-overheight': item.is_axle_overweight && settings.overweight_axles_highlight}">
        <p class="nowrap"><b :class="{ 'color-red' : axlesViolations.includes(item.axle_num.toString()) && settings.overweight_axles_highlight}">{{ item.axle_weight || '' }}</b></p>
        <span />
      </div>
      <div class="body">
        <img
          v-for="i in item.skatnost"
          :key="i"
          src="@/assets/img/axles/axle.svg"
          :style="{ 'margin-left': `-${i === 1 ? 0 : 7}px`}"
        />
      </div>
      <div v-if="key !== prepareDataAllAxles.length - 1" class="footer">
        <p class="nowrap"><b>{{ item.axle_base || '' }}</b></p>
        <div class="arrow">
          <img src="@/assets/img/axles/row.svg" class="row left">
          <img src="@/assets/img/axles/row.svg" class="row right">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AxlesConstructor',
  props: {
    axles: {
      type: Array,
      required: true,
    },
    violations: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    category: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      axlesViolations: [],
    };
  },

  methods: {
    prepareViolations() {
      /* eslint-disable */
      var result = [];
      if (!Object.keys(this.violations).includes('axle_weight')) {
        return result;
      }
      for (const axls in this.violations.axle_weight) {
         result.push(...axls.split(','))
      }
      this.axlesViolations = result;
      /* eslint-enable */
      return null;
    },
  },
  computed: {
    // готовим инфу по осям
    prepareDataAllAxles() {
      this.prepareViolations();
      return this.axles.map((item, index) => ({ ...item, axle_base: this.axles[index + 1] ? this.axles[index + 1].axle_base : '' }));
    },
  },
};
</script>
