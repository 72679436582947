import EventBus from '@/eventBus.js';
import constructor from '@/components/mixins/constructor';

export default {
  mixins: [
    constructor,
  ],
  data() {
    return {
      ruleFormRowIndex: -1,
      clearForm: {},
      field: '',
      showModal: false,
      pulseIndex: -1,
      displayName: {},
    };
  },
  created() {
    const { configFile } = this.$store.state;
    if (!configFile) {
      this.$router.push('/settings/constructor/config');
      return;
    }
    this.json = configFile;
    if (!this.ruleForm) return;
    this.$nextTick(this.setRuleForm);
  },
  mounted() {
    EventBus.$on('update-config-language', this.updateConfigLanguage);
  },
  beforeDestroy() {
    EventBus.$off('update-config-language', this.updateConfigLanguage);
  },
  watch: {
    pulseIndex(val) {
      if (val + 1) {
        setTimeout(() => {
          this.pulseIndex = -1;
        }, 800);
      }
    },
    showModal(val) {
      this.validateOid();
      if (!val) {
        this.ruleForm = JSON.parse(JSON.stringify(this.clearForm));
        this.ruleFormRowIndex = -1;
      }
    },
  },
  computed: {
    baseRecords() {
      return this.json[this.field] ? this.json[this.field].map((item, index) => ({
        ...item, index, isCellPulse: this.pulseIndex === index, searchData: this.getSearchData(item),
      })) : [];
    },
    records() {
      return this.baseRecords;
    },
    langNames() {
      if (!this.json.languages) return [];
      return this.json.languages.map((lang) => lang.toLowerCase());
    },
  },
  methods: {
    setRuleForm() {
      const lang = {};
      this.langNames.forEach((item) => { lang[item] = ''; });
      this.displayName = { ...lang };
      Object.keys(this.ruleForm).forEach((field) => {
        if (this.ruleForm[field] === 'lang') this.ruleForm[field] = { ...lang };
      });
      this.clearForm = JSON.parse(JSON.stringify(this.ruleForm));
    },
    onSubmit() {
      let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
      if (typeof this.onSubmitForm === 'function') ruleForm = this.onSubmitForm(ruleForm);
      if (this.ruleFormRowIndex + 1) {
        this.json[this.field][this.ruleFormRowIndex] = ruleForm;
        this.json[this.field] = [...this.json[this.field]];
        this.pulseIndex = this.ruleFormRowIndex;
      } else {
        this.json[this.field].unshift(ruleForm);
        this.pulseIndex = 0;
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
      this.showModal = false;
    },
    editRow(index) {
      if (!this.baseRecords[index]) return;
      let ruleForm = JSON.parse(JSON.stringify(this.baseRecords[index]));
      if (typeof this.onEditRow === 'function') ruleForm = this.onEditRow(ruleForm);
      ruleForm = { ...this.clearForm, ...ruleForm };
      Object.keys(this.clearForm).forEach((key) => {
        if (typeof this.clearForm[key] === 'object' && !Array.isArray(this.clearForm[key])) {
          ruleForm[key] = { ...this.clearForm[key], ...ruleForm[key] };
        }
      });

      this.ruleForm = ruleForm;
      this.ruleFormRowIndex = index;
      this.showModal = true;
    },
    copyRow(index) {
      if (!this.baseRecords[index]) return;
      let ruleForm = JSON.parse(JSON.stringify(this.baseRecords[index]));
      if (typeof this.onEditRow === 'function') ruleForm = this.onEditRow(ruleForm);
      ruleForm = { ...this.clearForm, ...ruleForm };
      Object.keys(this.clearForm).forEach((key) => {
        if (typeof this.clearForm[key] === 'object' && !Array.isArray(this.clearForm[key])) {
          ruleForm[key] = { ...this.clearForm[key], ...ruleForm[key] };
        }
      });
      ruleForm.name += '_copy';
      this.ruleForm = ruleForm;
      this.ruleFormRowIndex = -1;
      this.showModal = true;
    },
    deleteRow(index) {
      this.json[this.field].splice(index, 1);
    },
    updateConfigLanguage(languages) {
      this.json.languages = languages;
      this.setRuleForm();
    },
    getSearchData(row) {
      let result = '';
      try {
        // eslint-disable-next-line consistent-return
        Object.values(row).forEach((value) => {
          if (Array.isArray(value) || typeof value !== 'object') {
            result += `|${JSON.stringify(value)}`;
            return true;
          }
          result += Object.values(row).map((item) => JSON.stringify(item)).join('|');
        });
      } catch (e) {
        //
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return result.toUpperCase().replace(/<\/?[^>]+(>|$)/g, ' ');
      }
    },
    validateOid() {
      this.oidValid = true;
      if (this.ruleForm.oid.length === 0) {
        this.oidValid = true;
        return;
      }
      this.records.forEach((record) => {
        if (record.name !== this.ruleForm.name && record.oid === this.ruleForm.oid) {
          this.oidValid = false;
        }
      });
    },
  },
};
