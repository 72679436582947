export default {
  props: ['metricsList', 'value', 'isEdit'],
  data() {
    return {
      formulas: [],
      allTmiParams: {},
      formulaLoading: false,
      arrSigns: ['type_boolean', 'type_sign'],
      arrOperators: ['type_boolean', 'type_sign', 'type_operator', 'type_math'],
      arrUnitOperators: ['type_boolean', 'type_sign', 'type_math'],
      arrMetrics: ['type_metrics'],
      arrValueTypeBoolean: ['boolean', 'select'],
      lists: {
        status: [this.$t('metrics.normal'), this.$t('metrics.warning'), this.$t('metrics.error'), this.$t('metrics.unknown')],
        boolean: { true: 'TRUE', false: 'FALSE' },
      },
    };
  },
  computed: {
    formulaText() {
      return this.formulas.map((el) => {
        if (el.value_list && this.lists[el.value_list]) {
          return this.lists[el.value_list][el.value] || el.value;
        }
        if (this.arrMetrics.includes(el.type) && el.metrics_name) {
          const val = el.metrics_name.trim();
          if (el.metrics_type && el.metrics_type.toLowerCase() !== 'base' && this.metricsTypes[el.metrics_type.toLowerCase()]) return `'${val}.${el.metrics_type.toUpperCase()}'`;
          return `'${val}'`;
        }
        if (el.deny_name) return `'${el.deny_name.trim()}'`;
        if (!el.value) return '';

        if (el.value_type === 'float' && el.value.indexOf('.') < 0) return `${el.value.trim()}.0`;
        return el.value.trim();
      }).join(' ');
    },
    metricsNames() {
      const result = {};
      this.metricsList.forEach((item) => {
        result[item.name] = item;
      });
      return result;
    },
  },
};
