import { mapState } from 'vuex';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import eonosdandatetimepicker from 'eonasdan-bootstrap-datetimepicker';
// eslint-disable-next-line no-unused-vars
import momentTimezone from 'moment-timezone';
// eslint-disable-next-line no-multi-assign
const $ = (window.jQuery = require('jquery'));

export default {
  name: 'vue-datetimepicker',
  props: ['opt'],
  data() {
    return {
      config: {
        format: 'DD.MM.YYYY HH:mm:ss',
        useCurrent: false,
      },
      el: null,
    };
  },
  computed: {
    ...mapState([
      'timezone',
      'timezones',
    ]),
  },
  watch: {
    timezone: {
      handler(val) {
        this.config.timeZone = this.timezones.filter((item) => item.offset === val)[0].title;
        if (this.el) {
          this.el.timeZone(this.config.timeZone);
        }
      },
      immediate: true,
    },
    '$i18n.locale': {
      handler(val) {
        this.config.locale = val;
        if (this.el) {
          this.el.locale(this.config.locale);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    $(this.$el).datetimepicker(this.config).on('dp.change', (e) => {
      this.$emit('change', e.date, this.opt);
    });
    this.el = $(this.$el).data('DateTimePicker');
  },
  methods: {
    clickCalendar() {
      this.el.show();
    },
    clear() {
      this.el.clear();
    },
    setDate(value) {
      this.el.date(value ? moment(value).format('DD.MM.YYYY HH:mm:ss') : null);
    },
  },
};
