import { mapState, mapGetters, mapMutations } from 'vuex';
import VueGallerySlideshow from 'vue-gallery-slideshow';
import { videoPlayer } from 'vue-video-player';
import MeasurementFilter from '@/components/modals/MeasurementFilter.vue';
import ResetFilter from '@/components/modals/ResetFilter.vue';
import MeasurementModal from '@/components/modals/measurement/MeasurementModal.vue';
import panelList from '@/components/panelList/panelList.vue';
import panelGrid from '@/components/panelGrid/panelGrid.vue';
import reportsControl from '@/components/reportsControl/reportsControl.vue';
import mixin from '@/components/mixins/mixin';

export default {
  name: 'Panel',
  components: {
    panelList,
    panelGrid,
    MeasurementFilter,
    ResetFilter,
    reportsControl,
    MeasurementModal,
    VueGallerySlideshow,
    videoPlayer,
  },
  mixins: [mixin],
  data() {
    return {
      currentMeasurement: {},
      galleryIndex: null,
      galleryImages: [],
      filters: {
        dateFrom: null,
        dateTo: null,
        pan: null,
        gnz: null,
        selectedCountries: [],
        selectedEctn28classes: [],
        selectedIndClasses: [],
        autodorClass: [],
        sdkpClass: [],
        lanes: [],
        noGRZ: false,
        noRearGRZ: false,
        notRUS: false,
        many_pan: false,
        no_category: false,
        nearby_dsrc: false,
        measurement_id: null,
        lengthFrom: null,
        lengthTo: null,
        widthFrom: null,
        widthTo: null,
        heightFrom: null,
        heightTo: null,
        speedFrom: null,
        speedTo: null,
        weightFrom: null,
        weightTo: null,
        dsrcTypes: [],
        front_back_plates_diff_not_1: false,
        with_trailers: false,
        roadar_prob_not_1: false,
        ton12: false,
        no_camera: false,
        image_request: false,
        rfid: false,
        SVPDateFrom: null,
        SVPDateTo: null,
        is_plate_valid: false,
        is_classification_valid: false,
        is_dsrc_valid: false,
        is_gp_valid: false,
        has_many_errors: false,
        viol_pdd: false,
        viol_speed: false,
        viol_speed20: false,
        viol_speed40: false,
        viol_speed60: false,
        viol_speed80: false,
        viol_collision: false,
        viol_dimensions: false,
        viol_weight: false,
        axles_operator: false,
        axles: null,
        same_gnz: null,
        same_gnz_2: null,
        same_pan: null,
        validity_first_axle: null,
        validity_half_axle_max: null,
        validity_axles_speed: null,
        validity_speed_in_range: null,
        validity_lane_change: null,
      },
    };
  },
  computed: {
    ...mapState([
      'timezone',
    ]),
    ...mapGetters([
      'timezoneTitle',
    ]),
    activeFilter() {
      const filter = {};
      filter.dateTime = {};
      filter.SVPDateTime = {};
      filter.length = {};
      filter.width = {};
      filter.height = {};
      filter.speed = {};
      filter.weight = {};
      filter.manual = [];
      filter.axles = { cnt: 0, operator: '' };
      // eslint-disable-next-line
      for (const key in this.filters) {
        if (key === 'axles') {
          filter.axles.cnt = this.filters.axles;
          filter.axles.operator = this.filters.axles_operator;
        } else
        if (key === 'axles_operator') {
          filter.axles.operator = this.filters.axles_operator;
        } else
        if (Array.isArray(this.filters[key]) && this.filters[key].length) {
          filter[key] = this.filters[key].slice().sort((a, b) => a - b);
        } else
        if (!Array.isArray(this.filters[key]) && this.filters[key]) {
          if (key === 'dateFrom') {
            filter.dateTime.from = this.formatTime(this.filters[key]);
          } else
          if (key === 'dateTo') {
            filter.dateTime.to = this.formatTime(this.filters[key]);
          } else
          if (key === 'SVPDateFrom') {
            filter.SVPDateTime.from = this.formatTime(this.filters[key]);
          } else
          if (key === 'SVPDateTo') {
            filter.SVPDateTime.to = this.formatTime(this.filters[key]);
          } else
          if (key === 'lengthFrom') {
            filter.length.from = this.filters[key];
          } else
          if (key === 'lengthTo') {
            filter.length.to = this.filters[key];
          } else
          if (key === 'widthFrom') {
            filter.width.from = this.filters[key];
          } else
          if (key === 'widthTo') {
            filter.width.to = this.filters[key];
          } else
          if (key === 'heightFrom') {
            filter.height.from = this.filters[key];
          } else
          if (key === 'heightTo') {
            filter.height.to = this.filters[key];
          }
          if (key === 'speedFrom') {
            filter.speed.from = this.filters[key];
          } else
          if (key === 'speedTo') {
            filter.speed.to = this.filters[key];
          }
          if (key === 'weightFrom') {
            filter.weight.from = this.filters[key];
          } else
          if (key === 'weightTo') {
            filter.weight.to = this.filters[key];
          } else
          if (key === 'is_plate_valid' || key === 'is_classification_valid' || key === 'is_dsrc_valid' || key === 'has_many_errors' || key === 'is_gp_valid') {
            filter.manual.push(key);
          } else {
            filter[key] = this.filters[key];
          }
        }
      }
      if (!filter.dateTime.from || !filter.dateTime.to) {
        delete filter.dateTime;
      }
      if (!filter.SVPDateTime.from || !filter.SVPDateTime.to) {
        delete filter.SVPDateTime;
      }
      if (!filter.length.from || !filter.length.to) {
        delete filter.length;
      }
      if (!filter.width.from || !filter.width.to) {
        delete filter.width;
      }
      if (!filter.height.from || !filter.height.to) {
        delete filter.height;
      }
      if (!filter.speed.from || !filter.speed.to) {
        delete filter.speed;
      }
      if (!filter.weight.from || !filter.weight.to) {
        delete filter.weight;
      }
      if (filter.manual.length === 0) {
        delete filter.manual;
      }
      if (filter.axles.cnt === 0 || filter.axles.operator === '' || filter.axles.cnt === null) {
        delete filter.axles;
      }
      delete filter.dateFrom;
      delete filter.dateTo;
      delete filter.SVPDateFrom;
      delete filter.SVPDateTo;
      delete filter.lengthFrom;
      delete filter.lengthTo;
      delete filter.widthFrom;
      delete filter.widthTo;
      delete filter.heightFrom;
      delete filter.heightTo;
      delete filter.speedFrom;
      delete filter.speedTo;
      delete filter.weightFrom;
      delete filter.weightTo;
      delete filter.axles_operator;

      return filter;
    },
    filtersForRequest() {
      const filter = [];
      const skipReasons = [];
      const weighterValidity = [];
      if (this.filters.same_gnz) {
        skipReasons.push(10);
      }
      if (this.filters.same_gnz_2) {
        skipReasons.push(11);
      }
      if (this.filters.same_pan) {
        skipReasons.push(16);
      }
      if (this.filters.no_vehicle_no_ectn) {
        skipReasons.push(21);
      }
      if (this.filters.validity_first_axle) {
        weighterValidity.push(1);
      }
      if (this.filters.validity_half_axle_max) {
        weighterValidity.push(2);
      }
      if (this.filters.validity_axles_speed) {
        weighterValidity.push(3);
      }
      if (this.filters.validity_speed_in_range) {
        weighterValidity.push(4);
      }
      if (this.filters.validity_lane_change) {
        weighterValidity.push(5);
      }
      if (skipReasons.length > 0) {
        filter.push({
          field: 'skip_reason',
          operator: 'in_int',
          value: skipReasons,
        });
      }
      if (weighterValidity.length > 0) {
        filter.push({
          field: 'weighter_validity',
          operator: 'in_int',
          value: weighterValidity,
        });
      }
      if (this.filters.dateFrom && this.filters.dateTo) {
        filter.push({
          field: 'datetime',
          operator: 'between_datetime',
          value: [this.getOffsetTime(this.filters.dateFrom), this.getOffsetTime(this.filters.dateTo)],
        });
      }
      if (this.filters.pan) {
        filter.push({
          field: 'dsrc_list',
          operator: 'dsrc_overlap',
          value: [`${this.filters.pan}`],
        });
      }
      if (this.filters.gnz) {
        filter.push({
          field: 'gnz',
          operator: 'like',
          value: `%${this.filters.gnz.toLowerCase()}%`,
        });
      }
      if (this.filters.selectedCountries.length) {
        filter.push({
          field: 'gnz_country',
          operator: 'in_text',
          value: this.filters.selectedCountries.map((item) => item.code),
        });
      }
      if (this.filters.selectedEctn28classes.length) {
        filter.push({
          field: 'category_by_sick',
          operator: 'in_int',
          value: this.filters.selectedEctn28classes,
        });
      }
      if (this.filters.selectedIndClasses.length) {
        filter.push({
          field: 'class_ind',
          operator: 'in_int',
          value: this.filters.selectedIndClasses,
        });
      }
      if (this.filters.autodorClass.length) {
        filter.push({
          field: 'class_autodor',
          operator: 'in_int',
          value: this.filters.autodorClass,
        });
      }
      if (this.filters.sdkpClass.length) {
        filter.push({
          field: 'class_sdkp',
          operator: 'in_int',
          value: this.filters.sdkpClass,
        });
      }
      if (this.filters.lanes.length) {
        filter.push({
          field: 'lane',
          operator: 'in_int',
          value: this.filters.lanes,
        });
      }
      if (this.filters.noGRZ) {
        filter.push({
          field: 'gnz',
          operator: 'equal_or_null',
          value: null,
        });
      }
      if (this.filters.noRearGRZ) {
        filter.push({
          field: 'back_cam_gnz',
          operator: 'equal_or_null',
          value: null,
        });
      }
      if (this.filters.notRUS) {
        filter.push({
          field: 'gnz_country',
          operator: 'not_in_text',
          value: ['RUS'],
        });
      }
      if (this.filters.many_pan) {
        filter.push({
          field: 'many_pan',
          operator: 'many_pan',
          value: null,
        });
      }
      if (this.filters.no_category) {
        filter.push({
          field: 'category_by_sick',
          operator: 'equal_or_null',
          value: null,
        });
      }
      if (this.filters.nearby_dsrc) {
        filter.push({
          field: 'is_nearby_dsrc',
          operator: 'equal',
          value: true,
        });
      }
      if (this.filters.measurement_id) {
        filter.push({
          field: 'measurement_id',
          operator: 'equal',
          value: `${this.filters.measurement_id}`,
        });
      }
      if (this.filters.lengthFrom && this.filters.lengthTo) {
        filter.push({
          field: 'car_length',
          operator: 'between',
          value: [this.filters.lengthFrom, this.filters.lengthTo],
        });
      }
      if (this.filters.widthFrom && this.filters.widthTo) {
        filter.push({
          field: 'car_width',
          operator: 'between',
          value: [this.filters.widthFrom, this.filters.widthTo],
        });
      }
      if (this.filters.heightFrom && this.filters.heightTo) {
        filter.push({
          field: 'car_height',
          operator: 'between',
          value: [this.filters.heightFrom, this.filters.heightTo],
        });
      }
      if (this.filters.speedFrom && this.filters.speedTo) {
        filter.push({
          field: 'scan_speed',
          operator: 'between',
          value: [this.filters.speedFrom / 3.6, this.filters.speedTo / 3.6],
        });
      }
      if (this.filters.weightFrom && this.filters.weightTo) {
        filter.push({
          field: 'total_weight',
          operator: 'between',
          value: [this.filters.weightFrom, this.filters.weightTo],
        });
      }
      if (this.filters.dsrcTypes.length) {
        filter.push({
          field: 'dsrc_device_id',
          operator: 'in_text',
          value: this.filters.dsrcTypes,
        });
      }
      if (this.filters.front_back_plates_diff_not_1) {
        filter.push({
          field: 'front_back_plates_diff',
          operator: 'not_equal',
          value: '1.0',
        });
      }
      if (this.filters.with_trailers) {
        filter.push({
          field: 'back_number_type',
          operator: 'not_equal_or_null',
          value: '2',
        });
      }
      if (this.filters.roadar_prob_not_1) {
        filter.push({
          field: 'roadar_prob',
          operator: 'not_equal',
          value: '1.0',
        });
      }
      if (this.filters.ton12) {
        filter.push({
          field: 'is_12t',
          operator: 'equal',
          value: true,
        });
      }
      if (this.filters.no_camera) {
        filter.push({
          field: 'no_photo',
          operator: 'no_photo',
          value: null,
        });
      }
      if (this.filters.image_request) {
        filter.push({
          field: 'image_request',
          operator: 'equal',
          value: true,
        });
      }
      if (this.filters.rfid) {
        filter.push({
          field: 'rfid_id',
          operator: 'not_equal',
          value: null,
        });
      }
      if (this.filters.SVPDateFrom && this.filters.SVPDateTo) {
        filter.push({
          field: 'sent_time',
          operator: 'between_datetime',
          value: [this.getOffsetTime(this.filters.SVPDateFrom), this.getOffsetTime(this.filters.SVPDateTo)],
        });
      }
      this.manualAnalysisValues.forEach((value) => {
        if (this.filters[value.key]) {
          filter.push({
            field: value.key,
            operator: 'equal',
            value: value.error,
          });
        }
      });
      if (this.filters.viol_pdd) {
        filter.push({
          field: 'viol_pdd',
          operator: 'viol_pdd',
          value: true,
        });
      }
      if (this.filters.viol_speed) {
        filter.push({
          field: 'viol_speed',
          operator: 'viol_speed',
          value: true,
        });
      }
      if (this.filters.viol_speed20) {
        filter.push({
          field: 'viol_speed20',
          operator: 'viol_speed20',
          value: true,
        });
      }
      if (this.filters.viol_speed40) {
        filter.push({
          field: 'viol_speed40',
          operator: 'viol_speed40',
          value: true,
        });
      }
      if (this.filters.viol_speed60) {
        filter.push({
          field: 'viol_speed60',
          operator: 'viol_speed60',
          value: true,
        });
      }
      if (this.filters.viol_speed60) {
        filter.push({
          field: 'viol_speed60',
          operator: 'viol_speed60',
          value: true,
        });
      }
      if (this.filters.viol_collision) {
        filter.push({
          field: 'viol_collision',
          operator: 'viol_collision',
          value: true,
        });
      }
      if (this.filters.viol_dimensions) {
        filter.push({
          field: 'viol_dimensions',
          operator: 'viol_dimensions',
          value: true,
        });
      }
      if (this.filters.viol_weight) {
        filter.push({
          field: 'viol_weight',
          operator: 'viol_weight',
          value: true,
        });
      }
      if (this.filters.axles_operator && this.filters.axles_operator !== '' && this.filters.axles != null) {
        if (this.filters.axles_operator === '==') {
          filter.push({
            field: 'axles_probable_value',
            operator: 'equal',
            value: parseInt(this.filters.axles, 10),
          });
        }
        if (this.filters.axles_operator === '!=') {
          filter.push({
            field: 'axles_probable_value',
            operator: 'not_equal',
            value: parseInt(this.filters.axles, 10),
          });
        }
        if (this.filters.axles_operator === '>=') {
          filter.push({
            field: 'axles_probable_value',
            operator: 'gte',
            value: parseInt(this.filters.axles, 10),
          });
        }
        if (this.filters.axles_operator === '>') {
          filter.push({
            field: 'axles_probable_value',
            operator: 'gt',
            value: parseInt(this.filters.axles, 10),
          });
        }
        if (this.filters.axles_operator === '<') {
          filter.push({
            field: 'axles_probable_value',
            operator: 'lt',
            value: parseInt(this.filters.axles, 10),
          });
        }
        if (this.filters.axles_operator === '<=') {
          filter.push({
            field: 'axles_probable_value',
            operator: 'lte',
            value: parseInt(this.filters.axles, 10),
          });
        }
      }
      // filter.push({
      //   field: 'lane',
      //   operator: 'in_int',
      //   value: [1],
      // });
      return filter;
    },
  },
  created() {
    this.checkLogin();
  },
  methods: {
    ...mapMutations(['openModal']),
    getRusTag(key, value) {
      if (key === 'dateTime') {
        return `${this.$t('interface.from')} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to}`;
      }
      if (key === 'length') {
        return `${this.$t('measurement_filter.length')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'width') {
        return `${this.$t('measurement_filter.width')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'height') {
        return `${this.$t('measurement_filter.height')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'weight') {
        return `${this.$t('measurement_filter.total_weight')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.t')}`;
      }
      if (key === 'SVPDateTime') {
        return `${this.$t('measurement_filter.date_send')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to}`;
      }
      if (key === 'autodorClass') {
        return `${this.$t('panel_cols.class_autodor')}: ${value.join(', ')}`;
      }
      if (key === 'sdkpClass') {
        return `${this.$t('panel_cols.class_sdkp')}: ${value.join(', ')}`;
      }
      if (key === 'lanes') {
        return `${this.$t('panel_cols.lane')}: ${value.join(', ')}`;
      }
      if (key === 'dsrcTypes') {
        return `DSRC: ${value.join(', ')}`;
      }
      if (key === 'manual') {
        return `${this.$t('measurement_filter.manual_analysis')}: ${value.map((item) => this.$t(`measurement_filter.${item}`)).join(', ')}`;
      }
      if (key === 'selectedEctn28classes') {
        return `${this.$t('panel_cols.ectn28')}: ${value.join(', ')}`;
      }
      if (key === 'selectedIndClasses') {
        return `${this.$t('panel_cols.class_ind')}: ${value.join(', ')}`;
      }
      if (key === 'selectedCountries') {
        return `${this.$t('panel_cols.country')}: ${value.map((item) => item.code).join(', ')}`;
      }
      if (key === 'no_category') {
        return `${this.$t('measurement_filter.no_ectn')}`;
      }
      if (key === 'nearby_dsrc') {
        return `${this.$t('measurement_filter.nearby_dsrc')}`;
      }
      if (key === 'notRUS') {
        return `${this.$t('measurement_filter.all_no_rus')}`;
      }
      if (key === 'many_pan') {
        return `${this.$t('measurement_filter.many_pan')}`;
      }
      if (key === 'noGRZ') {
        return `${this.$t('measurement_filter.no_gnz')}`;
      }
      if (key === 'noRearGRZ') {
        return `${this.$t('measurement_filter.no_back_gnz')}`;
      }
      if (key === 'pan') {
        return `PAN: ${value}`;
      }
      if (key === 'gnz') {
        return `${this.$t('measurement_filter.gnz')}: ${value}`;
      }
      if (key === 'measurement_id') {
        return `${this.$t('measurement_filter.trans_id')}: ${value}`;
      }
      if (key === 'front_back_plates_diff_not_1') {
        return `${this.$t('measurement_filter.front_and_rear')}`;
      }
      if (key === 'with_trailers') {
        return `${this.$t('measurement_filter.excluding_railers')}`;
      }
      if (key === 'roadar_prob_not_1') {
        return `${this.$t('measurement_filter.recognition_mismatch')}`;
      }
      if (key === 'ton12') {
        return `${this.$t('measurement_filter.more_than_12')}`;
      }
      if (key === 'rfid') {
        return `${this.$t('measurement_filter.rfid_availability')}`;
      }
      if (key === 'no_camera') {
        return `${this.$t('measurement_filter.no_photo')}`;
      }
      if (key === 'image_request') {
        return `${this.$t('measurement_filter.dozapros')}`;
      }
      if (key === 'viol_pdd') {
        return `${this.$t('measurement_filter.pdd')}`;
      }
      if (key === 'viol_speed') {
        return `${this.$t('measurement_filter.viol_speed')}`;
      }
      if (key === 'viol_speed20') {
        return `${this.$t('measurement_filter.viol_speed20')}`;
      }
      if (key === 'viol_speed40') {
        return `${this.$t('measurement_filter.viol_speed40')}`;
      }
      if (key === 'viol_speed60') {
        return `${this.$t('measurement_filter.viol_speed60')}`;
      }
      if (key === 'viol_speed80') {
        return `${this.$t('measurement_filter.viol_speed80')}`;
      }
      if (key === 'viol_collision') {
        return `${this.$t('measurement_filter.viol_collision')}`;
      }
      if (key === 'viol_dimensions') {
        return `${this.$t('measurement_filter.viol_dimensions')}`;
      }
      if (key === 'viol_weight') {
        return `${this.$t('measurement_filter.viol_weight')}`;
      }
      if (key === 'same_gnz') {
        return `${this.$t('measurement_filter.same_gnz')}`;
      }
      if (key === 'same_gnz_2') {
        return `${this.$t('measurement_filter.same_gnz_2')}`;
      }
      if (key === 'same_pan') {
        return `${this.$t('measurement_filter.same_pan')}`;
      }
      if (key === 'no_vehicle_no_ectn') {
        return `${this.$t('measurement_filter.no_vehicle_no_ectn')}`;
      }
      return `${key} - ${value}`;
    },
    removeTag(key) {
      if (key === 'dateTime') {
        this.filters.dateFrom = null;
        this.filters.dateTo = null;
      } else
      if (key === 'SVPDateTime') {
        this.filters.SVPDateFrom = null;
        this.filters.SVPDateTo = null;
      } else
      if (key === 'length') {
        this.filters.lengthFrom = null;
        this.filters.lengthTo = null;
      } else
      if (key === 'width') {
        this.filters.widthFrom = null;
        this.filters.widthTo = null;
      } else
      if (key === 'height') {
        this.filters.heightFrom = null;
        this.filters.heightTo = null;
      } else if (key === 'speed') {
        this.filters.speedFrom = null;
        this.filters.speedTo = null;
      } else if (key === 'weight') {
        this.filters.weightFrom = null;
        this.filters.weightTo = null;
      } else
      if (key === 'manual') {
        this.filters.is_plate_valid = null;
        this.filters.is_classification_valid = null;
        this.filters.is_dsrc_valid = null;
        this.filters.is_gp_valid = null;
        this.filters.has_many_errors = null;
      } else
      if (Array.isArray(this.filters[key])) {
        this.filters[key] = [];
      } else
      if (typeof this.filters[key] === 'string') {
        this.filters[key] = null;
      } else
      if (typeof this.filters[key] === 'boolean') {
        this.filters[key] = false;
      }
    },
    downloadReport(withPhoto) {
      const data = {
        filters: this.filtersForRequest,
        params: {
          offset: 0,
          limit: withPhoto ? 3000 : 10000,
          timezone: this.timezoneTitle,
        },
        with_photos: withPhoto,
      };
      this.downloadFile(this.getApiUrl('reports_measurements'), data);
    },
    downloadReportPhotos() {
      this.downloadReport(true);
    },
    downloadArchive() {
      const data = {
        filters: this.filtersForRequest,
        params: {
          offset: 0,
          limit: 3000,
          timezone: this.timezoneTitle,
        },
      };
      this.downloadFile(this.getApiUrl('reports_images'), data, 'application/zip');
    },
    openMeasurement(measurement) {
      if (this.roleIn(['admin', 'user'])) {
        this.currentMeasurement = measurement;
        this.openModal('measurement');
      }
    },
    openImage(measurement, index) {
      // if (this.roleIn(['admin', 'user'])) {
      //   this.currentMeasurement = measurement;
      //   this.galleryImages = measurement.imagesArr;
      //   let galleryIndex = null;
      //   if (measurement.imagesObj[index]) galleryIndex = measurement.imagesArr.indexOf(measurement.imagesObj[index]);
      //   else if (measurement.imagesArr.length) galleryIndex = 0;
      //   this.galleryIndex = galleryIndex;
      // }
      this.currentMeasurement = measurement;
      this.galleryImages = measurement.imagesArr;
      let galleryIndex = null;
      if (measurement.imagesObj[index]) galleryIndex = measurement.imagesArr.indexOf(measurement.imagesObj[index]);
      else if (measurement.imagesArr.length) galleryIndex = 0;
      this.galleryIndex = galleryIndex;
    },
  },
};
