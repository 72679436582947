import constructorEdit from '@/components/mixins/constructorEdit';
import Grid from '@/components/Grid/Grid.vue';

export default {
  name: 'Groups',
  components: {
    Grid,
  },
  mixins: [
    constructorEdit,
  ],
  data() {
    return {
      field: 'web_groups',
      ruleForm: {
        name: '',
        display_name: 'lang',
      },
      emptyRuleForm: {
        name: '',
        display_name: 'lang',
      },
    };
  },
  computed: {
    isValid() {
      return this.ruleForm.name && Object.values(this.ruleForm.display_name).some((val) => val) && !this.records.find((item, i) => item.name === this.ruleForm.name && i !== this.ruleFormRowIndex);
    },
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.ruleForm = this.emptyRuleForm;
      }
    },
  },
  methods: {
    onSubmitForm(ruleForm) {
      if (this.ruleFormRowIndex < 0) return ruleForm;
      const { name } = this.json[this.field][this.ruleFormRowIndex];
      if (name === ruleForm.name) return ruleForm;
      this.json.metrics = this.json.metrics.map((item) => {
        if (!item.web_group || !item.web_group.name || item.web_group.name !== name) return item;
        return {
          ...item,
          web_group: {
            ...item.web_group,
            name: ruleForm.name,
          },
        };
      });

      return ruleForm;
    },
  },
};
