<template>
  <div class="distance-wrapper">
    <div class="arrow">
      <img src="@/assets/img/axles/row.svg" class="row left">
      <img src="@/assets/img/axles/row.svg" class="row right">
    </div>
    <p>{{ distance || '' }} (м)</p>
  </div>
</template>

<script>
export default {
  name: 'DistanseWrapper',
  props: {
    distance: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.distance-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  margin: 0 auto;
  position: relative;
  p {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
  }
  .arrow {
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: relative;
    margin-top: 30px;
    .row {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0;
      width: 6px;
    }
    .left {
      left: 0;
    }
    .right {
      right: 0;
      transform: rotate(180deg) translateY(50%);
    }
  }
}
</style>
