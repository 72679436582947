<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark reasonModal"
    size="lg"
  >
    <div slot="title">{{ $t('navbar.function_mode') }}: <span :class="currentState">{{ currentState ? $t(`interface.${currentState}`) : '' }}</span></div>
    <form v-on:submit.prevent="changeCurrentState" class="state-modal__change text-center">
      <h4>{{ $t('reason_modal.change_mode') }}: {{ $t(`interface.${nextState}`) }}</h4>
      <textarea minlength="4" required v-model="changeReason"
                class="form-control form-control-indigo-600" name="name" rows="3"
                :placeholder="$t('functioning.cause')"></textarea>
      <br>
      <button class="btn btn-lg btn-primary btn-material-indigo-600" type="submit">{{ $t('reason_modal.change_btn') }}</button>
    </form>
    <div slot="footer">
      <button class="btn btn-danger btn-raised" @click="close" type="button">{{ $t('action.close') }}</button>
    </div>
  </modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import mixin from '@/components/mixins/mixin';
import modal from '@/components/mixins/modal';

export default {
  name: 'ReasonModal',
  mixins: [mixin, modal],
  data() {
    return {
      changeReason: '',
    };
  },
  computed: {
    nextState() {
      if (this.currentState === 'SERVICE') {
        return 'NORMAL';
      }
      return 'SERVICE';
    },
    ...mapState([
      'currentState',
    ]),
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.reason,
    }),
  },
  methods: {
    ...mapMutations([
      'setCurrentState',
    ]),
    changeCurrentState() {
      const request = {
        method: 'state_journal.set_current_state',
        token: localStorage.token,
        data: {
          state: this.nextState,
          reason: this.changeReason,
        },
      };
      this.sendRequest(request).then((data) => {
        this.setCurrentState(data.data.state);
        this.changeReason = '';
        this.close();
      }, () => {
        this.changeReason = '';
      });
    },
    close() {
      this.closeModal('reason');
    },
  },
};
</script>
