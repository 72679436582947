<template>
  <div class="wrapper-vehicle-trailer">
    <Truck :axles="axlesVehicle" />
    <div v-if="axlesTrailer.length" class="trailer-wrapper">
      <Trailer :axles="axlesTrailer" />
      <DistanseWrapper :distance="axlesVehicle[axlesVehicle.length - 1].axle_base" class="adaptive-distance" />
    </div>
  </div>
</template>

<script>
import Truck from '@/components/modals/measurement/constructor-axles/types/Truck.vue';
import Trailer from '@/components/modals/measurement/constructor-axles/types/Trailer.vue';
import DistanseWrapper from '@/components/modals/measurement/constructor-axles/common/DistanseWrapper.vue';

export default {
  name: 'TruckWidthTrailer',
  components: { Truck, Trailer, DistanseWrapper },
  props: {
    axlesVehicle: {
      type: Array,
      default: () => [],
    },
    axlesTrailer: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-vehicle-trailer {
  display: flex;
}
.trailer-wrapper {
  position: relative;
}
.adaptive-distance {
  position: absolute;
  left: -69px;
  bottom: 0;
  width: 162px;
  transform: translateY(37px);
  z-index: 10;
}
</style>
