import { mapState, mapGetters, mapMutations } from 'vuex';
import GridColumns from '@/components/modals/GridColumns.vue';
import AxlesConstructor from '@/components/panelGrid/AxlesConstructor.vue';
import pagination from '@/components/pagination/pagination.vue';
import mixin from '@/components/mixins/mixin';
import MeasurementFilter from '@/components/modals/MeasurementFilter.vue';
import ResetFilter from '@/components/modals/ResetFilter.vue';
import reportsControl from '@/components/reportsControl/reportsControl.vue';

export default {
  components: {
    GridColumns,
    pagination,
    AxlesConstructor,
    ResetFilter,
    reportsControl,
    MeasurementFilter,
  },
  mixins: [mixin],
  props: ['filtersForRequest', 'activeFilter', 'filtersParent'],
  data() {
    return {
      measurements: [],
      measurementsSize: 0,
      offset: 0,
      offsetChanged: false,
      measurementsOnPage: 10,
      socketId: -100,
      colSettingsCkad: {
        photo: true,
        state: true,
        date: true,
        country: true,
        country_alt: false,
        validity: false,
        gnz_front: true,
        back_validity: false,
        gnz_back: true,
        gnz_alt: true,
        confidence_grnz: false,
        back_gnz_confidence: false,
        roadar_plate_prob: false,
        front_back_plates_diff: false,
        roadar_prob: false,
        brand: true,
        brand_prob: false,
        ectn28: true,
        confidence_tstype: false,
        class_autodor: true,
        class_autodor_confidence: false,
        class_euro13: true,
        class_sdkp: true,
        dsrc: true,
        size: true,
        speed: true,
        axles_probable_value: true,
        lane: true,
        manual: true,
        is_12t: true,
      },
      colSettingsVgk: {
        date: true,
        country: true,
        gnz_front: true,
        gnz_back: true,
        is_12t: true,
        ectn28: true,
        size: true,
        speed: false,
        scan_speed: true,
        brand: false,
        confidence_grnz: true,
        back_gnz_confidence: true,
        confidence_tstype: true,
        lane: true,
        total_weight: true,
        class_euro13: true,
        rus12_category: true,
        rus12_subcategory: true,
        class_autodor: true,
        axles_params: true,
      },
      colSettingsInd: {
        date: true,
        country: true,
        gnz_front: true,
        is_12t: true,
        gnz_back: true,
        ectn28: true,
        size: true,
        speed: true,
        brand: false,
        confidence_grnz: true,
        back_gnz_confidence: true,
        confidence_tstype: true,
        lane: true,
        total_weight: true,
        class_euro13: true,
        class_ind: true,
        state: false,
        axles_params: true,
      },
    };
  },
  computed: {
    ...mapState(['wsMessage', 'timezone', 'settings']),
    ...mapGetters([
      'timezoneTitle',
    ]),
    colSettings() {
      let result = {};
      if (this.appCode === 'ckad') {
        result = this.colSettingsCkad;
      }
      if (this.appCode === 'vgk') {
        result = this.colSettingsVgk;
      }
      if (this.appCode === 'ind') {
        result = this.colSettingsInd;
      }
      if (!this.settings.ma_visibility) {
        delete result.manual;
      }
      if (!this.settings.brand_model_visibility) {
        delete result.brand;
      }
      if (!this.settings.class_euro13_visibility) {
        delete result.class_euro13;
      }
      if (!this.settings.class_sdkp_visibility) {
        delete result.class_sdkp;
      }
      if (!this.settings.class_sdkp_visibility) {
        delete result.class_sdkp;
      }
      if (!this.settings.is_12t_visibility) {
        delete result.is_12t;
      }
      if (!this.settings.class_autodor_visibility) {
        delete result.class_autodor;
      }
      if (!this.settings.rus12_visibility) {
        delete result.rus12_category;
        delete result.rus12_subcategory;
      }
      return result;
    },
    colSettingsVisible() {
      return Object.keys(this.colSettings).filter((key) => this.colSettings[key]);
    },
    dynamicDataTable() {
      return this.measurements.map((item) => ({
        measurement: item,
        columns: this.colSettingsVisible.map((type) => {
          const flag = () => {
            if (type === 'country') return item.flag;
            if (type === 'country_alt') return item.flag_alt;
            return null;
          };
          return {
            type,
            value: item[this.getNameFieldToIncommingData(type)] || null,
            flag: flag(),
            category: item.category_by_sick,
          };
        }),
      }));
    },
  },
  watch: {
    filtersForRequest() {
      this.measurements = [];
      this.measurementsSize = 0;
      this.$refs.pagination.pageClick(1, true);
    },
    wsMessage(data) {
      if (data.index === this.socketId && data.status === 'ok' && Array.isArray(data.data)) {
        if (!this.offsetChanged) {
          this.measurementsSize += this.getNewLength(data.data, this.measurements, 'id');
          this.measurements = this.getUnique([...this.parseMeasurements(data.data), ...this.measurements], 'id').slice(0, this.measurementsOnPage);
        } else {
          this.offsetChanged = false;
          this.measurements = this.parseMeasurements(data.data);
        }
      }
    },
  },
  mounted() {
    this.subscribeMeasurements(0);
  },
  beforeDestroy() {
    this.unsubscribeMeasurements();
  },
  methods: {
    ...mapMutations(['openModal']),
    getNameFieldToIncommingData(type) {
      switch (type) {
        case 'date': return 'datetime';
        case 'country': return 'gnz_country';
        case 'country_alt': return 'roadar_country';
        case 'gnz_front': return 'gnz';
        case 'gnz_back': return 'back_cam_gnz';
        case 'gnz_alt': return 'roadar_plate';
        case 'ectn28': return 'category_by_sick';
        case 'axles_params': return 'axles';
        default: return type;
      }
    },
    getMeasurementsCount() {
      const request = {
        method: 'measurements_feed.count',
        token: localStorage.token,
        data: {
          filters: this.filtersForRequest,
        },
      };
      this.sendRequest(request).then((data) => {
        if (!Number.isNaN(data.data)) this.measurementsSize = data.data;
      });
    },
    parseMeasurements(data) {
      return data.map((item, index) => {
        const value = { ...item, ...this.measurementAddParameters(item) };
        value.table_id = this.offset + index + 1;
        return value;
      });
    },
    subscribeMeasurements(offset) {
      this.unsubscribeMeasurements();
      this.getMeasurementsCount();
      if (offset !== this.offset) {
        this.offsetChanged = true;
        this.offset = offset;
      }
      const request = {
        method: 'measurements_feed.subscribe',
        token: localStorage.token,
        data: {
          params: {
            limit: this.measurementsOnPage,
            offset: this.offset,
          },
          force: true,
          filters: this.filtersForRequest,
        },
        index: this.socketId,
      };
      this.sendRequest(request, true);
    },
    unsubscribeMeasurements() {
      const request = {
        method: 'measurements_feed.unsubscribe',
        token: localStorage.token,
        data: {},
        index: this.socketId,
      };
      this.sendRequest(request);
    },
    saveColumns(obj) {
      if (this.appCode === 'ind') {
        this.colSettingsInd = { ...obj };
      }
      if (this.appCode === 'ckad') {
        this.colSettingsCkad = { ...obj };
      }
      if (this.appCode === 'vgk') {
        this.colSettingsVgk = { ...obj };
      }
    },
    getRusTag(key, value) {
      if (key === 'dateTime') {
        return `${this.$t('interface.from')} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to}`;
      }
      if (key === 'length') {
        return `${this.$t('measurement_filter.length')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'width') {
        return `${this.$t('measurement_filter.width')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'height') {
        return `${this.$t('measurement_filter.height')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.m')}`;
      }
      if (key === 'speed') {
        return `${this.$t('measurement_filter.speed')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.kmh')}`;
      }
      if (key === 'weight') {
        return `${this.$t('measurement_filter.total_weight')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to} ${this.$t('interface.t')}`;
      }
      if (key === 'SVPDateTime') {
        return `${this.$t('measurement_filter.date_send')} ${this.$t('interface.from').toLowerCase()} ${value.from} ${this.$t('interface.to').toLowerCase()} ${value.to}`;
      }
      if (key === 'autodorClass') {
        return `${this.$t('panel_cols.class_autodor')}: ${value.join(', ')}`;
      }
      if (key === 'lanes') {
        return `${this.$t('panel_cols.lane')}: ${value.join(', ')}`;
      }
      if (key === 'dsrcTypes') {
        return `DSRC: ${value.join(', ')}`;
      }
      if (key === 'manual') {
        return `${this.$t('measurement_filter.manual_analysis')}: ${value.map((item) => this.$t(`measurement_filter.${item}`)).join(', ')}`;
      }
      if (key === 'selectedEctn28classes') {
        return `${this.$t('panel_cols.ectn28')}: ${value.join(', ')}`;
      }
      if (key === 'selectedIndClasses') {
        return `${this.$t('panel_cols.class_ind')}: ${value.join(', ')}`;
      }
      if (key === 'selectedCountries') {
        return `${this.$t('panel_cols.country')}: ${value.map((item) => item.code).join(', ')}`;
      }
      if (key === 'no_category') {
        return `${this.$t('measurement_filter.no_ectn')}`;
      }
      if (key === 'nearby_dsrc') {
        return `${this.$t('measurement_filter.nearby_dsrc')}`;
      }
      if (key === 'notRUS') {
        return `${this.$t('measurement_filter.all_no_rus')}`;
      }
      if (key === 'many_pan') {
        return `${this.$t('measurement_filter.many_pan')}`;
      }
      if (key === 'noGRZ') {
        return `${this.$t('measurement_filter.no_gnz')}`;
      }
      if (key === 'noRearGRZ') {
        return `${this.$t('measurement_filter.no_back_gnz')}`;
      }
      if (key === 'pan') {
        return `PAN: ${value}`;
      }
      if (key === 'gnz') {
        return `${this.$t('measurement_filter.gnz')}: ${value}`;
      }
      if (key === 'measurement_id') {
        return `${this.$t('measurement_filter.trans_id')}: ${value}`;
      }
      if (key === 'front_back_plates_diff_not_1') {
        return `${this.$t('measurement_filter.front_and_rear')}`;
      }
      if (key === 'with_trailers') {
        return `${this.$t('measurement_filter.excluding_railers')}`;
      }
      if (key === 'roadar_prob_not_1') {
        return `${this.$t('measurement_filter.recognition_mismatch')}`;
      }
      if (key === 'ton12') {
        return `${this.$t('measurement_filter.more_than_12')}`;
      }
      if (key === 'rfid') {
        return `${this.$t('measurement_filter.rfid_availability')}`;
      }
      if (key === 'no_camera') {
        return `${this.$t('measurement_filter.no_photo')}`;
      }
      if (key === 'image_request') {
        return `${this.$t('measurement_filter.dozapros')}`;
      }
      if (key === 'viol_pdd') {
        return `${this.$t('measurement_filter.pdd')}`;
      }
      if (key === 'viol_speed') {
        return `${this.$t('measurement_filter.viol_speed')}`;
      }
      if (key === 'viol_speed20') {
        return `${this.$t('measurement_filter.viol_speed20')}`;
      }
      if (key === 'viol_speed40') {
        return `${this.$t('measurement_filter.viol_speed40')}`;
      }
      if (key === 'viol_speed60') {
        return `${this.$t('measurement_filter.viol_speed60')}`;
      }
      if (key === 'viol_speed80') {
        return `${this.$t('measurement_filter.viol_speed80')}`;
      }
      if (key === 'viol_collision') {
        return `${this.$t('measurement_filter.viol_collision')}`;
      }
      if (key === 'viol_dimensions') {
        return `${this.$t('measurement_filter.viol_dimensions')}`;
      }
      if (key === 'viol_weight') {
        return `${this.$t('measurement_filter.viol_weight')}`;
      }
      if (key === 'sdkpClass') {
        return `${this.$t('measurement_filter.class_sdkp')} ${value.join(', ')}`;
      }
      if (key === 'axles') {
        return `${this.$t('measurement_filter.axles')} ${value.operator} ${value.cnt}`;
      }
      if (key === 'same_gnz') {
        return `${this.$t('measurement_filter.same_gnz')}`;
      }
      if (key === 'same_gnz_2') {
        return `${this.$t('measurement_filter.same_gnz_2')}`;
      }
      if (key === 'same_pan') {
        return `${this.$t('measurement_filter.same_pan')}`;
      }
      if (key === 'validity_first_axle') {
        return `${this.$t('measurement_modal.weighter_validity.1')}`;
      }
      if (key === 'validity_half_axle_max') {
        return `${this.$t('measurement_modal.weighter_validity.2')}`;
      }
      if (key === 'validity_axles_speed') {
        return `${this.$t('measurement_modal.weighter_validity.3')}`;
      }
      if (key === 'validity_speed_in_range') {
        return `${this.$t('measurement_modal.weighter_validity.4')}`;
      }
      if (key === 'validity_lane_change') {
        return `${this.$t('measurement_modal.weighter_validity.5')}`;
      }
      if (key === 'no_vehicle_no_ectn') {
        return `${this.$t('measurement_filter.no_vehicle_no_ectn')}`;
      }
      return `${key} - ${value}`;
    },
  },
};
