import constructor from '@/components/mixins/constructor';
import EventBus from '@/eventBus.js';
import axios from 'axios';

export default {
  name: 'Settings',
  mixins: [
    constructor,
  ],
  data() {
    return {
      path: '/json/lang.json',
      langJson: {},
      dontSave: true,
      langs: ['RU', 'EN', 'TR', 'ID'],
      showList: false,
      newLang: null,
    };
  },
  created() {
    const { configFile } = this.$store.state;
    if (!configFile) {
      this.$emit('close');
      return;
    }
    this.json = configFile;
    this.getPath();
  },
  computed: {
    isValid() {
      return this.langs.length;
    },
    newList() {
      return Object.keys(this.langJson).filter((lang) => this.langs.indexOf(lang) < 0);
    },
  },
  methods: {
    removePosition(i, arr) {
      arr.splice(i, 1);
    },
    getPath() {
      axios.get(this.path)
        .then((response) => {
          if (response.status === 200) return response.data;
          return [];
        })
        .then((features) => {
          const obj = {};
          Object.keys(features).forEach((key) => {
            obj[key.toUpperCase()] = features[key].name;
          });
          this.$set(this, 'langJson', obj);
          this.getLangs();
        });
    },
    getLangs() {
      if (!this.json) return;
      const { languages } = this.json;
      if (!languages || !languages.length) return;
      this.langs = languages.filter((item) => this.langJson[item]);
    },
    onSubmit() {
      if (this.newLang != null) {
        this.langs.push(this.newLang);
      }
      const languages = this.langs;
      EventBus.$emit('update-config-language', languages);
      // this.json.languages = languages;
      this.$emit('close');
    },
    onAdd() {
      if (!this.newLang) return;
      this.langs.push(this.newLang);
      this.showList = false;
    },
  },
};
