<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark"
    size="xxl"
  >
    <div slot="title">{{ $t('interface.filter') }}</div>
    <div class="filters">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <div class="row">
              <div class="col-6">
                <label>{{ $t('interface.from') }}</label>
                <div class="input-group">
                  <datetimepicker opt="dateFrom" @change="updateDate" ref="dateFrom" />
                </div>
              </div>
              <div class="col-6">
                <label>{{ $t('interface.to') }}</label>
                <div class="input-group">
                  <datetimepicker opt="dateTo" @change="updateDate" ref="dateTo" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="checkbox">
              <label>
                <input v-model="filters.isChronological" type="checkbox" />
                <span class="checkbox-material">
                  <span class="check"></span>
                </span>
                {{ $t('failure_modal.сhronological') }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label>{{ $t('failure_modal.possible_reasons') }}</label>
            <div class="input-group">
              <span class="input-group-btn">
                <img src="../../assets/img/icons/arrow-down.svg" />
              </span>
              <v-select
                multiple
                v-model="filters.reason"
                :options="alertNames"
                label="name">
                <template slot="option" slot-scope="option">
                  <div>{{ option.name }}</div>
                </template>
                <div slot="no-options">{{ $t('interface.no_results') }}</div>
              </v-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="d-flex justify-content-between">
      <div class="button" @click="resetFilter">
        <span class="button-image"><img src="../../assets/img/icons/reset.svg"/></span>
        <span>{{ $t('action.drop_filters') }}</span>
      </div>
      <div class="d-flex">
        <div class="button" @click="applyFilter">
          <span class="button-image"><img src="../../assets/img/icons/search.svg"/></span>
          <span>{{ $t('action.apply') }}</span>
        </div>
        <div class="button" @click="close">
          <span class="button-image"><img src="../../assets/img/icons/close.svg"/></span>
          <span>{{ $t('action.cancel') }}</span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import mixin from '@/components/mixins/mixin';
import modal from '@/components/mixins/modal';
import datetimepicker from '@/components/vue-datetimepicker/vue-datetimepicker.vue';

export default {
  name: 'FailureFilter',
  components: {
    datetimepicker,
  },
  mixins: [mixin, modal],
  props: ['filtersParent'],
  data() {
    return {
      filters: {},
      dateFilters: ['dateFrom', 'dateTo'],
    };
  },
  computed: {
    ...mapState([
      'alertNames',
    ]),
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.failureFilter,
    }),
  },
  methods: {
    close() {
      this.closeModal('failureFilter');
    },
  },
};
</script>
