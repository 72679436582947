<template>
  <div
      :class="{
        'block-info': true,
        'line-top': !isTextOnLine,
        'line-bottom': isTextOnLine
      }"
  >
    <p>{{ weight }} ({{$t('interface.t')}})</p>
  </div>
</template>

<script>
export default {
  name: 'BlockInfo',
  props: {
    weight: {
      type: String,
      default: '',
    },
    isTextOnLine: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.block-info {
  display: flex;
  flex-direction: column;
  padding: 0 3px;
  position: absolute;
  left: 40px;
  min-width: 60px;
  max-width: 70px;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    height: 1.4px;
    background-color: #fff;
    width: 45px;
  }
  p {
    font-size: 12px;
    margin: 0;
    text-align: center;
  }
}
.line-top {
  border-top: 1px solid #fff;
  bottom: -40px;
  transform: translateY(100%);
  &:after {
    top: 0;
    transform: rotate(-110deg);
    transform-origin: left top;
  }
}
.line-bottom {
  border-bottom: 1px solid #fff;
  top: -40px;
  transform: translateY(-100%);
  &:after {
    bottom: 0;
    transform: rotate(110deg);
    transform-origin: left bottom;
  }
}
</style>
