<template>
  <div class="modal-measurement-content">
    <div class="row mb">
      <ViewItem
        class="col-xs-6"
        :label="$t('measurement_filter.trans_id')"
        :value="measurement.measurement_id"
      />
      <ViewItem
        class="col-xs-6"
        :label="$t('interface.date')"
        :value="measurement.datetime | momentFilter(timezone)"
      />
    </div>
    <div class="block-title">{{ $t('measurement_modal.placement') }}</div>
    <hr>
    <div class="row mb">
      <ViewItem
        class="col-xs-4"
        :label="appCode === 'ckad' ? $t('measurement_modal.complex_id') : $t('measurement_modal.post_id')"
        :value="settings.complex_name"
      />
      <ViewItem
        class="col-xs-4"
        :label="$t('measurement_modal.direction')"
        :value="measurement.moving_direction"
      />
      <ViewItem
        class="col-xs-4"
        :label="$t('measurement_modal.diraction_name')"
        :value="measurement.direction_name"
      />
    </div>
    <div class="row">
      <ViewItem
        class="col-xs-4"
        :label="$t('measurement_modal.lane_number')"
        :value="measurement.lane"
      />
      <ViewItem
        v-if="appCode === 'ckad'"
        class="col-xs-4"
        :label="$t('measurement_modal.latitude')"
        :value="measurement.lat ? (+measurement.lat).toFixed(6) : null"
      />
      <ViewItem
        v-if="appCode === 'ckad'"
        class="col-xs-4"
        :label="$t('measurement_modal.longitude')"
        :value="measurement.lon ? (+measurement.lon).toFixed(6) : null"
      />
    </div>
    <div class="block-title">{{ $t('measurement_modal.dop_info') }}</div>
    <hr>
    <div class="row mb">
      <ViewItem
        class="col-xs-4"
        :label="$t('panel_cols.state')"
        :value="measurement.lane"
      >
        <i :class="sentClass(measurement)"></i>
      </ViewItem>
      <ViewItem
        v-if="appCode === 'ckad'"
        class="col-xs-4"
        :label="$t('measurement_modal.svp_time')"
        :value="measurement.sent_time | momentFilter(timezone)"
      />
      <div v-if="roleIn(['admin', ])" class="col-xs-4" style="color: red">{{ $t(`measurement_modal.skip_reason.${measurement.skip_reason}`) }}</div>
    </div>
    <div v-if="appCode === 'ckad' && settings.ma_visibility" class="row mb">
      <ViewItem
        class="col-xs-4"
        :label="$t('measurement_filter.manual_analysis')"
        :value="manualAnalysis(measurement)"
      />
      <div class="col-xs-8"></div>
      <div class="col-xs-4" v-if="measurement.is_plate_valid === false">
        {{ $t('measurement_filter.is_plate_valid') }}
      </div>
      <div class="col-xs-4" v-if="measurement.is_classification_valid === false">
        {{ $t('measurement_filter.is_classification_valid') }}
      </div>
      <div class="col-xs-4" v-if="measurement.is_gp_valid === false">
        {{ $t('measurement_filter.is_gp_valid') }}
      </div>
      <div class="col-xs-4" v-if="measurement.is_dsrc_valid === false">
        {{ $t('measurement_filter.is_dsrc_valid') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixin from '@/components/mixins/mixin';

export default {
  name: 'CommonInfoTab',
  mixins: [mixin],
  props: {
    measurement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState([
      'settings',
      'timezone',
    ]),
  },
};
</script>
