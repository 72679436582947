<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark errorModal"
    size="lg"
    :dismiss-btn="false"
  >
    <div slot="title">{{ $t('error_modal.error') }}</div>
    <div class="error-msg">{{ errors }}</div>
    <div slot="footer">
      <button class="button" @click="close">{{ $t('action.close') }}</button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import modal from '@/components/mixins/modal';

export default {
  name: 'ErrorModal',
  mixins: [modal],
  props: ['errors'],
  computed: {
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.error,
    }),
  },
  methods: {
    close() {
      this.closeModal('error');
    },
  },
};
</script>
