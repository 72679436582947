<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark"
    size="lg"
    :dismiss-btn="false"
  >
    <div slot="title">{{ $t('confirm_modal.verification') }}</div>
    <div>{{ $t('confirm_modal.want_exit') }}</div>
    <div class="d-flex justify-content-between" slot="footer">
      <button class="button" @click="logout">{{ $t('action.yes') }}</button>
      <button class="button" @click="close">{{ $t('action.no') }}</button>
    </div>
  </modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import mixin from '@/components/mixins/mixin';
import modal from '@/components/mixins/modal';

export default {
  name: 'ConfirmModal',
  mixins: [mixin, modal],
  computed: {
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.confirm,
    }),
  },
  methods: {
    ...mapMutations(['setLoginned']),
    logout() {
      const request = {
        method: 'measurements_feed.unsubscribe',
        token: localStorage.token,
        data: {},
        index: -100,
      };
      this.sendRequest(request);

      localStorage.token = '';
      this.setLoginned(false);
      this.$router.push('/login');
      this.close();
    },
    close() {
      this.closeModal('confirm');
    },
  },
};
</script>
