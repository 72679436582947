/* eslint-disable no-param-reassign */
import constructorEdit from '@/components/mixins/constructorEdit';
import Grid from '@/components/Grid/Grid.vue';
import FormulaData from './FormulaData/FormulaData.vue';

export default {
  name: 'Metrics',
  components: {
    Grid,
    FormulaData,
  },
  mixins: [
    constructorEdit,
  ],
  data() {
    return {
      field: 'metrics',
      rawIndex: -1,
      denyIndex: -1,
      ruleForm: {
        name: '',
        type: 'int',
        expression_type: 'int',
        unit: 'lang',
        display_name: 'lang',
        description: 'lang',
        web_group: {
          name: '',
          order: '',
        },
        request_type: {
          mode: 'check_interval',
          interval: '',
        },
        is_hidden: false,
        source: '',
        alarm: false,
        source_params: {},

        expression: null,
        expression_ui_data: '',
        deny: null,
        deny_ui_data: '',
        value_config: {
          normal: [],
          warning: [],
          error: [],
        },
        trigger: '',
        deny_notify: [],
        status_description: {},
        oid: '',
        api_name: '',
        is_active: '',
      },
      statusDescriptionValues: {
        normal: [['', { ru: '', en: '' }]],
        warning: [['', { ru: '', en: '' }]],
        error: [['', { ru: '', en: '' }]],
        unknown: [{ operator: '', value: '' }],
      },
      valueConfig: {
        normal: [{ operator: '', value: '' }],
        warning: [{ operator: '', value: '' }],
        error: [{ operator: '', value: '' }],
        unknown: [{ operator: '', value: '' }],
      },
      clearValueConfig: {
        normal: [{ operator: '', value: '' }],
        warning: [{ operator: '', value: '' }],
        error: [{ operator: '', value: '' }],
        unknown: [{ operator: '', value: '' }],
      },
      sourceParams: {
        snmp: {
          oid: '',
          filter: ['', 'kw', 'encode', 'int_map', 'int_substr', 'float_map', 'float_substr'],
          filter_param: '',
          filter_map: {},
          filter_map_raw: [['', '']],
          filter_default: '',
          filter_encode: 'iso-8859-1',
        },
        ntp: {
          OffsetFile: '',
          IsStatus: '',
          param: ['offset', 'root_dispertion', 'root_delay'],
        },
        systemstate: {
          path: '',
        },
        zabbix: {
          metric: '',
        },
        uragansshcommand: {
          type: '',
          command: '',
        },
        advantech: {
          oid: '',
        },
        rest: {
          url: '',
          type: '',
          json_key: '',
        },
        frameversion: {},
        calculation: {},
        frameboot: {
          path: '',
        },
        shtil: {
          addr: '',
          code: '',
          payload: '',
          bits: '',
          from: '',
        },
        local: {
          group: '',
          type: '',
          path: '',
        },
        text: {
          path: '',
          parser: ['percent', 'float', 'state'],
        },
        mercury236: {
          path: '',
          is_time: false,
          is_diff_time: false,
        },
        mercury236log: {
          path: '',
          is_time: false,
          is_diff_time: false,
        },
        db: {
          metric: '',
          query: ['day_average'],
        },
      },
      sourceGroupList: {
        cpu: ['logical', 'physical', 'percent'],
        disk: ['total', 'free', 'used', 'usedPercent', 'freePercent'],
        host: ['uptime', 'name', 'procs'],
        memory: ['total', 'available', 'used', 'usedPercent', 'availablePercent'],
        cpu_linux: ['systemPercent', 'userPercent'],
      },
      operatorList: [
        { name: '=', key: 'eq' },
        { name: '!=', key: 'neq' },
        { name: '<', key: 'lt' },
        { name: '>', key: 'gt' },
        { name: '<=', key: 'lte' },
        { name: '>=', key: 'gte' },
        { name: 'in_range', key: 'in_range' },
        { name: 'not_in_range', key: 'not_in_range' },
        { name: 'in_erange', key: 'in_erange' },
        { name: 'not_in_erange', key: 'not_in_erange' },
      ],
      booleanOptions: [
        { code: true, description: 'TRUE' },
        { code: false, description: 'FALSE' },
      ],
      expressionValid: false,
      denyValid: false,
      expressionSelectThisMetrics: false,
      denySelectThisMetrics: false,
      expressionFormulas: [],
      denyFormulas: [],
      oidValid: false,
    };
  },
  created() {
    Object.values(this.sourceParams).forEach((params) => {
      Object.keys(params).forEach((key) => {
        if (Array.isArray(params[key]) || (!Array.isArray(params[key]) && typeof params[key] === 'object')) {
          this.ruleForm.source_params[key] = params[key];
        } else {
          this.ruleForm.source_params[key] = '';
        }
      });
    });
    Object.keys(this.valueConfig).forEach((key) => {
      this.ruleForm.status_description[key] = { ...this.displayName };
      this.statusDescriptionValues[key] = [['', this.makeStatusDescriptionLang()]];
    });
    this.$set(this.ruleForm, 'source_params', { ...this.ruleForm.source_params });
  },
  mounted() {
    this.listNormalize();
  },
  watch: {
    'ruleForm.type': {
      handler() {
        this.valueConfig.normal = [{ operator: '', value: '' }];
        this.valueConfig.warning = [{ operator: '', value: '' }];
        this.valueConfig.error = [{ operator: '', value: '' }];
      },
    },
    showModal(val) {
      if (val) {
        setTimeout(() => {
          if (!this.ruleForm.deny_notify || !this.ruleForm.deny_notify.length) {
            this.$set(this.ruleForm, 'deny_notify', [{
              name: '',
              display_name: { ...this.displayName },
            }]);
          }
        }, 100);
      } else {
        this.rowIndex = -1;
        this.denyIndex = -1;
        this.expressionValid = false;
        this.denyValid = false;
        this.expressionSelectThisMetrics = false;
        this.denySelectThisMetrics = false;
        this.expressionFormulas = [];
        this.denyFormulas = [];
      }
    },
    'ruleForm.source': {
      handler(val, prevVal) {
        if (val && prevVal) this.$set(this.ruleForm, 'source_params', { ...this.clearForm.source_params });
      },
    },
  },
  computed: {
    records() {
      return this.baseRecords.filter(({ name }) => name && name.indexOf('_raw') < 0 && name.indexOf('_deny') < 0).map((item) => ({
        ...item,
        notGroup: item.web_group && item.web_group.name && !this.groupList.find(({ code }) => code === item.web_group.name),
        notType: item.type && !this.typeList.includes(item.type),
      }));
    },
    recordsSources() {
      const obj = {};
      this.records.forEach((item) => {
        let source = item.source || '';
        const raw = this.baseRecords.find(({ name }) => name === `${item.name}_raw`);
        if (raw && raw.source) source = raw.source;
        obj[item.name] = {
          source,
          notSource: source && !this.sourceList[source],
        };
      });
      return obj;
    },
    nameIsValid() {
      return this.ruleForm.name && !this.baseRecords.find((item, i) => item.name === this.ruleForm.name && i !== this.ruleFormRowIndex);
    },
    isValid() {
      if (!this.expressionValid && this.expressionFormulas.length) return false;
      if (!this.denyValid && this.denyFormulas.length) return false;
      if (!this.oidValid) return false;
      if (!this.expressionFormulas.length && this.sourceType === 'Calculation') return false;
      return this.nameIsValid && this.ruleForm.type && this.ruleForm.web_group.name
        && this.ruleForm.web_group.order && this.ruleForm.request_type.mode
        && this.ruleForm.request_type.interval && this.ruleForm.source && this.ruleForm.trigger;
    },
    sourceList() {
      if (!this.json.sources) return {};
      const result = {};
      this.json.sources.forEach((item) => { result[item.Name] = item.Protocol; });
      return result;
    },
    showSourceFields() {
      let { source } = this.ruleForm;
      if (!source || !this.sourceList[source]) return {};
      source = this.sourceList[source].toLowerCase();
      if (!this.sourceParams[source]) return {};
      const result = {};
      Object.keys(this.sourceParams[source]).forEach((key) => { result[key] = true; });
      if (source === 'local' && (!this.ruleForm.source_params.group || this.ruleForm.source_params.group.toLowerCase() !== 'disk')) result.path = false;
      if (source === 'snmp') {
        switch (this.ruleForm.source_params.filter) {
          case 'kw':
            result.filter_map = false;
            result.filter_param = false;
            result.filter_default = false;
            result.filter_encode = false;
            break;
          case 'encode':
            result.filter_map = false;
            result.filter_param = false;
            result.filter_default = false;
            break;
          case 'int_map':
            result.filter_param = false;
            result.filter_default = true;
            result.filter_encode = false;
            break;
          case 'int_substr':
            result.filter_map = false;
            result.filter_default = false;
            result.filter_encode = false;
            break;
          case 'float_map':
            result.filter_param = false;
            result.filter_default = true;
            result.filter_encode = false;
            break;
          case 'float_substr':
            result.filter_map = false;
            result.filter_default = false;
            result.filter_encode = false;
            break;
          default:
            result.filter_param = false;
            result.filter_default = false;
            result.filter_map = false;
            result.filter_encode = false;
            break;
        }
        result.filter_map_raw = false;
      }
      return result;
    },
    groupList() {
      return this.json.web_groups ? this.json.web_groups.map((item) => ({ code: item.name, title: item.display_name[this.$i18n.locale] || item.name })) : [];
    },
    triggerList() {
      return this.json.triggers ? this.json.triggers.map((item) => ({ code: item.name, title: item.display_name[this.$i18n.locale] || item.name })) : [];
    },
    sourceTypeList() {
      if (this.sourceType === 'rest') return ['status', 'body', 'json'];
      if (!this.showSourceFields.group) return ['version', 'metrology', 'time'];
      if (!this.ruleForm.source_params.group) return [];
      return this.sourceGroupList[this.ruleForm.source_params.group] || [];
    },
    metricsList() {
      const allMetrics = this.baseRecords.map(({ name }) => name);
      const records = JSON.parse(JSON.stringify(this.records));
      if (this.ruleFormRowIndex + 1) {
        const i = records.findIndex(({ index }) => index === this.ruleFormRowIndex);
        records.splice(i, 1);
      }

      records.forEach((item) => {
        if (allMetrics.indexOf(`${item.name}_raw`) + 1) records.push({ ...item, name: `${item.name}_raw` });
      });

      records.sort((a, b) => (a.name > b.name ? 1 : -1));

      return records.filter(({ name }) => name);
    },
    metricsListExpression() {
      if (this.nameIsValid && this.sourceType === 'Calculation') return [{ ...this.ruleForm, name: this.ruleForm.name, thisMetrics: true }, ...this.metricsList];
      if (this.nameIsValid) return [{ ...this.ruleForm, name: `${this.ruleForm.name}_raw`, thisMetrics: true }, ...this.metricsList];
      return this.metricsList;
    },
    metricsListDeny() {
      if (!this.nameIsValid) return this.metricsList;
      const list = this.expressionFormulas.length ? this.metricsListExpression : this.metricsList;
      return [{ ...this.ruleForm, thisMetrics: true }, ...list];
    },
    valueConfigCanAdd() {
      const result = {};
      Object.keys(this.valueConfig).forEach((key) => {
        result[key] = !this.valueConfig[key].find((item) => !item.operator || !(item.value || item.value === false));
      });
      return result;
    },
    denyNotifCanAdd() {
      return this.ruleForm.deny_notify && !this.ruleForm.deny_notify.find(({ name }) => !name);
    },
    denyList() {
      let result = this.ruleForm.deny_notify ? this.ruleForm.deny_notify.filter(({ name }) => name).map(({ name }) => name) : [];
      result = new Set(result);
      return [...result];
    },
    sourceType() {
      if (this.ruleForm.source in this.sourceList) {
        return this.sourceList[this.ruleForm.source].toLowerCase();
      }
      return '';
    },
  },
  methods: {
    removePosition(i, arr) {
      arr.splice(i, 1);
    },
    listNormalize() {
      if (!this.json[this.field]) return;
      const list = [];
      this.json[this.field].forEach((item) => {
        list.push(item);
        // if (!item.name || item.name.indexOf('_') < 0) return;
        // const parent = item.name.replace(/_.*/gi, '');
        // if (list.find((e) => e.name === parent)) return;
        // if (this.json[this.field].find((e) => e.name === parent)) return;
        // list.push({ name: parent });
      });
      this.json[this.field] = list.sort((a, b) => {
        if (!a.web_group) return 1;
        if (!b.web_group) return -1;
        return a.web_group.name > b.web_group.name;
      });
    },
    onExpressionValid(val) {
      this.expressionValid = val;
    },
    onDenyValid(val) {
      this.denyValid = val;
    },
    onExpressionSelectThisMetrics(val) {
      this.expressionSelectThisMetrics = val;
    },
    onDenySelectThisMetrics(val) {
      this.denySelectThisMetrics = val;
    },
    onExpressionFormulas(val) {
      this.expressionFormulas = val;
    },
    onDenyFormulas(val) {
      this.denyFormulas = val;
    },
    addState(state) {
      this.valueConfig[state].push({ operator: '', value: '' });
    },
    modifyState(state, i) {
      const item = this.valueConfig[state][i];
      if (['in_range', 'not_in_range', 'in_erange', 'not_in_erange'].includes(item.operator)) {
        if (!Array.isArray(this.valueConfig[state][i].value)) {
          this.valueConfig[state][i].value = ['', ''];
        }
      } else if (Array.isArray(this.valueConfig[state][i].value)) {
        this.valueConfig[state][i].value = '';
      }
    },
    addDenyNotify() {
      this.ruleForm.deny_notify.push({
        name: '',
        display_name: { ...this.displayName },
      });
    },
    onEditRow(form) {
      let ruleForm = { ...form };
      const apiName = ruleForm.api_name;
      const valueConfig = {};
      this.valueConfig = this.clearValueConfig;
      this.baseRecords.forEach((item) => {
        if (item.name === `${ruleForm.name}_raw`) {
          ruleForm = {
            ...ruleForm, ...item, name: ruleForm.name, is_hidden: ruleForm.is_hidden,
          };
          this.rawIndex = item.index;
        }
        if (item.name === `${ruleForm.name}_deny`) {
          ruleForm = {
            ...item, deny: item.expression, ...ruleForm, is_hidden: ruleForm.is_hidden,
          };
          this.denyIndex = item.index;
        }
      });
      if (ruleForm.value_config && typeof ruleForm.value_config === 'object') {
        Object.keys(ruleForm.value_config).forEach((type) => {
          if (!this.valueConfig[type] || !Array.isArray(ruleForm.value_config[type])) return;
          const result = [];
          ruleForm.value_config[type].forEach((config) => {
            Object.keys(config).filter((item) => this.operatorList.find(({ key }) => item === key)).forEach((operator) => {
              result.push({
                operator,
                value: config[operator],
              });
            });
          });

          if (result.length) valueConfig[type] = [...result];
        });
      }
      const sourceType = this.sourceList[ruleForm.source];
      if (!('source_params' in ruleForm)) {
        ruleForm.source_params = {};
      }
      Object.keys(ruleForm.source_params).forEach((paramKey) => {
        if (sourceType in this.sourceParams) {
          const paramConf = this.sourceParams[sourceType][paramKey];
          if (typeof paramConf === 'object' && !Array.isArray(paramConf)) {
            if (`${paramKey}_raw` in this.sourceParams[sourceType]) {
              ruleForm.source_params[`${paramKey}_raw`] = [];
              Object.keys(ruleForm.source_params[paramKey]).forEach((k) => {
                ruleForm.source_params[`${paramKey}_raw`].push([k, ruleForm.source_params[paramKey][k]]);
              });
              ruleForm.source_params[`${paramKey}_raw`].push(['', '']);
            }
          }
        }
      });
      const statusDescriptionValues = {};
      const formStatusDescription = {};
      Object.keys(this.valueConfig).forEach((state) => {
        if (ruleForm.status_description[state] === undefined) {
          ruleForm.status_description[state] = {
            default: {},
          };
        }
        if ('default' in ruleForm.status_description[state]) {
          formStatusDescription[state] = ruleForm.status_description[state].default;
        }
        statusDescriptionValues[state] = [];
        Object.keys(ruleForm.status_description[state]).forEach((value) => {
          if (value !== 'default') {
            statusDescriptionValues[state].push([value, ruleForm.status_description[state][value]]);
          }
        });
        statusDescriptionValues[state].push(['', this.makeStatusDescriptionLang()]);
      });
      ruleForm.status_description = formStatusDescription;
      ruleForm.api_name = apiName;
      this.statusDescriptionValues = statusDescriptionValues;
      setTimeout(() => {
        this.$set(this, 'valueConfig', { ...this.valueConfig, ...valueConfig });
      }, 100);

      if (ruleForm.deny_notify && !Array.isArray(ruleForm.deny_notify)) ruleForm.deny_notify = [];
      return JSON.parse(JSON.stringify(ruleForm));
    },
    onSubmitForm(ruleForm) {
      const baseMetricsKeys = ['description', 'display_name', 'status_description', 'deny_notify', 'web_group', 'unit'];
      let result = {};
      Object.keys(ruleForm).forEach((item) => {
        const key = item.toLowerCase();
        if (key === 'source_params') {
          const sourceParams = {};
          Object.keys(ruleForm.source_params).forEach((paramKey) => {
            if (this.sourceType in this.sourceParams) {
              const paramConf = this.sourceParams[this.sourceType][paramKey];
              if (typeof paramConf === 'object' && !Array.isArray(paramConf)) {
                if (`${paramKey}_raw` in ruleForm.source_params) {
                  const values = ruleForm.source_params[`${paramKey}_raw`];
                  ruleForm.source_params[paramKey] = {};
                  values.forEach(([k, v]) => {
                    if (k.length > 0) {
                      ruleForm.source_params[paramKey][k] = v;
                    }
                  });
                }
              }
              if (typeof paramConf === 'object' && Array.isArray(paramConf) && (Array.isArray(ruleForm.source_params[paramKey]) || ruleForm.source_params[paramKey] === '')) {
                delete ruleForm.source_params[paramKey];
              }
            }
          });
          Object.keys(ruleForm[item]).forEach((sub) => {
            if (this.showSourceFields[sub]) sourceParams[sub] = ruleForm.source_params[sub];
          });
          result.source_params = sourceParams;
          return true;
        }
        if (['value_config', 'expression', 'expression_ui_data', 'deny', 'deny_ui_data', ...baseMetricsKeys].includes(key)) return true;
        result[key] = ruleForm[key];
        return true;
      });
      const statusDescription = {};
      Object.keys(this.valueConfig).forEach((key) => {
        statusDescription[key] = {};
      });
      Object.keys(this.statusDescriptionValues).forEach((state) => {
        let statusDescriptionValues = this.statusDescriptionValues[state];
        statusDescriptionValues = statusDescriptionValues.filter((el) => el[0].length > 0);
        statusDescriptionValues.forEach(([k, v]) => {
          statusDescription[state][k] = v;
        });
        statusDescription[state].default = ruleForm.status_description[state];
      });
      ruleForm.status_description = statusDescription;
      result.request_type.interval = +result.request_type.interval;
      if (!result.request_type.mode) result.request_type.mode = 'check_interval';

      const valueConfig = {};
      Object.keys(this.valueConfig).forEach((type) => {
        valueConfig[type] = this.valueConfig[type].filter((item) => item.operator && (item.value || item.value === false)).map((item) => {
          const obj = {};
          obj[item.operator] = item.value;
          return obj;
        });
      });

      // RAW
      if (this.expressionFormulas.length && this.sourceType !== 'calculation') {
        this.json[this.field].push({
          ...result,
          name: `${ruleForm.name}_raw`,
          is_hidden: true,
          api_name: '',
        });
        result = {
          name: ruleForm.name,
          type: ruleForm.expression_type,
          expression: ruleForm.expression,
          expression_ui_data: ruleForm.expression_ui_data,
          source: 'Calculation',
          request_type: {
            interval: +result.request_type.interval,
            mode: 'check_interval',
          },
          api_name: ruleForm.api_name,
        };
      }
      if (this.expressionFormulas.length && this.sourceType === 'calculation') {
        result = {
          name: ruleForm.name,
          type: ruleForm.type,
          expression: ruleForm.expression,
          expression_ui_data: ruleForm.expression_ui_data,
          source: 'Calculation',
          request_type: {
            interval: +result.request_type.interval,
            mode: 'check_interval',
          },
          api_name: ruleForm.api_name,
        };
      }

      // ОСНОВНАЯ
      result = {
        ...result,
        value_config: valueConfig,
        api_name: ruleForm.api_name,
        trigger: ruleForm.trigger,
        is_active: ruleForm.is_active,
        is_hidden: ruleForm.is_hidden,
        alarm: ruleForm.alarm,
      };
      baseMetricsKeys.forEach((key) => {
        result[key] = ruleForm[key];
      });

      result.web_group.order = +result.web_group.order;

      // DENY
      if (this.denyFormulas.length) {
        this.json[this.field].push({
          name: `${ruleForm.name}_deny`,
          expression: ruleForm.deny,
          deny_ui_data: ruleForm.deny_ui_data,
          source: 'Calculation',
          type: 'bool',
          is_hidden: true,
          request_type: {
            interval: +result.request_type.interval,
            mode: 'check_interval',
          },
        });
      }

      // ОБНОВЛЕНИЕ NAME
      if (this.ruleFormRowIndex + 1) {
        const { name } = this.json[this.field][this.ruleFormRowIndex];
        const newName = ruleForm.name;
        this.json.metrics.forEach((item) => {
          if (item.expression) {
            item.expression = this.replaceNameJSON(item.expression, name, newName) || item.expression;
            item.expression = this.replaceNameJSON(item.expression, `${name}_raw`, `${newName}_raw`) || item.expression;
          }
          if (item.deny) {
            item.deny = this.replaceNameJSON(item.deny, name, newName) || item.deny;
            item.deny = this.replaceNameJSON(item.deny, `${name}_raw`, `${newName}_raw`) || item.deny;
          }
          if (item.expression_ui_data) {
            item.expression_ui_data = this.replaceNameString(item.expression_ui_data, name, newName) || item.expression_ui_data;
            item.expression_ui_data = this.replaceNameString(item.expression_ui_data, `${name}_raw`, `${newName}_raw`) || item.expression_ui_data;
          }
          if (item.deny_ui_data) {
            item.deny_ui_data = this.replaceNameString(item.deny_ui_data, name, newName) || item.deny_ui_data;
            item.deny_ui_data = this.replaceNameString(item.deny_ui_data, `${name}_raw`, `${newName}_raw`) || item.deny_ui_data;
          }
        });
      }

      // ЧИСТИМ ОСТАТКИ
      const { rawIndex, denyIndex } = this;
      if ((rawIndex + 1 || denyIndex + 1) && this.ruleFormRowIndex + 1) {
        const min = Math.min(rawIndex, denyIndex);
        const max = Math.max(rawIndex, denyIndex);
        setTimeout(() => {
          this.removePosition(max, this.json[this.field]);
          if (min + 1) this.removePosition(min, this.json[this.field]);
          this.json[this.field].sort((a, b) => (a.name > b.name ? 1 : -1));
        }, 300);
      }

      return result;
    },
    replaceNameJSON(json, oldName, newName) {
      let text = JSON.stringify(json);
      if (!text.indexOf(`@${oldName}`)) return '';
      text = text.replace(new RegExp(`"@${oldName}"`, 'g'), `"@${newName}"`);
      text = text.replace(new RegExp(`"@${oldName}@`, 'g'), `"@${newName}@`);
      return JSON.parse(text);
    },
    replaceNameString(str, oldName, newName) {
      if (!str.indexOf(`\\"${oldName}\\"`)) return '';
      let json = JSON.parse(str);
      json = json.map((item) => {
        if (!item.metrics_name || item.metrics_name !== oldName) return item;
        return { ...item, metrics_name: newName };
      });
      return JSON.stringify(json);
    },
    deleteRow(index) {
      const indices = [];
      const baseName = this.baseRecords[index].name;
      this.baseRecords.forEach(({ name }, i) => {
        if ([baseName, `${baseName}_raw`, `${baseName}_deny`].includes(name)) indices.unshift(i);
      });
      indices.forEach((i) => {
        this.json[this.field].splice(i, 1);
      });
    },
    makeSourceParamGroup(key) {
      let paramGroup = this.ruleForm.source_params[`${key}_raw`];
      paramGroup = paramGroup.filter((el) => el[0].length > 0);
      if (paramGroup.length === 0) {
        paramGroup.push(['', '']);
      } else {
        const lastIndex = paramGroup.length - 1;
        if (paramGroup[lastIndex][0].length > 0) {
          paramGroup.push(['', '']);
        }
      }
      this.ruleForm.source_params[`${key}_raw`] = paramGroup;
    },
    makeStatusDescriptionLang() {
      const lang = {};
      this.langNames.forEach((l) => {
        lang[l] = '';
      });
      return lang;
    },
    makeStatusDescriptionValues(state) {
      let statusDescriptionValues = this.statusDescriptionValues[state];
      statusDescriptionValues = statusDescriptionValues.filter((el) => el[0].length > 0);
      if (statusDescriptionValues.length === 0) {
        statusDescriptionValues.push(['', this.makeStatusDescriptionLang()]);
      } else {
        const lastIndex = statusDescriptionValues.length - 1;
        if (statusDescriptionValues[lastIndex][0].length > 0) {
          statusDescriptionValues.push(['', this.makeStatusDescriptionLang()]);
        }
      }
      this.statusDescriptionValues[state] = statusDescriptionValues;
    },
    isUsedDeny(deny) {
      let isUsed = false;
      this.denyFormulas.forEach((i) => {
        if (i.type === 'type_deny' && i.deny_name === deny) {
          isUsed = true;
        }
      });
      return isUsed;
    },
  },
};
