<template>
  <div class="axle" :class="`axle_${typeWheels}`">
    <BlockInfo :isTextOnLine="isTextOnLine" :weight="weight"/>
    <div class="axle-img">
      <div v-if="weightWheels.top" class="axle-img__weight axle-img__weight_top">{{weightWheels.top}} ({{$t('interface.t')}})</div>
      <img v-if="typeWheels === 1" src="@/assets/img/axles/types-axles/axle.svg" class="axle-img__img"/>
      <img v-else-if="typeWheels === 2" src="@/assets/img/axles/types-axles/axle2.svg" class="axle-img__img"/>
      <img v-else src="@/assets/img/axles/types-axles/axle3.svg" class="axle-img__img"/>
      <div v-if="weightWheels.bottom" class="axle-img__weight axle-img__weight_bottom">{{weightWheels.bottom}} ({{$t('interface.t')}})</div>
    </div>

    <div
      v-if="isShowLine"
      class="line"
      :class="currentWidth"
    >
      <div v-if="typeCardan === 'circle'" class="cardan circle" />
      <div v-if="typeCardan === 'rectangle'" class="cardan rectangle" />
      <div v-if="typeCardan === 'rectangle-small'" class="cardan rectangle-small" />
    </div>
    <DistanseWrapper
      v-if="isShowDistance && distance"
      :distance="distance"
      class="adaptive-distance"
    />
  </div>
</template>

<script>
import BlockInfo from '@/components/modals/measurement/constructor-axles/common/BlockInfo.vue';
import DistanseWrapper from '@/components/modals/measurement/constructor-axles/common/DistanseWrapper.vue';

export default {
  name: 'Axle',
  components: { BlockInfo, DistanseWrapper },
  props: {
    typeLine: {
      type: String,
      default: 'small',
    },
    typeCardan: {
      type: String,
      default: null,
    },
    typeWheels: {
      type: Number,
      default: 1,
    },
    isShowLine: {
      type: Boolean,
      default: false,
    },
    weight: {
      type: String,
      default: '',
    },
    isTextOnLine: {
      type: Boolean,
      default: false,
    },
    isShowDistance: {
      type: Boolean,
      default: false,
    },
    distance: {
      type: String,
      default: null,
    },
    wheels: Array,
  },
  computed: {
    currentWidth() {
      return {
        small: 'small-line',
        middle: 'middle-line',
        big: 'big-line',
        special: 'special-line',
      }[this.typeLine];
    },
    weightWheels() {
      const result = { top: '', bottom: '' };
      if (this.wheels) {
        this.wheels.forEach((wheel) => {
          const weight = Math.round(wheel.weight / 10) / 100;
          // eslint-disable-next-line default-case
          switch (wheel.side_name) {
            case 'left': result.bottom = weight; break;
            case 'right': result.top = weight; break;
          }
        });
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.axle {
  display: flex;
  align-items: center;
  position: relative;
}
.axle-img {
  height: 110px;
  margin-right: auto;
  position: relative;
  &__img {
    height: 110px;
  }
  &__weight {
    text-align: center;
    position: absolute;
    left: 50%;
    white-space: nowrap;
    transform: translateX(-50%);
    line-height: 12px;
    color: #3a404d;
    font-size: 10px;
    background-color: #c6c6c6;
    margin: 2px 0;
    &_top {
      top: 0;
    }
    &_bottom {
      bottom: 0;
    }
    .axle_2 & {
      margin: 6px 0;
    }
    .axle_3 & {
      margin: 10px 0;
    }
  }
}
.line {
  display: flex;
  height: 4px;
  background-color: #C8C8C8;
  position: relative;
  margin-left: -20px;
}
.small-line {
  width: 60px;
}
.middle-line {
  width: 110px;
}
.big-line {
  width: 150px;
}
.special-line {
  width: 210px;
}
.cardan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #C8C8C8;
}
.circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.rectangle {
  height: 250%;
  width: 33%;
}
.rectangle-small {
  height: 250%;
  width: 35px;
  left: 80px;
}
.special-margin {
  margin-left: -20px;
}
.adaptive-distance {
  position: absolute;
  width: calc(100% - 20px) !important;
  transform: translateY(50px);
  bottom: 0;
  left: 22px;
}
</style>
