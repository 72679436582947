<template>
  <div class="modal-measurement-content">
    <div class="block-title">{{ $t('measurement_modal.classification') }}</div>
    <hr>
    <!-- ckad classification -->
    <div v-if="appCode === 'ckad'">
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.ectn_class')"
          :value="measurement.category_by_sick"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.ectn_prob')"
          :value="getPercentText(measurement.confidence_tstype)"
        />
        <div class="col-xs-4">
          <img
            v-if="measurement.category_by_sick"
            :src="require(`@/assets/img/trucks/ectn-white/${measurement.category_by_sick}.png`)"
            class="category_img"
          />
        </div>
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.autodor_class')"
          :value="measurement.class_autodor"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.autodor_prob')"
          :value="measurement.class_autodor_confidence"
        />
        <div class="col-xs-4">
          <img class="category_img" v-if="truckImage" :src="truckImage" />
        </div>
      </div>
      <div class="row mb" v-if="settings.class_sdkp_visibility">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.class_sdkp')"
          :value="measurement.class_sdkp"
        />
        <div class="col-xs-4"></div>
        <div class="col-xs-4"></div>
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.axles')"
          :value="measurement.axles_probable_value"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.axles_prob')"
          :value="getPercentText(measurement.axles_confidence)"
        />
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="`${$t('panel_cols.size')}, ${$t('interface.m')}`"
          :value="getCarDimensionsText(measurement)"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.size_prob')"
          :value="
            measurement.length_confidence && measurement.width_confidence && measurement.height_confidence
              ? `${getPercentText(measurement.length_confidence, 100, '-')} / ${getPercentText(measurement.width_confidence, 100, '-')} / ${getPercentText(measurement.height_confidence, 100, '-')}`
              : null
          "
        />
      </div>
    </div>
        <!-- vkg classification -->
    <div v-else>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="appCode === 'ckad' ? $t('measurement_modal.ectn_classification') : $t('measurement_modal.ectn_class')"
          :value="measurement.category_by_sick"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.class_euro13')"
          :value="measurement.class_euro13"
        />
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.rus12_category')"
          :value="measurement.rus12_category"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.rus12_subcategory')"
          :value="measurement.rus12_subcategory"
        />
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.autodor_class')"
          :value="measurement.class_autodor"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.is_12t')"
        >
          <i
            :class="{
              'glyphicon': true,
              'glyphicon-ok success': measurement.is_12t,
              'glyphicon-remove error': !measurement.is_12t
            }"
          />
        </ViewItem>
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.class_prob')"
          :value="measurement.confidence_tstype"
        />
      </div>
    </div>
    <div class="block-title">{{ $t('measurement_modal.identification') }}</div>
    <hr>
    <!-- vgk identification -->
    <div v-if="appCode === 'vgk'">
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.number_type')"
          :value="numberTypePretty"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.brand')"
          :value="getCarBrandModelText(measurement)"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.brand_prob')"
          :value="getPercentText(measurement.brand_prob)"
        />
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.speed_vehicle')"
          :value="measurement.speed"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.country')"
          :value="measurement.speed"
        >
          <span v-if="measurement.gnz_country">{{ fullCountryName(measurement.gnz_country) }}</span>
          <img v-if="measurement.flag" class="flag-img" :src="measurement.flag" />
        </ViewItem>
      </div>
    </div>
    <!-- ckad identification -->
    <div v-else>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.country')"
        >
          <span v-if="measurement.gnz_country">{{ fullCountryName(measurement.gnz_country) }}</span>
          <img v-if="measurement.flag" class="flag-img" :src="measurement.flag" />
        </ViewItem>
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.country_alt')"
        >
          <span v-if="measurement.roadar_country">{{ fullCountryName(measurement.roadar_country) }}</span>
          <img v-if="measurement.flagAlt" class="flag-img" :src="measurement.flagAlt" />
        </ViewItem>
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.number_type')"
          :value="numberTypePretty"
        />
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.brand')"
          :value="getCarBrandModelText(measurement)"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.brand_prob')"
          :value="getPercentText(measurement.brand_prob)"
        />
      </div>
    </div>
    <hr>
    <div class="row relative mb">
      <div class="col-xs-9">
        <div class="row">
          <div :class="{ 'col-xs-4': appCode === 'ckad' || appCode === 'vgk',  'col-xs-6': appCode !== 'ckad' }">
            <label>{{ $t('measurement_modal.gnz_front') }}</label>
            <div class="grnz-card grnz-card-bgl" :class="measurement.confidence_grnz > 70 ? 'grnz-card-green' : 'grnz-card-yellow'">
              <p>
                <b>{{ typeof measurement.gnz === 'string' ? measurement.gnz.toUpperCase() : measurement.gnz }}</b>
              </p>
              <p>{{ getPercentText(measurement.confidence_grnz) }}</p>
              <p v-if="appCode === 'ckad'">{{ getPercentText(measurement.front_back_plates_diff, 100) }}</p>
              <p>{{ validityPretty(measurement.validity) }}</p>
            </div>
          </div>
          <div v-if="settings.match_back_photos" :class="{ 'col-xs-4': appCode === 'ckad'  || appCode === 'vgk', 'col-xs-6': appCode !== 'ckad' }">
            <label>{{ $t('measurement_modal.gnz_back') }}</label>
            <div class="grnz-card grnz-card-bgr" :class="measurement.back_gnz_confidence > 70 ? 'grnz-card-green' : 'grnz-card-yellow'">
              <p>
                <b>{{ typeof measurement.back_cam_gnz === 'string' ? measurement.back_cam_gnz.toUpperCase() : measurement.back_cam_gnz }}</b>
              </p>
              <p>{{ getPercentText(measurement.back_gnz_confidence) }}</p>
              <p v-if="appCode === 'ckad'">{{ getPercentText(measurement.front_back_plates_diff, 100) }}</p>
              <p>{{ validityPretty(measurement.back_validity) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="grnz-card-text" :class="{ 'grnz-card-text-full-width': appCode === 'ckad', 'grnz-card-text-half-width': appCode !== 'ckad' }">
        <div class="grnz-card-title">{{ $t('measurement_filter.gnz') }}</div>
        <div class="grnz-card-title">{{ $t('measurement_modal.rasp_prob') }}</div>
        <div v-if="appCode === 'ckad'" class="grnz-card-title">{{ $t('measurement_modal.sovp_prob') }}</div>
        <div class="grnz-card-title">{{ $t('measurement_modal.rasp_quality') }}</div>
      </div>
    </div>
    <div class="row relative mb" v-if="appCode === 'ckad' && settings.match_mm">
      <div class="col-xs-9">
        <div class="row">
          <div v-if="appCode === 'ckad'" class="col-xs-4">
            <label>{{ $t('measurement_modal.gnz_alt') }}</label>
            <div class="grnz-card grnz-card-bgl" :class="measurement.roadar_plate_prob > 70 ? 'grnz-card-green' : 'grnz-card-yellow'">
              <p>
                <b>{{ typeof measurement.roadar_plate === 'string' ? measurement.roadar_plate.toUpperCase() : measurement.roadar_plate }}</b>
              </p>
              <p>{{ getPercentText(measurement.roadar_plate_prob) }}</p>
              <p>{{ getPercentText(measurement.roadar_prob, 100) }}</p>
              <p>{{ validityPretty(measurement.r_validity) }}</p>
            </div>
          </div>
          <div v-if="appCode === 'ckad'" class="col-xs-4">
            <label>{{ $t('measurement_modal.gnz_back_alt') }}</label>
            <div class="grnz-card grnz-card-bgr" :class="measurement.roadar_back_plate_prob > 70 ? 'grnz-card-green' : 'grnz-card-yellow'">
              <p>
                <b>{{ typeof measurement.roadar_back_plate === 'string' ? measurement.roadar_back_plate.toUpperCase() : measurement.roadar_back_plate }}</b>
              </p>
              <p>{{ getPercentText(measurement.roadar_back_plate_prob) }}</p>
              <p>{{ getPercentText(measurement.roadar_back_prob, 100) }}</p>
              <p>{{ validityPretty(measurement.r_back_validity) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="grnz-card-text" :class="{ 'grnz-card-text-full-width': appCode === 'ckad', 'grnz-card-text-half-width': appCode !== 'ckad' }">
        <div class="grnz-card-title">{{ $t('measurement_filter.gnz') }}</div>
        <div class="grnz-card-title">{{ $t('measurement_modal.rasp_prob') }}</div>
        <div v-if="appCode === 'ckad'" class="grnz-card-title">{{ $t('measurement_modal.sovp_prob') }}</div>
        <div class="grnz-card-title">{{ $t('measurement_modal.rasp_quality') }}</div>
      </div>
    </div>
    <div v-if="measurement.dsrcs && measurement.dsrcs.length && appCode === 'ckad'">
      <div class="block-title">{{ $t('measurement_modal.transponder') }}</div>
      <hr>
      <div class="row">
        <div class="col-xs-4">
          <label>{{ $t('measurement_modal.operator') }}</label>
        </div>
        <div class="col-xs-4">
          <label>PAN</label>
        </div>
        <div class="col-xs-4">
          <label>{{ $t('measurement_modal.manufactor') }}</label>
        </div>
      </div>
      <div class="row mb" v-for="(dsrc, dsrc_key) in measurement.dsrcs" :key="dsrc_key">
        <div class="col-xs-4">
          <div v-if="dscrTypes[dsrc.dsrc_operator]" class="dsrc-img">
            <img :src="require(`@/assets/img/operators/${dscrTypes[dsrc.dsrc_operator]}`)" />
          </div>
        </div>
        <div class="col-xs-4 d-flex align-items-center">
          {{ dsrc.pan }}
        </div>
        <div class="col-xs-4 d-flex align-items-center">
          {{ dsrc.manufacturer_name }}
        </div>
      </div>
    </div>
    <!-- param vehicle -->
    <div v-if="appCode === 'vgk'">
      <div class="block-title">{{ $t('measurement_modal.param_vehicle') }}</div>
      <hr>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.probable_number_of_axles')"
          :value="measurement.axles_probable_value"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.axles_prob')"
          :value="measurement.axles_confidence"
        />
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.center_distance')"
          :value="parseFloat(measurement.lane_center).toFixed(2)"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.left_distance')"
          :value="parseFloat(measurement.road_center).toFixed(2)"
        />
      </div>
      <div class="row mb">
        <ViewItem
          class="col-xs-4"
          :label="$t('panel_cols.total_weight')"
          :value="measurement.total_weight"
        />
        <ViewItem
          class="col-xs-4"
          :label="$t('measurement_modal.overall_dimensions')"
          :value="
            measurement.car_length && measurement.car_width && measurement.car_height
              ? `${measurement.car_length}x${measurement.car_width}x${measurement.car_height}`
              : null
          "
        />
      </div>
    </div>
    <div v-if="appCode === 'vgk'">
      <div class="block-title" >{{ $t('panel_cols.axles_params_vgk') }}</div>
      <hr>
      <ConstructorAxles
        v-if="measurement.axles && measurement.axles.length"
        :category="measurement.category_by_sick"
        :axles="measurement.axles"
      />
      <div class="wim_sensors" v-if="appCode === 'vgk'">
        <div class="axls" v-for="(axleData, axleId) in parseWimSensors(JSON.parse(measurement.wim_axles))" :key="axleId">
          <div class="form-group" style="width: 100%">
            <div class="row">
              <div class="col-6" style="padding: 0; width: 100%">
                <div class="axle-tags" v-if="0 in axleData">
                  <b>Ось {{ parseInt(axleId)+1 }}</b>
                  <div v-for="(rail, railId) in axleData[0]" :key="railId">
                    <b>Rail {{railId}} ({{ (Object.values(rail).reduce((a, b) => a + b.integral, 0) / 1000000000).toFixed(3) }}) {{parseInt(parseRailWeight(JSON.parse(measurement.wim_axles))[axleId][railId])}} {{ $t('measurement_modal.kg') }}</b><br>
                    <div class="tags">
                      <div :class="{'tag-green': sensorId in rail, 'tag-red': !(sensorId in rail)}" v-for="sensorId in Array.from(Array(8).keys())"  :key="sensorId">{{ sensorId in rail ? parseFloat(rail[sensorId].integral / 1000000000).toFixed(3) : ' ' }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6" style="padding: 0">
                <div class="axle-tags" v-if="1 in axleData">
                  <b>Ось {{ parseInt(axleId)+1 }} </b>
                  <div v-for="(rail, railId) in axleData[1]" :key="railId">
                    <b>Rail {{railId}} ({{ (Object.values(rail).reduce((a, b) => a + b.integral, 0) / 1000000000).toFixed(3) }}) {{parseInt(parseRailWeight(JSON.parse(measurement.wim_axles))[axleId][railId])}} {{ $t('measurement_modal.kg') }}</b><br>
                    <div class="tags">
                      <div :class="{'tag-green': sensorId in rail, 'tag-red': !(sensorId in rail)}" v-for="sensorId in Array.from(Array(8).keys()).reverse()"  :key="sensorId">{{ sensorId in rail ? parseFloat(rail[sensorId].integral / 1000000000).toFixed(3) : ' ' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-else>Неизвестно</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import mixin from '@/components/mixins/mixin';
import ConstructorAxles from '@/components/modals/measurement/constructor-axles/ConstructorAxles.vue';

export default {
  name: 'VehicleTab',
  mixins: [mixin],
  props: {
    measurement: {
      type: Object,
      required: true,
    },
  },
  components: { ConstructorAxles },
  computed: {
    truckImage() {
      return this.getTruckImage(this.measurement);
    },
    numberTypePretty() {
      switch (this.measurement.number_type) {
        case '5': return this.$t('measurement_modal.min_defense');
        case '20': return this.$t('measurement_modal.cops');
        case '1b': return this.$t('measurement_modal.transport');
        case '10': return this.$t('measurement_modal.ambassador');
        case '9': return this.$t('measurement_modal.diplomat');
        case '1': return this.$t('measurement_modal.civil');
        default: return this.measurement.number_type;
      }
    },
    scanLanePretty() {
      return this.measurement.scan_lane ? +this.measurement.scan_lane + 1 : null;
    },
  },
  methods: {
    validityPretty(value) {
      if (!value) return this.$t('measurement_modal.out');
      return value === 1 ? this.$t('measurement_modal.low') : this.$t('measurement_modal.high');
    },
    fullCountryName(country) {
      return this.countriesDictionary.includes(country) ? `${this.$t(`countries.${country}`)} (${country})` : country;
    },
    parseWimSensors(wimAxles) {
      const axles = {};
      for (const axle_id in wimAxles.axles) {
        if (!(axle_id in axles)) {
          axles[axle_id] = {}
        }
        for (const shaft_id in wimAxles.axles[axle_id].shafts) {
          const shaft = wimAxles.axles[axle_id].shafts[shaft_id]
          if (!(shaft.side in axles[axle_id])) {
            axles[axle_id][shaft.side] = {}
          }
          for (const sensor of wimAxles.axles[axle_id].shafts[shaft_id].humps) {
            if (!(sensor.rail in axles[axle_id][shaft.side])) {
              axles[axle_id][shaft.side][sensor.rail] = {}
            }
            if (!(sensor.sensor in axles[axle_id][shaft.side][sensor.rail])) {
              axles[axle_id][shaft.side][sensor.rail][sensor.sensor] = sensor
            }
          }
        }
      }
      return axles;
    },

    parseRailWeight(wimAxles) {
      const weight_by_axle = {};
      for (const axle_id in wimAxles.axles) {
        if (!(axle_id in weight_by_axle)) {
          weight_by_axle[axle_id] = {}
        }
        for (const shaft_id in wimAxles.axles[axle_id].shafts) {
          const shaft = wimAxles.axles[axle_id].shafts[shaft_id]
          for (const rail_info_id in shaft.weight_by_rail) {
            var rail_info = shaft.weight_by_rail[rail_info_id]
            weight_by_axle[axle_id][rail_info.rail] = rail_info.weight
          }
        }
      }
      return weight_by_axle;
    },
  },
};
</script>
