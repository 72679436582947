import { mapState, mapMutations } from 'vuex';
import MonitoringFilter from '@/components/modals/MonitoringFilter.vue';
import MonitoringReports from '@/components/modals/MonitoringReports.vue';
import mixin from '@/components/mixins/mixin';
import dataLoadInterval from '@/components/mixins/dataLoadInterval';

export default {
  name: 'Monitoring',
  components: {
    MonitoringFilter,
    MonitoringReports,
  },
  mixins: [
    mixin,
    dataLoadInterval,
  ],
  data() {
    return {
      currentTemperature: null,
      currentHumidity: null,
      monitoringData: [],
      currentSensor: {},
      filters: {
        group: null,
      },
    };
  },
  computed: {
    ...mapState([
      'timezone',
    ]),
  },
  watch: {
    filters(val) {
      if (val.group) {
        this.getMonitoring();
      }
    },
  },
  created() {
    this.checkLogin();
    this.startLoadData(30000, () => {
      this.getHumidity();
      this.getTemperature();
      if (this.filters.group) {
        this.getMonitoring();
      }
    });
  },
  beforeDestroy() {
    this.stopLoadData();
  },
  methods: {
    ...mapMutations([
      'openModal',
    ]),
    getMonitoring() {
      const request = {
        method: 'state_journal.get_system_data_groups',
        token: localStorage.token,
        data: {
          group_name: this.filters.group,
          lang: this.$i18n.locale,
        },
      };
      this.sendRequest(request, true).then((data) => {
        this.monitoringData = data.data;
      });
    },
    getTemperature() {
      const request = {
        method: 'state_journal.get_current_measurement',
        token: localStorage.token,
        data: {
          name: 'iNodeDigitalSensorTempValue',
        },
      };
      this.sendRequest(request).then((data) => {
        this.currentTemperature = parseInt(data.data.data, 10);
      });
    },
    getHumidity() {
      const request = {
        method: 'state_journal.get_current_measurement',
        token: localStorage.token,
        data: {
          name: 'iNodeDigitalSensorHumidityValue',
        },
      };
      this.sendRequest(request).then((data) => {
        this.currentHumidity = parseInt(data.data.data, 10);
      });
    },
    getSensorReport(sensor) {
      this.currentSensor = sensor;
      this.openModal('monitoringReports');
    },
    getColor(state) {
      let color = '';
      if (state === 0 || state === '0') {
        color = 'green';
      } else
      if (state === 1 || state === '1') {
        color = 'yellow';
      } else
      if (state === 2 || state === '2') {
        color = 'red';
      }
      return color;
    },
  },
};
