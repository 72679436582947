<template>
  <div class="wrapper-light-vehicle">
    <Axle
      v-for="(item, key) in axles"
      :key="key"
      :weight="item.axle_weight"
      :isShowLine="key === 0 && axles.length > 1"
      :type-cardan="key === 0 && axles.length > 1 ? 'rectangle' : null"
      :type-wheels="item.skatnost"
      :wheels="item.wheels"
      type-line="special"
      :isTextOnLine="true"
      :distance="item.axle_base"
      :isShowDistance="key !== axles.length - 1"
      :class="{ 'special-margin': key > 0 }"
    />
    <img src="@/assets/img/axles/types-axles/light-vehicle.svg" class="vehicle-img" />
  </div>
</template>

<script>
import Axle from '@/components/modals/measurement/constructor-axles/common/Axle.vue';

export default {
  name: 'LightVehicle',
  components: { Axle },
  props: {
    axles: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-light-vehicle {
  display: flex;
  position: relative;
  width: 303px;
  padding: 13px;
  padding-left: 25px;
}
.vehicle-img {
  position: absolute;
  left: 0;
  top: 0;
  height: 136px;
}
</style>
