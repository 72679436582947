/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import draggable from 'vuedraggable';
import tmiParamsMixin from '@/components/mixins/formula';

export default {
  name: 'formulaData',
  mixins: [tmiParamsMixin],
  components: {
    draggable,
  },
  props: {
    value: Object,
    type: String,
    denyList: Array,
    trigger: String,
    alarm: Boolean,
  },
  data() {
    return {
      formulaLoading: Boolean,
      metricsTypes: {
        base: this.$t('formula.value'),
        status: this.$t('failure.status'),
        count: this.$t('formula.count'),
        delay: this.$t('formula.delay'),
      },
      formulaItems: [
        {
          key: 'PLUS',
          value: '+',
          parse: '+',
          type: 'type_math',
        }, {
          key: 'MINUS',
          value: '-',
          parse: '-',
          type: 'type_math',
        }, {
          key: 'MULTIPLY',
          value: '*',
          parse: '*',
          type: 'type_math',
        }, {
          key: 'DIVIDE',
          value: '/',
          parse: '/',
          type: 'type_math',
        }, {
          key: 'REMAINER',
          value: '%',
          parse: '%',
          type: 'type_math',
        },

        {
          key: 'EQ',
          value: '==',
          parse: '==',
          type: 'type_boolean',
        }, {
          key: 'NEQ',
          value: '!=',
          parse: '!=',
          type: 'type_boolean',
        }, {
          key: 'GT',
          value: '>',
          parse: '>',
          type: 'type_sign',
        }, {
          key: 'LT',
          value: '<',
          parse: '<',
          type: 'type_sign',
        }, {
          key: 'GTE',
          value: '>=',
          parse: '>=',
          type: 'type_sign',
        }, {
          key: 'LTE',
          value: '<=',
          parse: '<=',
          type: 'type_sign',
        }, {
          key: 'AND',
          value: '&&',
          parse: '&&',
          type: 'type_operator',
        }, {
          key: 'OR',
          value: '||',
          parse: '||',
          type: 'type_operator',
        }, {
          key: 'NOT',
          value: 'NOT',
          parse: 'NOT',
          type: 'type_all',
        },

        {
          key: '(',
          value: '(',
          parse: '{',
          type: 'type_bracket',
          hidden: true,
        }, {
          key: ')',
          value: ')',
          parse: '}',
          type: 'type_bracket',
          hidden: true,
        }, {
          key: '()',
          value: '( )',
          type: 'type_custom',
          keys: ['(', ')'],
        },

        {
          key: 'AND_bit',
          value: '&',
          parse: '&',
          type: 'type_bit',
        }, {
          key: 'OR_bit',
          value: '|',
          parse: '|',
          type: 'type_bit',
        }, {
          key: 'NOT_AND_bit',
          value: '^',
          parse: '^',
          type: 'type_bit',
        }, {
          key: 'LEFT_bit',
          value: '<<',
          parse: '<<',
          type: 'type_bit',
        }, {
          key: 'RIGHT_bit',
          value: '>>',
          parse: '>>',
          type: 'type_bit',
        },

        {
          key: 'IF',
          value: 'IF',
          parse: 'IF',
          type: 'type_if',
        }, {
          key: 'if_then',
          value: 'IF _ THEN _',
          type: 'type_custom',
          keys: ['IF', '(', ')', 'THEN', '(', ')'],
          isGrow: true,
        },
        {
          key: 'if_then_else',
          value: 'IF _ THEN _ ELSE _',
          type: 'type_custom',
          keys: ['IF', '(', ')', 'THEN', '(', ')', 'ELSE', '(', ')'],
          isGrow: true,
        },

        {
          key: 'THEN',
          value: 'THEN',
          parse: 'THEN',
          type: 'type_if',
        }, {
          key: 'ELSE',
          value: 'ELSE',
          parse: 'ELSE',
          type: 'type_if',
        },

        {
          key: 'input', // поле ввода
          value: this.$t('formula.value'),
          parse: 'input', // поле вво',
          type: 'type_input',
          isGrow: true,
        }, {
          key: 'param',
          value: this.$t('metrics.title'),
          parse: 'param',
          type: 'type_metrics',
          metrics_type: 'base',
          metrics_name: '',
          isGrow: true,
        },

        {
          key: 'INT',
          value: 'INT',
          parse: 'INT',
          type: 'type_transform',
        }, {
          key: 'FLOAT',
          value: 'FLOAT',
          parse: 'FLOAT',
          type: 'type_transform',
          isGrow: true,
        }, {
          key: 'STRING',
          value: 'STRING',
          parse: 'STRING',
          type: 'type_transform',
          isGrow: true,
        }, {
          key: 'ABS',
          value: 'ABS',
          parse: 'ABS',
          type: 'type_transform',
          isGrow: true,
        },

        {
          key: 'deny',
          value: this.$t('formula.refusal'),
          parse: 'deny',
          deny_name: '',
          type: 'type_deny',
          metrics_type: 'base',
          metrics_name: '',
          isGrow: true,
        },
        {
          key: 'deny_false',
          value: this.$t('formula.refusal_fix'),
          parse: 'deny_false',
          type: 'type_deny_false',
          isGrow: true,
        },
      ],
      formulaValid: false,
      formulaInvalid: false,
      valForm: {
        itemIndex: 0,
        value: '',
        value_type: 'text',
        saveBlock: false,
      },
      showValForm: false,
      paramForm: {
        itemIndex: 0,
        metrics_name: '',
        metrics_type: 'base',
        item_sign_id: 0,
        item_value: '',
        type: 'new',
        saveBlock: false,
        param_name: '',
        filter: '',
      },
      showParamForm: false,
      denyForm: {
        itemIndex: 0,
        deny_name: '',
        saveBlock: false,
        filter: '',
        metrics_type: '',
        metrics_name: [],
        valid: false,
      },
      showDenyForm: false,
    };
  },
  created() {
    this.formulaItems.forEach((item) => {
      item.block_type = this.type;
    });

    // eslint-disable-next-line default-case
    switch (this.type) {
      case 'expression':
        this.formulaItems.forEach((item) => {
          if (['deny', 'deny_false'].includes(item.key)) item.hidden = true;
        });
        break;
      case 'deny':
        this.formulaItems.forEach((item) => {
          if (['INT', 'FLOAT'].includes(item.key)) item.hidden = true;
        });
        break;
    }
  },
  mounted() {
    this.parseFormulaFromString();
  },
  watch: {
    'paramForm.device_code': {
      handler() {
        this.paramForm.filter = '';
      },
    },
    'paramForm.metrics_name': {
      handler() {
        this.paramForm.item_value = null;
      },
    },
    showValForm(val) {
      if (!val) this.onCloseEditForm(this.valForm);
    },
    showParamForm(val) {
      if (!val) this.onCloseEditForm(this.paramForm);
    },
    showDenyForm(val) {
      if (!val) this.onCloseEditForm(this.denyForm);
    },
    usedThisMetrics(val) {
      this.$emit('select-this-metrics', !!val);
    },
    formulaValid(val) {
      this.$emit('formula-valid', val);
    },
    metricsList(val) {
      const thisMetricsList = val.filter((item) => item.thisMetrics).map(({ name }) => name);
      if (!thisMetricsList.length) return;
      const raw = thisMetricsList.find((el) => el.indexOf('_raw') + 1);
      const base = thisMetricsList.find((el) => el.indexOf('_raw') < 0);

      this.formulas.forEach((item) => {
        if (!item.thisMetrics) return;
        if (item.metrics_name.indexOf('_raw') + 1) {
          if (raw) item.metrics_name = raw;
        } else if (base) {
          item.metrics_name = base;
        }
      });
    },
    formulaText() {
      this.formulaValid = false;
      this.formulaInvalid = false;
      this.$emit('formulas', [...this.formulas]);
    },
    denyList(val, prevVal) {
      if (!prevVal || prevVal.length < val.length) return;
      if (prevVal.length > val.length) {
        const searchName = prevVal.find((item) => val.indexOf(item) < 0);
        if (searchName && this.formulas.find((item) => item.deny_name && item.deny_name === searchName)) this.formulas = [];
        return;
      }

      this.formulas.forEach((item) => {
        if (!item.deny_name) return;
        const i = prevVal.indexOf(item.deny_name);
        if (i < 0) return;
        item.deny_name = val[i];
      });
    },
    alarm() {
      this.formulaValid = false;
      this.formulaInvalid = false;
    },
    trigger() {
      this.formulaValid = false;
      this.formulaInvalid = false;
    },
    thisMetricsName() {
      if (this.type === 'deny') {
        this.formulaValid = false;
        this.formulaInvalid = false;
      }
    },
  },
  computed: {
    filterMetricsList() {
      const { filter } = this.paramForm;
      return !filter && this.metricsList.length > 5 ? this.metricsList : this.metricsList.filter((el) => el.name && el.name.toLowerCase().indexOf(filter.toLowerCase()) + 1);
    },
    filterDenyMetricsList() {
      const { filter } = this.denyForm;
      return !filter && this.metricsList.length > 5 ? this.metricsList : this.metricsList.filter((el) => el.name && el.name.toLowerCase().indexOf(filter.toLowerCase()) + 1);
    },
    formulaSignsList() {
      const { type } = this.metricsRules;
      const formulaItems = this.formulaItems.map((el, i) => ({ ...el, index: i }));
      return formulaItems.filter((el) => {
        if (this.arrValueTypeBoolean.includes(type)) return el.type === 'type_boolean';
        return this.arrSigns.includes(el.type);
      });
    },
    formulaItemsFilter() {
      return this.formulaItems.map((el, i) => ({ ...el, i })).filter((el) => !el.hidden);
    },
    metricsRules() {
      let type = 'text';
      let valueList = '';
      if (this.showValForm) {
        type = this.valForm.value_type;
        if (this.valForm.value_list) valueList = this.valForm.value_list;
      } else if (this.showParamForm) {
        const name = this.paramForm.metrics_name;
        if (this.metricsNames[name]) type = this.metricsNames[name].type;
        if (this.paramForm.metrics_type !== 'base') type = 'int';
        if (this.paramForm.metrics_type === 'status') valueList = 'status';
      }
      if (valueList && this.lists[valueList]) {
        const options = Array.isArray(this.lists[valueList])
          ? this.lists[valueList].map((description, code) => ({
            code,
            description,
          }))
          : Object.keys(this.lists[valueList]).map((code) => ({
            code,
            description: this.lists.boolean[code],
          }));
        return {
          type,
          options,
          value_list: valueList,
        };
      }

      switch (type) {
        case 'int': case 'float': return { type };
        case 'bool': return {
          type: 'bool',
          options: Object.keys(this.lists.boolean).map((code) => ({
            code,
            description: this.lists.boolean[code],
          })),
          value_list: valueList,
        };
        default: return { type: 'text' };
      }
    },
    requiredSign() {
      return this.paramForm.item_value || this.paramForm.item_value === false || this.paramForm.item_value === 0;
    },
    disableSubmitButton() {
      return !this.formulaValid;
    },
    usedThisMetrics() {
      return this.formulas.filter((item) => item.thisMetrics).length > 0;
    },
    thisMetricsName() {
      const obj = this.metricsList.find((e) => e.thisMetrics);
      return obj ? obj.name : '';
    },
  },
  methods: {
    removePosition(i, arr) {
      arr.splice(i, 1);
    },
    addItemHelper(el, index, edit = false) {
      const items = [];
      // eslint-disable-next-line default-case
      switch (el.type) {
        case 'type_input':
          this.valForm.itemIndex = index;
          this.valForm.saveBlock = edit;
          this.valForm.with_metrics_name = edit && el.with_metrics_name ? el.with_metrics_name : '';
          this.valForm.value_type = el.value_type || 'text';
          this.valForm.value_list = el.value_list || '';
          this.valForm.value = edit ? el.value : '';
          this.showValForm = true;
          return;
        case 'type_metrics':
          this.paramForm.type = edit ? 'param' : 'new';
          this.paramForm.saveBlock = edit;
          this.paramForm.itemIndex = index;
          this.paramForm.metrics_name = edit ? el.metrics_name : this.metricsList[0].name;
          this.paramForm.metrics_type = edit ? el.metrics_type : Object.keys(this.metricsTypes)[0];
          this.paramForm.item_sign_id = 0;
          this.paramForm.item_value = '';
          this.paramForm.filter = '';
          this.showParamForm = true;
          return;
        case 'type_deny':
          this.denyForm.saveBlock = edit;
          this.denyForm.itemIndex = index;
          this.denyForm.deny_name = edit ? el.deny_name : this.denyList[0];
          this.denyForm.filter = '';
          this.denyForm.metrics_type = edit ? el.metrics_type : [];
          this.denyForm.metrics_name = edit ? el.metrics_name : [];
          this.denyForm.valid = false;
          this.showDenyForm = true;
          return;
        case 'type_custom':
          if (!el.keys) return;
          el.keys.forEach((key) => {
            // eslint-disable-next-line no-shadow
            const item = this.formulaItems.find((item) => item.key === key);
            if (item) items.push({ ...item });
          });
          this.formulas.splice(index, 1, ...items);
      }
    },
    removePositionHelper() {
      this.formulaInvalid = false;
      this.formulaValid = false;
    },
    onCloseEditForm(form) {
      if (form.saveBlock) return;
      this.removePosition(form.itemIndex, this.formulas, false, this.removePositionHelper);
    },
    getApiUrl() {
      return process.env.NODE_ENV === 'development' ? '/parse_expression' : `http://${window.location.hostname}:8901/parse_expression`;
    },
    async checkFormula() {
      const formulas = JSON.parse(JSON.stringify(this.formulas));
      this.formulas = formulas.length ? formulas : [];

      this.formulaValid = false;
      this.formulaLoading = true;
      this.formulaInvalid = false;
      this.formulaObj = [];
      try {
        if (!this.checkLocal(this.formulas)) this.formulaInvalid = true;
        if (this.formulaInvalid) return;
        const text = this.getFormulaText();
        const { data } = await axios.post(this.getApiUrl(), text, { headers: { 'Content-Length': 0, 'Content-Type': 'text/plain' } });

        if (!data) {
          this.formulaInvalid = true;
          return;
        }

        if (typeof data === 'string') {
          if (data.indexOf('@@@') < 0) {
            this.formulaInvalid = true;
            return;
          }
          this.value[this.type] = data.replace(/@@@(.|\n)*/, '');
        } else {
          if (!data.expression || typeof data.expression !== 'object') {
            this.formulaInvalid = true;
            return;
          }
          this.value[this.type] = data.expression;
        }

        this.value[`${this.type}_ui_data`] = JSON.stringify(this.formulas);
        this.formulaValid = true;
      } catch (e) {
        console.error('error checkFormula:', e);
        this.formulaInvalid = true;
      } finally {
        this.formulaLoading = false;
      }
    },
    checkLocal(formula) {
      if (formula.length < 2) return false;
      return true;
    },
    getFormulaText() {
      return this.formulas ? this.formulas.map((item) => {
        if (!['type_metrics', 'type_input', 'type_deny', 'type_deny_false'].includes(item.type)) return item.parse;
        if (item.type === 'type_metrics') return item.metrics_type && item.metrics_type.toLowerCase() !== 'base' && this.metricsTypes[item.metrics_type.toLowerCase()] ? `@${item.metrics_name}@${item.metrics_type.toUpperCase()}` : `@${item.metrics_name}`;
        if (item.type === 'type_input' && item.value_type && item.value_type === 'float') return item.value.indexOf('.') + 1 ? item.value : `{ FLOAT ${item.value} }`;
        if (item.type === 'type_deny') {
          if (item.metrics_name && item.metrics_type) {
            let str = '';
            let vars = '';
            if (Array.isArray(item.metrics_name)) {
              str = `${item.deny_name}`;
              vars = '';
              item.metrics_name.forEach((mn) => {
                str += '    %v';
                if (item.metrics_type.toLowerCase() !== 'base') {
                  vars += `@${mn}@${item.metrics_type.toUpperCase()} `;
                } else {
                  vars += `@${mn} `;
                }
              });
            } else {
              str = `${item.deny_name}    %v`;
              if (item.metrics_type.toLowerCase() !== 'base') {
                vars = `@${item.metrics_name}@${item.metrics_type.toUpperCase()}`;
              } else {
                vars = `@${item.metrics_name}`;
              }
            }
            return `{!${this.trigger}!${this.thisMetricsName}${this.alarm ? '!' : ''} = {STRING "${str}" ${vars}}}`;
          }
          return `{!${this.trigger}!${this.thisMetricsName}${this.alarm ? '!' : ''} = "${item.deny_name}"}`;
        }
        if (item.type === 'type_deny_false') return `{!${this.trigger}!${this.thisMetricsName}!ACTIVE = false}`;
        return item.value;
      }).join(' ') : '';
    },
    addItem(i) {
      this.formulaInvalid = false;
      this.formulaValid = false;
      const el = { ...this.formulaItems[i] };
      this.formulas.push(el);
      this.addItemHelper(el, this.formulas.length - 1);
    },
    editItem(i) {
      const item = this.formulas[i];
      if (Array.isArray(item.metrics_name)) {
        let isOk = true;
        item.metrics_name.forEach((mn) => {
          if (!this.metricsNames[mn]) isOk = false;
        });
        if (!isOk) return;
      } else if (item.metrics_name && !this.metricsNames[item.metrics_name]) {
        return;
      }
      this.addItemHelper(this.formulas[i], i, true);
    },
    onFormulaChange(e) {
      this.formulaInvalid = false;
      this.formulaValid = false;
      if (!e.added) return;
      if (e.added.element.block_type !== this.type) {
        this.removePosition(e.added.newIndex, this.formulas);
        return;
      }
      this.addItemHelper(e.added.element, e.added.newIndex);
    },
    setFormulaItem() {
      const { value, value_list: valueList } = this.valForm;
      this.formulas[this.valForm.itemIndex].value = value;
      this.formulas[this.valForm.itemIndex].value_type = this.metricsRules.type;
      if (valueList) this.formulas[this.valForm.itemIndex].value_list = valueList;
      this.valForm.saveBlock = true;
      this.showValForm = false;
      this.formulaValid = false;
    },
    setFormulaDeny() {
      this.formulas.splice(this.denyForm.itemIndex, 1, {
        ...this.formulas[this.denyForm.itemIndex],
        deny_name: this.denyForm.deny_name,
        metrics_name: this.denyForm.metrics_name,
        metrics_type: this.denyForm.metrics_type,
      });
      this.denyForm.saveBlock = true;
      this.showDenyForm = false;
      this.formulaValid = false;
    },
    setFormulaParam() {
      const form = this.paramForm;
      this.formulas.splice(form.itemIndex, 1, {
        ...this.formulas[form.itemIndex],
        metrics_name: form.metrics_name,
        metrics_type: form.metrics_type,
        value_type: this.metricsRules.type,
        thisMetrics: !!this.metricsList.find((item) => item.name === form.metrics_name && item.thisMetrics),
      });

      if (form.type === 'new') {
        if ((form.item_sign_id || form.item_sign_id === 0) && (form.item_value || form.item_value === 0)) {
          const input = {
            ...this.formulaItems.find((el) => el.key === 'input'),
            value: form.item_value,
            value_type: this.metricsRules.type,
          };
          if (this.metricsRules.value_list) input.value_list = this.metricsRules.value_list;
          this.formulas.splice(form.itemIndex + 1, 0, input);
          this.formulas.splice(form.itemIndex + 1, 0, { ...this.formulaItems[form.item_sign_id] });
          this.lastGroup++;
        }
      }

      this.paramForm.saveBlock = true;
      this.showParamForm = false;
      this.formulaValid = false;
    },
    clearAll() {
      this.formulas = [];
      this.formulaInvalid = false;
      this.formulaValid = false;
    },
    parseFormulaFromString() {
      try {
        let formulas = this.value[`${this.type}_ui_data`] ? JSON.parse(this.value[`${this.type}_ui_data`]) : [];
        if (!formulas) formulas = [];

        this.formulas = JSON.parse(JSON.stringify(formulas));

        this.formulaObj = this.value[this.type];
      } catch (e) {
        console.error('error parseFormulaFromString:', e);
      }
    },
  },
};
