<template>
  <div class="wrapper-vehicle-trailer">
    <LightVehicle :axles="axlesVehicle" />
    <Trailer v-if="axlesTrailer.length" :axles="axlesTrailer" />
    <DistanseWrapper
      v-if="axlesTrailer.length"
      :distance="axlesVehicle[axlesVehicle.length - 1].axle_base"
      class="adaptive-distance"
    />
  </div>
</template>

<script>
import LightVehicle from '@/components/modals/measurement/constructor-axles/types/LightVehicle.vue';
import Trailer from '@/components/modals/measurement/constructor-axles/types/Trailer.vue';
import DistanseWrapper from '@/components/modals/measurement/constructor-axles/common/DistanseWrapper.vue';

export default {
  name: 'LightVehicleWithTrailer',
  components: { LightVehicle, Trailer, DistanseWrapper },
  props: {
    axlesVehicle: {
      type: Array,
      default: () => [],
    },
    axlesTrailer: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-vehicle-trailer {
  display: flex;
  position: relative;
}
.adaptive-distance {
  position: absolute;
  left: 262px;
  bottom: 0;
  width: 134px;
  transform: translateY(37px);
  z-index: 10;
}
</style>
