<template>
  <iframe :src="settings[this.url_id]"></iframe>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Iframe',
  props: ['url_id'],
  computed: {
    ...mapState([
      'settings',
    ]),
  },
};
</script>
