<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark"
    size="lg"
    :dismiss-btn="false"
  >
    <div slot="title">{{ $t('confirm_modal.verification') }}</div>
    <div>{{ $t('reset_modal.clear_filters') }}</div>
    <div class="d-flex justify-content-between" slot="footer">
      <button class="button" @click="submit">{{ $t('action.yes') }}</button>
      <button class="button" @click="close">{{ $t('action.no') }}</button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import modal from '@/components/mixins/modal';

export default {
  name: 'ResetFilter',
  mixins: [modal],
  computed: {
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.resetFilter,
    }),
  },
  methods: {
    submit() {
      this.$emit('clear-filters');
      this.close();
    },
    close() {
      this.closeModal('resetFilter');
    },
  },
};
</script>
