import mixin from '@/components/mixins/mixin';

export default {
  name: 'Settings',
  mixins: [
    mixin,
  ],
  computed: {
    isAdmin() {
      return localStorage.token && this.parseJwt(localStorage.token).user.role === 'admin';
    },
  },
  data() {
    return {
      configErrors: false,
      showSettings: false,
    };
  },
};
