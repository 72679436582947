<template>
  <div>
    <label v-html="label"></label>
    <slot>{{ hHasValue }}</slot>
  </div>
</template>

<script>
export default {
  name: 'ViewItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String, Boolean],
      default: '—',
    },
  },
  computed: {
    hHasValue() {
      return this.value || this.value === false || this.value === 0 ? this.value : '—';
    },
  },
};
</script>
