import { mapMutations } from 'vuex';

export default {
  computed: {
    isModalOpen: {
      get() {
        return this.isModalOpenFromStore;
      },
      set() {
        this.close();
      },
    },
  },
  watch: {
    isModalOpenFromStore(val) {
      if (val) {
        if (this.filters) {
          this.filters = { ...this.filtersParent };
        }
        if (this.dateFilters) {
          this.dateFilters.forEach((item) => {
            if (this.$refs[item]) {
              if (!this.filters[item]) {
                this.$refs[item].clear();
              } else {
                this.$refs[item].setDate(this.filters[item]);
              }
            }
          });
        }
      }
    },
    timezone(newValue, oldValue) {
      if (this.filters && Object.keys(this.filters).length && this.dateFilters) {
        const delta = newValue - oldValue;
        let save = false;
        this.dateFilters.forEach((item) => {
          if (this.filters[item]) {
            this.filters[item].add(delta, 'hours');
            save = true;
          }
        });
        if (save) this.$emit('save-filters', { ...this.filters });
      }
    },
  },
  methods: {
    ...mapMutations([
      'closeModal',
    ]),
    updateDate(event, type) {
      this.filters[type] = event;
    },
    applyFilter() {
      this.$emit('save-filters', { ...this.filters });
      this.close();
    },
    resetFilter() {
      this.clearFilters();
      if (this.dateFilters) {
        this.dateFilters.forEach((item) => {
          if (this.$refs[item]) {
            this.$refs[item].clear();
          }
        });
      }
    },
  },
};
