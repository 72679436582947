<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark"
    size="lg"
  >
    <div slot="title">{{ $t('grid_modal.title') }}</div>
    <div class="filters d-flex">
      <div class="col-grow"></div>
      <div>
        <div class="form-group" v-for="(column, key) in columns" :key="key">
        <div class="checkbox">
          <label>
            <input v-model="columns[key]" type="checkbox" />
            <span class="checkbox-material">
              <span class="check"></span>
            </span>
            {{ $t(`panel_cols.${key}`) }}
          </label>
        </div>
      </div>
      </div>
      <div class="col-grow"></div>
    </div>
    <div class="d-flex justify-content-between" slot="footer">
      <div></div>
      <div class="d-flex">
        <div class="button" @click="applyFilter()">
          <span class="button-image"><img src="../../assets/img/icons/search.svg"/></span>
          <span>{{ $t('action.apply') }}</span>
        </div>
        <div class="button" @click="cancelFilter()">
          <span class="button-image"><img src="../../assets/img/icons/close.svg"/></span>
          <span>{{ $t('action.cancel') }}</span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import modal from '@/components/mixins/modal';

export default {
  name: 'GridColumns',
  mixins: [modal],
  props: ['colSettings'],
  data() {
    return {
      columns: {},
    };
  },
  computed: {
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.gridColumns,
    }),
  },
  watch: {
    isModalOpenFromStore(val) {
      if (val) this.columns = { ...this.colSettings };
    },
  },
  methods: {
    applyFilter() {
      this.$emit('save-columns', this.columns);
      this.close();
    },
    cancelFilter() {
      this.close();
    },
    close() {
      this.closeModal('gridColumns');
    },
  },
};
</script>
