import { mapMutations } from 'vuex';
import ErrorModal from '@/components/modals/ErrorModal.vue';
import mixin from '@/components/mixins/mixin';

export default {
  name: 'Login',
  components: {
    ErrorModal,
  },
  mixins: [mixin],
  data() {
    return {
      login: '',
      password: '',
      errors: '',
    };
  },
  created() {
    if (localStorage.token && localStorage.token !== 'undefined') {
      this.$router.push('/panel');
    }
  },
  methods: {
    ...mapMutations([
      'openModal',
      'setLoginned',
    ]),
    auth() {
      if (!this.login || !this.password) {
        this.errors = 'Не заполнено поле логина или поле пароля';
        this.openModal('error');
      } else {
        const request = {
          method: 'auth.login',
          token: null,
          data: {
            login: this.login,
            password: this.password,
          },
        };
        this.sendRequest(request).then((data) => {
          localStorage.token = data.token;
          this.setLoginned(true);
          this.$router.push('/panel');
        }, (data) => {
          console.error(data.error);
          this.setLoginned(false);
          this.errors = 'Неправильный пароль или такого пользователя не существует';
          this.openModal('error');
        });
      }
    },
  },
};
