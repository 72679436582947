<template>
  <modal
    v-model="isModalOpen"
    auto-focus
    type="dark"
    class="modal-dark"
    size="xxl"
  >
    <div slot="title">{{ $t('interface.filter') }}</div>
    <div class="filters">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label>{{ $t('interface.date') }}</label>
            <div class="row">
              <div class="col-6">
                <div class="input-group">
                  <datetimepicker opt="dateFrom" @change="updateDate" ref="dateFrom" />
                </div>
              </div>
              <div class="col-6">
                <datetimepicker opt="dateTo" @change="updateDate" ref="dateTo" />
              </div>
            </div>
          </div>
          <div v-if="appCode === 'ckad'" class="form-group">
            <label>PAN</label>
            <div class="input-group">
              <span class="input-group-btn">
                <img src="../../assets/img/icons/aspect.svg" />
              </span>
              <input
                class="form-control"
                v-model="filters.pan"
                placeholder="xxxxxxxxxxxxxxxxxxxx"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t('measurement_filter.gnz') }}</label>
            <div class="input-group">
              <span class="input-group-btn">
                <img src="../../assets/img/icons/aspect.svg" />
              </span>
              <input
                class="form-control"
                v-model="filters.gnz"
                placeholder="x999xx99"
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t('panel_cols.country') }}</label>
            <div class="input-group">
              <span class="input-group-btn">
                <img src="../../assets/img/icons/arrow-down.svg" />
              </span>
              <v-select
                multiple
                v-model="filters.selectedCountries"
                :options="countries"
                label="value"
                ref="countries"
              >
                <template slot="option" slot-scope="option">
                  <div v-html="highlightCountry(option.name)"></div>
                  <div v-html="highlightCountry(option.code)"></div>
                </template>
                <div slot="no-options">{{ $t('interface.no_results') }}</div>
              </v-select>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t('panel_cols.ectn28') }}</label>
            <div class="input-group">
              <span class="input-group-btn">
                <img src="../../assets/img/icons/aspect.svg" />
              </span>
              <v-select
                multiple
                v-model="filters.selectedEctn28classes"
                :options="ectn28classes"
                ref="ectn28classes"
              >
                <template slot="option" slot-scope="option">
                  <div v-html="highlightEctn(option.label)"></div>
                </template>
                <div slot="no-options">{{ $t('interface.no_results') }}</div>
              </v-select>
            </div>
          </div>
          <div class="form-group"  v-if="appCode === 'ind'">
            <label>{{ $t('panel_cols.class_ind') }}</label>
            <div class="input-group">
              <span class="input-group-btn">
                <img src="../../assets/img/icons/aspect.svg" />
              </span>
              <v-select
                multiple
                v-model="filters.selectedIndClasses"
                :options="indClasses"
                ref="indClasses"
              >
                <template slot="option" slot-scope="option">
                  <div v-html="highlightInd(option.label)"></div>
                </template>
                <div slot="no-options">{{ $t('interface.no_results') }}</div>
              </v-select>
            </div>
          </div>
        </div>
        <!--    чекбоксы    -->
        <div class="col-6">
          <div class="form-group" v-if="appCode === 'ckad' || appCode === 'vgk'">
            <label>{{ $t('panel_cols.class_autodor') }}</label>
            <div class="input-group flex-wrap">
              <div v-for="(value, key) in autodorClass" :key="key" class="checkbox checkbox-item">
                <input
                  v-model="filters.autodorClass"
                  :value="value"
                  :id="'ch-autodor_class-'+value"
                  type="checkbox"
                />
                <label :for="'ch-autodor_class-'+value">{{ $t('measurement_filter.class') }} {{ value }}</label>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="settings.class_sdkp_visibility">
            <label>{{ $t('panel_cols.class_sdkp') }}</label>
            <div class="input-group flex-wrap">
              <div v-for="(value, key) in sdkpClass" :key="key" class="checkbox checkbox-item">
                <input
                  v-model="filters.sdkpClass"
                  :value="value"
                  :id="'ch-sdkp_class-'+value"
                  type="checkbox"
                />
                <label :for="'ch-sdkp_class-'+value">{{ $t('measurement_filter.class') }} {{ value }}</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{ $t('panel_cols.lane') }}</label>
            <div class="input-group flex-wrap">
              <div v-for="(value, key) in lanes" :key="key" class="checkbox checkbox-item">
                <input
                  v-model="filters.lanes"
                  :value="value"
                  :id="'ch-lanes-'+value"
                  type="checkbox"
                />
                <label :for="'ch-lanes-'+value">{{ $t('measurement_filter.lane') }} {{ value }}</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="roleIn(['admin', 'user'])">
            <div class="col-6">
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.noGRZ" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.no_gnz') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.noRearGRZ" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.no_back_gnz') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="roleIn(['admin', 'user'])">
            <div class="col-6">
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.notRUS" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.all_no_rus') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div v-if="appCode === 'ckad'" class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.many_pan" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.many_pan') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="roleIn(['admin', 'user'])">
            <div class="col-6">
              <div class="form-group">
                <label>&nbsp;</label>
                <div class="checkbox">
                  <label>
                    <input v-model="filters.no_category" type="checkbox" />
                    <span class="checkbox-material">
                  <span class="check"></span>
                </span>
                    {{ $t('measurement_filter.no_ectn') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div v-if="appCode === 'ckad'" class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.nearby_dsrc" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.nearby_dsrc') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse-control">
        <div class="collapse-wrap d-flex align-items-center">
          <h3
            class="collapsestate"
            @click="collapseContent()"
            :class="{ 'collapsestate-active' : collapseState }"
          >
            {{ $t('measurement_filter.extra') }}
            <span>({{ collapseState ? $t('measurement_filter.hide') : $t('measurement_filter.show') }})</span>
            <img src="../../assets/img/icons/collapse.svg" />
          </h3>
          <div class="collapsestate-line"></div>
        </div>
        <div class="collapse-content" v-show="collapseState">
          <div class="row">
            <div class="col-6">
              <h4 class="noborder">&nbsp;</h4>
              <div class="form-group">
                <label>{{ $t('measurement_filter.trans_id') }}</label>
                <input
                  class="form-control"
                  v-model="filters.measurement_id"
                  placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  type="text"
                />
              </div>

              <div class="form-group">
                <label>{{ $t('measurement_filter.length') }}, {{ $t('interface.m') }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.from') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.lengthFrom"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.to') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.lengthTo"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('measurement_filter.width') }}, {{ $t('interface.m') }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.from') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.widthFrom"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.to') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.widthTo"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('measurement_filter.height') }}, {{ $t('interface.m') }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.from') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.heightFrom"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.to') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.heightTo"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="appCode === 'vgk'">
                <label>{{ $t('measurement_filter.total_weight') }}, {{ $t('interface.t') }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.from') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.weightFrom"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.to') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.weightTo"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('measurement_filter.speed') }}, {{ $t('interface.kmh') }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.from') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.speedFrom"
                        type="number"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.to') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.speedTo"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" v-if="appCode === 'vgk'">
                <label>{{ $t('measurement_modal.violations') }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="checkbox">
                        <label>
                          <input v-model="filters.viol_weight" type="checkbox" />
                          <span class="checkbox-material">
                        <span class="check"></span>
                      </span>
                          {{ $t('measurement_filter.weight') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="checkbox">
                        <label>
                          <input v-model="filters.viol_dimensions" type="checkbox" />
                          <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                          {{ $t('measurement_filter.dimensions') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              <div class="form-group" v-if="appCode === 'ckad'" >
                <label>{{ $t('measurement_filter.axles') }}</label>
                <div class="row">
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">
                        <img src="../../assets/img/icons/arrow-down.svg"/>
                      </span>
                      <v-select
                        v-model="filters.axles_operator"
                        :options="axles_operators"
                        ref="axles_operators"
                      >
                        <template slot="option" slot-scope="option">
                          <div v-html="highlightAxleOperator(option.label)">
                          {{option }}</div>
                        </template>
                        <div slot="no-options">{{ $t('interface.no_results') }}</div>
                      </v-select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <span class="input-group-btn">{{ $t('interface.cnt') }}</span>
                      <input
                        class="form-control"
                        v-model="filters.axles"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="appCode === 'ind'" class="col-6">
              <div class="form-group" v-if="appCode === 'ckad' || appCode === 'vgk'">
                <label>{{ $t('panel_cols.class_autodor') }}</label>
                <div class="input-group flex-wrap">
                  <div v-for="(value, key) in autodorClass" :key="key" class="checkbox checkbox-item">
                    <input
                      v-model="filters.autodorClass"
                      :value="value"
                      :id="'ch-autodor_class-'+value"
                      type="checkbox"
                    />
                    <label :for="'ch-autodor_class-'+value">{{ $t('measurement_filter.class') }} {{ value }}</label>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t('panel_cols.violations') }}</label>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <div class="checkbox">
                      <label>
                        <input v-model="filters.viol_pdd" type="checkbox" />
                        <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                        {{ $t('measurement_filter.pdd') }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group" v-if="appCode === 'ind'">
                    <div class="checkbox">
                      <label>
                        <input v-model="filters.viol_speed" type="checkbox" />
                        <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                        {{ $t('measurement_filter.viol_speed') }}
                      </label>
                    </div>
                  </div>
                  <div class="form-group" v-else>
                    <div class="checkbox">
                      <label>
                        <input v-model="filters.viol_speed20" type="checkbox" />
                        <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                        {{ $t('measurement_filter.viol_speed20') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <div class="checkbox">
                      <label>
                        <input v-model="filters.viol_dimensions" type="checkbox" />
                        <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                        {{ $t('measurement_filter.dimensions') }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group" v-if="appCode !== 'ind'">
                    <div class="checkbox">
                      <label>
                        <input v-model="filters.viol_speed40" type="checkbox" />
                        <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                        {{ $t('measurement_filter.viol_speed40') }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="checkbox">
                        <label>
                          <input v-model="filters.viol_weight" type="checkbox" />
                          <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                          {{ $t('measurement_filter.weight') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" v-if="appCode !== 'ind'">
                      <div class="checkbox">
                        <label>
                          <input v-model="filters.viol_speed60" type="checkbox" />
                          <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                          {{ $t('measurement_filter.viol_speed60') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="checkbox">
                        <label>
                          <input v-model="filters.viol_collision" type="checkbox" />
                          <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                          {{ $t('measurement_filter.viol_collision') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group" v-if="appCode !== 'ind'">
                      <div class="checkbox">
                        <label>
                          <input v-model="filters.viol_speed80" type="checkbox" />
                          <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                          {{ $t('measurement_filter.viol_speed80') }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
            <div v-if="appCode === 'ckad' && settings.lanes_num === 3" class="col-6">
              <h4 class=" label-white noborder">{{ $t('measurement_filter.dsrc_antenns') }}</h4>
              <div class="dsrc-lines">
                <div
                  :class="'checkbox dsrc-type-' + dsrc_type.replace('.', '')"
                  v-for="(dsrc_type, index) in dsrcTypes3"
                  :key="index">
                  <label>
                    <input
                      v-model="filters.dsrcTypes"
                      :value="dsrc_type"
                      type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div v-else-if="appCode === 'ckad' && settings.lanes_num === 4" class="col-6">
              <h4 class=" label-white noborder">{{ $t('measurement_filter.dsrc_antenns') }}</h4>
              <div class="dsrc-4lines">
                <div
                  :class="'checkbox dsrc-type-' + dsrc_type.replace('.', '')"
                  v-for="(dsrc_type, index) in dsrcTypes4"
                  :key="index">
                  <label>
                    <input
                      v-model="filters.dsrcTypes"
                      :value="dsrc_type"
                      type="checkbox"/>
                    <span class="checkbox-material">
                                  <span class="check"></span>
                                </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="row"  v-if="roleIn(['admin', 'user'])">
            <div class="col-6">
              <h4 class="label-white">{{ $t('measurement_filter.mismatch') }}</h4>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.front_back_plates_diff_not_1" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.front_and_rear') }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.with_trailers" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.excluding_railers') }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.roadar_prob_not_1" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.recognition_mismatch') }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <h4 class="label-white">{{ $t('measurement_filter.extra_priznak') }}</h4>
              <div class="form-group" v-show="!svp">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.ton12" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.more_than_12') }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.no_camera" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.no_photo') }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.image_request" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.dozapros') }}
                  </label>
                </div>
              </div>
              <div class="form-group" v-if="appCode === 'ckad' || appCode === 'vgk'">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.rfid" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.rfid_availability') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row"  v-if="roleIn(['admin',])">
            <div class="col-12">
              <h4 class="label-white">{{ $t('measurement_filter.skip_reasons') }}</h4>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.same_gnz" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.same_gnz') }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.same_gnz_2" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.same_gnz_2') }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.same_pan" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.same_pan') }}
                  </label>
                </div>
              </div>
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input v-model="filters.no_vehicle_no_ectn" type="checkbox" />
                    <span class="checkbox-material">
                      <span class="check"></span>
                    </span>
                    {{ $t('measurement_filter.no_vehicle_no_ectn') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="form-group"  v-if="roleIn(['admin', 'user'])">
            <label>{{ $t('measurement_filter.date_send') }}</label>
            <div class="row">
              <div class="col-6">
                <datetimepicker opt="SVPDateFrom" @change="updateDate" ref="SVPDateFrom" />
              </div>
              <div class="col-6">
                <datetimepicker opt="SVPDateTo" @change="updateDate" ref="SVPDateTo" />
              </div>
            </div>
          </div>
          <br />

          <div class="form-group"  v-if="roleIn(['admin', 'user']) && settings.ma_visibility">
            <label>{{ $t('measurement_filter.manual_analysis') }}</label>
            <div class="input-group flex-wrap">
              <div v-for="value in manualAnalysisValues" :key="value.key" class="checkbox checkbox-item">
                <input v-model="filters[value.key]" :id="value.key" type="checkbox" />
                <label :for="value.key">{{ $t(`measurement_filter.${value.key}`) }}</label>
              </div>
            </div>
          </div>
          <div class="form-group"  v-if="roleIn(['admin', 'user']) && true">
            <label>{{ $t('measurement_filter.weighter_validity') }}</label>
            <div class="input-group flex-wrap">
              <div v-for="value in weighterValidityValues" :key="value.key" class="checkbox checkbox-item">
                <input v-model="filters[value.key]" :id="value.key" type="checkbox" />
                <label :for="value.key">{{ $t(`measurement_modal.weighter_validity.${value.id}`) }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="d-flex justify-content-between">
      <div class="button" @click="resetFilter">
        <span class="button-image"><img src="../../assets/img/icons/reset.svg"/></span>
        <span>{{ $t('action.drop_filters') }}</span>
      </div>
      <div class="d-flex">
        <div class="button" @click="applyFilter">
          <span class="button-image"><img src="../../assets/img/icons/search.svg"/></span>
          <span>{{ $t('action.apply') }}</span>
        </div>
        <div class="button" @click="close">
          <span class="button-image"><img src="../../assets/img/icons/close.svg"/></span>
          <span>{{ $t('action.cancel') }}</span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import mixin from '@/components/mixins/mixin';
import modal from '@/components/mixins/modal';
import datetimepicker from '@/components/vue-datetimepicker/vue-datetimepicker.vue';

export default {
  name: 'MeasurementFilter',
  components: {
    datetimepicker,
  },
  mixins: [mixin, modal],
  props: ['filtersParent'],
  data() {
    return {
      svp: false,
      collapseState: false,
      countries: [],
      axles_operators: [
        '==',
        '!=',
        '>=',
        '<=',
        '>',
        '<',
      ],
      dsrcTypes3: ['1.0', '1.5', '2.0', '2.5', '3.0'],
      dsrcTypes4: ['1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0'],
      autodorClass: [1, 2, 3, 4],
      sdkpClass: [1, 2, 3, 4],
      ectn28classes: [
        110,
        210,
        211,
        310,
        311,
        320,
        321,
        330,
        331,
        340,
        410,
        411,
        420,
        421,
        430,
        431,
        440,
        441,
        450,
        451,
        510,
        520,
        530,
        540,
        550,
        551,
        590,
        610,
        611,
      ],
      indClasses: [
        1,
        2,
        3,
        4,
        5,
      ],
      filters: {},
      dateFilters: ['dateFrom', 'dateTo', 'SVPDateFrom', 'SVPDateTo'],
    };
  },
  computed: {
    ...mapState([
      'settings',
    ]),
    ...mapState({
      isModalOpenFromStore: (state) => state.isModalOpen.measurementFilter,
    }),
    lanes() {
      const arr = [];
      for (let i = 1; i <= this.settings.lanes_num; i += 1) {
        arr.push(i);
      }
      return arr;
    },
  },
  created() {
    this.countries = this.countriesDictionary.map((key) => {
      const object = {
        name: this.$t(`countries.${key}`),
        code: key,
        value: `${this.$t(`countries.${key}`)} (${key})`,
      };
      return object;
    });
  },
  methods: {
    collapseContent() {
      this.collapseState = !this.collapseState;
    },
    highlightCountry(value) {
      if (!this.$refs.countries.search) {
        return value;
      }
      if (value.toLowerCase().includes(this.$refs.countries.search.toLowerCase())) {
        const searchValue = this.$refs.countries.search.toLowerCase();
        if (value.toUpperCase() === value) {
          return value
            .toLowerCase()
            .replace(searchValue, `<b>${searchValue}</b>`)
            .toUpperCase();
        }
        if (value.toLowerCase().indexOf(searchValue) === 0) {
          return value
            .toLowerCase()
            .replace(searchValue, `<b>${searchValue}</b>`);
        }
        return value.toLowerCase().replace(searchValue, `<b>${searchValue}</b>`);
      }
      return value;
    },
    highlightEctn(value) {
      if (!this.$refs.ectn28classes.search) {
        return value;
      }
      return String(value).replace(this.$refs.ectn28classes.search, `<b>${this.$refs.ectn28classes.search}</b>`);
    },
    highlightInd(value) {
      if (!this.$refs.indClasses.search) {
        return value;
      }
      return String(value).replace(this.$refs.indClasses.search, `<b>${this.$refs.indClasses.search}</b>`);
    },
    highlightAxleOperator(value) {
      if (!this.$refs.axles_operators.search) {
        return value;
      }
      return String(value).replace(this.$refs.axles_operators.search, `<b>${this.$refs.axles_operators.search}</b>`);
    },
    close() {
      this.closeModal('measurementFilter');
    },
  },
};
</script>
